import styled from 'styled-components';
import { displayFlex, flexDirectionColumn, fontBold } from '../../styles';
import { fontSize_LG, fontSize_XL } from '../../customFonts';

export const ModalHeader = styled.header`
    background-color: #263757;
    padding: 0 20px;
    ${displayFlex}
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.standard.WHITE};

    .close-icon {
        color: ${(props) => props.theme.light_primary.LIGHT_NONARY};
        ${fontSize_LG}
    }
`;

export const ModalHeadingPrimary = styled.p`
    color: ${(props) => props.theme.standard.WHITE};
    ${fontSize_XL}
    ${fontBold}
  margin: 0;
`;

export const ModalHeadingSecondary = styled(ModalHeadingPrimary)`
    ${fontSize_LG}
`;

export const ModelBody = styled.div`
    background-color: ${(props) => props.theme.standard.WHITE};
    max-height: 100%;
    overflow: auto;
    height: 100%;
    flex: 1;
    ${flexDirectionColumn};

    .compo-content {
        overflow-x: hidden !important;
    }
`;

export const PrimaryModelBody = styled.div`
    background-color: ${(props) => props.theme.standard.WHITE};
    overflow: auto;
    padding: 20px 25px;
    ${flexDirectionColumn}
`;
