import {
    createNewInvoiceFeeActionTypes,
    CREATE_NEW_INVOICE_FEE,
    CREATE_NEW_INVOICE_FEE_SUCCESS,
    EDIT_INVOICE_DATA,
    EDIT_INVOICE_DATA_SUCCESS,
    GET_INVOICE_DATA,
    GET_INVOICE_DATA_SUCCESS,
    GET_INVOICE_LIST,
    GET_INVOICE_LIST_SUCCESS,
    GET_INVOICE_OPTIONS,
    GET_INVOICE_OPTIONS_SUCCESS,
    SET_INVOICE_TYPE,
    createNewInvoiceFeeAction
} from './types';

export const createNewInvoiceFee = (
    params: any,
    history: any,
    stream: string
): createNewInvoiceFeeAction => ({
    type: CREATE_NEW_INVOICE_FEE,
    params,
    history,
    stream
});

export const createNewInvoiceFeeSuccess = (): createNewInvoiceFeeActionTypes => ({
    type: CREATE_NEW_INVOICE_FEE_SUCCESS
});

export const getInvoiceListData = (
    params: any,
    stream: string,
    appendData?: boolean
): createNewInvoiceFeeActionTypes => ({
    type: GET_INVOICE_LIST,
    params,
    stream,
    appendData
});

export const getInvoiceListDataSuccess = (
    payload: any,
    appendData = false
): createNewInvoiceFeeActionTypes => ({
    type: GET_INVOICE_LIST_SUCCESS,
    payload,
    appendData
});

export const getInvoiceData = (params: any, id: number): createNewInvoiceFeeActionTypes => ({
    type: GET_INVOICE_DATA,
    params,
    id
});

export const getInvoiceDataSuccess = (payload: any): createNewInvoiceFeeActionTypes => ({
    type: GET_INVOICE_DATA_SUCCESS,
    payload
});

export const editInvoiceData = (
    params: any,
    id: number,
    history: any,
    stream: string
): createNewInvoiceFeeActionTypes => ({
    type: EDIT_INVOICE_DATA,
    params,
    id,
    history,
    stream
});

export const editInvoiceDataSuccess = (): createNewInvoiceFeeActionTypes => ({
    type: EDIT_INVOICE_DATA_SUCCESS
});

export const getInvoiceOptionsData = (): createNewInvoiceFeeActionTypes => ({
    type: GET_INVOICE_OPTIONS
});

export const getInvoiceOptionsDataSuccess = (payload: any): createNewInvoiceFeeActionTypes => ({
    type: GET_INVOICE_OPTIONS_SUCCESS,
    payload
});

export const setInvoiceType = (data: any): createNewInvoiceFeeActionTypes => ({
    type: SET_INVOICE_TYPE,
    data
});
