import { GetRequestInterface } from '../../../interfaces/interfaces';

export const SET_ISACCESS_REQUEST = 'SET_ISACCESS_REQUEST';
export const GET_BUILDINGS_COMPANY_LIST = 'GET_BUILDINGS_COMPANY_LIST';
export const GET_BUILDINGS_COMPANY_LIST_SUCCESS = 'GET_BUILDINGS_COMPANY_LIST_SUCCESS';
export const GET_BUILDING_TYPE_LIST = 'GET_BUILDING_TYPE_LIST';
export const GET_BUILDING_TYPE_LIST_SUCCESS = 'GET_BUILDING_TYPE_LIST_SUCCESS';
export const CHECK_BUILDING = 'CHECK_BUILDING';
export const CHECK_BUILDING_SUCCESS = 'CHECK_BUILDING_SUCCESS';
export const ADD_NEW_BUILDING = 'ADD_NEW_BUILDING';
export const ADD_NEW_BUILDING_SUCCESS = 'ADD_NEW_BUILDING_SUCCESS';
export const ADD_NEW_BUILDING_FAILURE = 'ADD_NEW_BUILDING_FAILURE';
export const GET_BUILDINGS_OPTIONS = 'GET_BUILDINGS_OPTIONS';
export const GET_BUILDINGS_OPTIONS_SUCCESS = 'GET_BUILDINGS_OPTIONS_SUCCESS';
export const SEND_BUILDINGS_ACCESS_REQUEST = 'SEND_BUILDINGS_ACCESS_REQUEST';
export const SEND_BUILDINGS_ACCESS_REQUEST_SUCCESS = 'SEND_BUILDINGS_ACCESS_REQUEST_SUCCESS';
export const GET_BUILDING_DETAILS = 'GET_BUILDING_DETAILS';
export const GET_BUILDING_DETAILS_RECEIVED = 'GET_BUILDING_DETAILS_RECEIVED';
export const GET_BUILDINGS_DATA = 'GET_BUILDINGS_DATA';
export const GET_BUILDINGS_DATA_SUCCESS = 'GET_BUILDINGS_DATA_SUCCESS';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_RECEIVED = 'GET_USERS_RECEIVED';
export const UPDATE_BUILDING = 'UPDATE_BUILDING';
export const UPDATE_BUILDING_SUCCESS = 'UPDATE_BUILDING_SUCCESS';
export const ASSIGN_CERTIFIED_LEVEL = 'ASSIGN_CERTIFIED_LEVEL';
export const ASSIGN_CERTIFIED_LEVEL_SUCCESS = 'ASSIGN_CERTIFIED_LEVEL_SUCCESS';
export const REMOVE_CERTIFIED_LEVEL = 'REMOVE_CERTIFIED_LEVEL';
export const REMOVE_CERTIFIED_LEVEL_SUCCESS = 'REMOVE_CERTIFIED_LEVEL_SUCCESS';
export const ADD_BUILDING_ACCESS = 'ADD_BUILDING_ACCESS';
export const ADD_BUILDING_ACCESS_SUCCESS = 'ADD_BUILDING_ACCESS_SUCCESS';
export const MAP_BOX_OBJECT = 'MAP_BOX_OBJECT';
export const SET_BUILDING_PROPERTY_TAB = 'SET_BUILDING_PROPERTY_TAB';
export interface companyJson {
    results: [];
}

export interface addNewBuildingParams extends GetRequestInterface {
    params: any;
}

export interface isAccessRequestAction {
    type: typeof SET_ISACCESS_REQUEST;
    isAccessRequest: boolean;
}

export interface getBuildingsCompanyListDataAction {
    type: typeof GET_BUILDINGS_COMPANY_LIST;
    params: any;
}

export interface getBuildingsCompanyListDataSuccessAction {
    type: typeof GET_BUILDINGS_COMPANY_LIST_SUCCESS;
    payload: companyJson;
}

export interface buildingTypeAction {
    type: typeof GET_BUILDING_TYPE_LIST;
    params: any;
}

export interface buildingTypeSuccessAction {
    type: typeof GET_BUILDING_TYPE_LIST_SUCCESS;
    payload: companyJson;
}

export interface buildingPropertyAction {
    type: typeof SET_BUILDING_PROPERTY_TAB;
    tab: string;
}

export interface checkBuildingAction {
    type: typeof CHECK_BUILDING;
    params: string;
    history: any;
    buildingData: any;
    isNewBuilding: any;
}

export interface checkBuildingSuccessAction {
    type: typeof CHECK_BUILDING_SUCCESS;
    params: any;
}

export interface addNewBuildingAction {
    type: typeof ADD_NEW_BUILDING;
    params: addNewBuildingParams;
    history: any;
}

export interface addNewBuildingActionSuccess {
    type: typeof ADD_NEW_BUILDING_SUCCESS;
}

export interface addNewBuildingFailure {
    type: typeof ADD_NEW_BUILDING_FAILURE;
}

export interface getBuildingsOptionsAction {
    type: typeof GET_BUILDINGS_OPTIONS;
}

export interface getBuildingsOptionsSuccessAction {
    type: typeof GET_BUILDINGS_OPTIONS_SUCCESS;
    payload: any;
}

export interface sendBuildingsAccessRequestAction {
    type: typeof SEND_BUILDINGS_ACCESS_REQUEST;
    params: any;
    history: any;
    setShowLoader?: any;
}

export interface sendBuildingsAccessRequestSuccessAction {
    type: typeof SEND_BUILDINGS_ACCESS_REQUEST_SUCCESS;
}

export interface getBuildingDetailsAction {
    type: typeof GET_BUILDING_DETAILS;
    id: number;
}

export interface getBuildingDetailsSuccessAction {
    type: typeof GET_BUILDING_DETAILS_RECEIVED;
    payload: any;
}

export interface getBuildingsDataAction {
    type: typeof GET_BUILDINGS_DATA;
    params: any;
    appendData?: boolean;
}

export interface getBuildingsDataSuccessAction {
    type: typeof GET_BUILDINGS_DATA_SUCCESS;
    payload: any;
    appendData?: boolean;
}

export interface getUsersAction {
    type: typeof GET_USERS;
    id: number;
}

export interface getUsersSuccessAction {
    type: typeof GET_USERS_RECEIVED;
    payload: any;
}

export interface updateBuildingDataAction {
    type: typeof UPDATE_BUILDING;
    params: any;
    id: number;
    history?: any;
}

export interface updateBuildingDataSuccessAction {
    type: typeof UPDATE_BUILDING_SUCCESS;
}

export interface assignCertifiedLevelAction {
    type: typeof ASSIGN_CERTIFIED_LEVEL;
    params: any;
    name: any;
}

export interface assignCertifiedLevelSuccessAction {
    type: typeof ASSIGN_CERTIFIED_LEVEL_SUCCESS;
}

export interface removeCertifiedLevelAction {
    type: typeof REMOVE_CERTIFIED_LEVEL;
    params: any;
    name: any;
}

export interface removeCertifiedLevelSuccessAction {
    type: typeof REMOVE_CERTIFIED_LEVEL_SUCCESS;
}
export interface addBuildingAccessAction {
    type: typeof ADD_BUILDING_ACCESS;
    params: any;
    id?: number;
}

export interface addBuildingAccessSuccessAction {
    type: typeof ADD_BUILDING_ACCESS_SUCCESS;
}

export interface mapBoxObjectAction {
    type: typeof MAP_BOX_OBJECT;
    data: any;
    markerData: any;
}

export type BuildingsActionTypes =
    | isAccessRequestAction
    | getBuildingsCompanyListDataAction
    | getBuildingsCompanyListDataSuccessAction
    | buildingTypeAction
    | buildingTypeSuccessAction
    | checkBuildingAction
    | checkBuildingSuccessAction
    | addNewBuildingAction
    | addNewBuildingActionSuccess
    | addNewBuildingFailure
    | getBuildingsOptionsAction
    | getBuildingsOptionsSuccessAction
    | sendBuildingsAccessRequestAction
    | sendBuildingsAccessRequestSuccessAction
    | getBuildingDetailsAction
    | getBuildingDetailsSuccessAction
    | getBuildingsDataAction
    | getBuildingsDataSuccessAction
    | getUsersAction
    | getUsersSuccessAction
    | updateBuildingDataAction
    | updateBuildingDataSuccessAction
    | assignCertifiedLevelAction
    | assignCertifiedLevelSuccessAction
    | removeCertifiedLevelAction
    | removeCertifiedLevelSuccessAction
    | addBuildingAccessAction
    | addBuildingAccessSuccessAction
    | mapBoxObjectAction
    | buildingPropertyAction;

export interface BuildingsState {
    isAccessRequest: boolean;
    isFetchingCompanyList: boolean;
    buildingCompanyListData: companyJson | null;
    isFetchingBuildingTypeList: boolean;
    buildingTypeListData: companyJson | null;
    suggestedBuildingsData: companyJson | null;
    isNewBuildingAdded: boolean;
    buildingOptionsData: companyJson | null;
    isBuildingsAccessRequestSent: boolean;
    buildingDetails: companyJson | null;
    buildingsData: companyJson | null;
    isFetchingBuildingList: boolean;
    usersData: companyJson | null;
    isAddingBuildingAccess: boolean;
    mapBoxObjectData: any;
    markerDataObject: any;
    isDetailsFetched: boolean;
    selectedBuildingPropertyTab: string;
}
