import { GetResponseInterface, GetRequestInterface } from '../../../interfaces/interfaces';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export interface ViewUserInterFace {
    id: string;
    management_company: string;
    email: string;
    group: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    phone: number;
}

export interface viewUserParams extends GetRequestInterface {
    params: string;
}
export interface viewUserJson extends GetResponseInterface {
    results: Array<ViewUserInterFace>;
}

export interface fetchUserAction {
    type: typeof FETCH_USER;
    params: viewUserParams;
    shouldAppend?: boolean;
    id?: number;
    group?: Array<any>;
}

export interface fetchUserSuccessAction {
    type: typeof FETCH_USER_SUCCESS;
    data: any;
    shouldAppend?: boolean;
}

export type FetchUserActionTypes = fetchUserAction | fetchUserSuccessAction;

export interface FetchUserState {
    isFetchingUser: boolean;
    fetchUserData: viewUserJson;
}
