import {
    GET_QUESTIONNAIRE,
    GET_QUESTIONNAIRE_RECEIVED,
    questionnaireActionTypes,
    GET_COMBINATIONS,
    GET_COMBINATIONS_RECEIVED,
    GET_ANSWER_QUESTIONNAIRE,
    GET_ANSWER_QUESTIONNAIRE_RECEIVED,
    GET_QUESTIONS_OPTIONS,
    GET_QUESTIONS_OPTIONS_SUCCESS,
    CREATE_QUESTION,
    CREATE_QUESTION_SUCCESS,
    GET_SEQUENCE_ID,
    GET_SEQUENCE_ID_RECEIVED,
    GET_ANSWERS,
    GET_ANSWERS_RECEIVED,
    SET_QUESTIONNAIRE_TYPE,
    UPDATE_QUESTION,
    UPDATE_QUESTION_SUCCESS,
    PUBLISH_QUESTIONNAIRE,
    PUBLISH_QUESTIONNAIRE_SUCCESS,
    DELETE_QUESTION,
    DELETE_QUESTION_SUCCESS,
    DELETE_COMBINATION,
    DELETE_COMBINATION_SUCCESS,
    GET_QUESTION_VALIDATIONS,
    GET_QUESTION_VALIDATIONS_SUCCESS,
    CREATE_DRAFT,
    CREATE_DRAFT_SUCCESS,
    MOVE_TO_ARCHIVE,
    MOVE_TO_ARCHIVE_SUCCESS,
    SUBMIT_FOR_VERIFICATION,
    SUBMIT_FOR_VERIFICATION_SUCCESS,
    ADD_ATTACHMENT,
    ADD_ATTACHMENT_SUCCESS
} from './types';

export const getQuestionnaire = (
    params: any,
    status: string,
    appendData?: boolean
): questionnaireActionTypes => ({
    type: GET_QUESTIONNAIRE,
    params,
    status,
    appendData
});

export const getQuestionnaireReceived = (
    payload: any,
    appendData = false
): questionnaireActionTypes => ({
    type: GET_QUESTIONNAIRE_RECEIVED,
    payload,
    appendData
});

export const getCombinations = (params: any, id: number): questionnaireActionTypes => ({
    type: GET_COMBINATIONS,
    params,
    id
});

export const getCombinationsReceived = (payload: any): questionnaireActionTypes => ({
    type: GET_COMBINATIONS_RECEIVED,
    payload
});

export const getQuestionsOptions = (): questionnaireActionTypes => ({
    type: GET_QUESTIONS_OPTIONS
});

export const getQuestionsOptionsSuccess = (payload: any): questionnaireActionTypes => ({
    type: GET_QUESTIONS_OPTIONS_SUCCESS,
    payload
});

export const createQuestion = (
    id: number,
    questionnaireId: number,
    params: any
): questionnaireActionTypes => ({
    type: CREATE_QUESTION,
    id,
    questionnaireId,
    params
});

export const createQuestionSuccess = (): questionnaireActionTypes => ({
    type: CREATE_QUESTION_SUCCESS
});

export const getSequenceId = (id: number): questionnaireActionTypes => ({
    type: GET_SEQUENCE_ID,
    id
});

export const getSequenceIdReceived = (payload: any): questionnaireActionTypes => ({
    type: GET_SEQUENCE_ID_RECEIVED,
    payload
});

export const getAnswerQuestionnaire = (id: number): questionnaireActionTypes => ({
    type: GET_ANSWER_QUESTIONNAIRE,
    id
});

export const getAnswerQuestionnaireReceived = (payload: any): questionnaireActionTypes => ({
    type: GET_ANSWER_QUESTIONNAIRE_RECEIVED,
    payload
});

export const getAnswers = (id: number): questionnaireActionTypes => ({
    type: GET_ANSWERS,
    id
});

export const getAnswersReceived = (payload: any): questionnaireActionTypes => ({
    type: GET_ANSWERS_RECEIVED,
    payload
});

export const setQuestionnaireType = (data: any): questionnaireActionTypes => ({
    type: SET_QUESTIONNAIRE_TYPE,
    data
});

export const updateQuestion = (
    id: any,
    questionnaireId: number,
    params: any
): questionnaireActionTypes => ({
    type: UPDATE_QUESTION,
    id,
    questionnaireId,
    params
});

export const updateQuestionSuccess = (): questionnaireActionTypes => ({
    type: UPDATE_QUESTION_SUCCESS
});

export const publishQuestionnaire = (
    params: any,
    history: any,
    status: string,
    limit: any
): questionnaireActionTypes => ({
    type: PUBLISH_QUESTIONNAIRE,
    params,
    history,
    status,
    limit
});

export const publishQuestionnaireSuccess = (): questionnaireActionTypes => ({
    type: PUBLISH_QUESTIONNAIRE_SUCCESS
});

export const deleteQuestion = (
    id: any,
    questionnaireId: number,
    payload: any
): questionnaireActionTypes => ({
    type: DELETE_QUESTION,
    id,
    questionnaireId,
    payload
});

export const deleteQuestionSuccess = (): questionnaireActionTypes => ({
    type: DELETE_QUESTION_SUCCESS
});

export const deleteCombination = (
    id: any,
    questionnaireId: number,
    name: string,
    payload: any
): questionnaireActionTypes => ({
    type: DELETE_COMBINATION,
    id,
    questionnaireId,
    name,
    payload
});

export const deleteCombinationSuccess = (): questionnaireActionTypes => ({
    type: DELETE_COMBINATION_SUCCESS
});

export const getQuestionValidations = (params: any): questionnaireActionTypes => ({
    type: GET_QUESTION_VALIDATIONS,
    params
});

export const getQuestionValidationsSuccess = (payload: any): questionnaireActionTypes => ({
    type: GET_QUESTION_VALIDATIONS_SUCCESS,
    payload
});

export const submitForVerification = (
    params: any,
    id: number,
    history: any
): questionnaireActionTypes => ({
    type: SUBMIT_FOR_VERIFICATION,
    params,
    id,
    history
});

export const submitForVerificationSuccess = (): questionnaireActionTypes => ({
    type: SUBMIT_FOR_VERIFICATION_SUCCESS
});

export const createDraft = (params: any, status: string, limit: any): questionnaireActionTypes => ({
    type: CREATE_DRAFT,
    params,
    status,
    limit
});

export const createDraftSuccess = (): questionnaireActionTypes => ({
    type: CREATE_DRAFT_SUCCESS
});

export const moveToArchive = (params: any): questionnaireActionTypes => ({
    type: MOVE_TO_ARCHIVE,
    params
});

export const moveToArchiveSuccess = (): questionnaireActionTypes => ({
    type: MOVE_TO_ARCHIVE_SUCCESS
});

export const addAttachment = (params: any): questionnaireActionTypes => ({
    type: ADD_ATTACHMENT,
    params
});

export const addAttachmentSuccess = (payload: any): questionnaireActionTypes => ({
    type: ADD_ATTACHMENT_SUCCESS,
    payload
});
