import {
    CompanyActionTypes,
    REGISTER_COMPANY,
    registerCompanyJson,
    COMPANY_DATA,
    COMPANY_DATA_RECEIVED,
    companyNamesJson,
    companyNamesParams,
    SIGN_UP,
    userSignUpParams,
    REGISTER_COMPANY_SUCCESS,
    SIGN_UP_SUCCESS,
    REGISTER_COMPANY_FAILURE,
    SIGN_UP_FAILURE
} from './types';

export const registerCompany = (params: registerCompanyJson): CompanyActionTypes => ({
    type: REGISTER_COMPANY,
    params
});

export const registerCompanySuccess = () => ({
    type: REGISTER_COMPANY_SUCCESS
});

export const registerCompanyFailure = () => ({
    type: REGISTER_COMPANY_FAILURE
});

export const getCompanyNamesData = (params: companyNamesParams): CompanyActionTypes => ({
    type: COMPANY_DATA,
    params
});

export const getCompanyNamesDataReceived = (data: companyNamesJson): CompanyActionTypes => ({
    type: COMPANY_DATA_RECEIVED,
    data
});

export const userSignUp = (params: userSignUpParams): CompanyActionTypes => ({
    type: SIGN_UP,
    params
});

export const userSignUpSuccess = () => ({
    type: SIGN_UP_SUCCESS
});

export const userSignUpFailure = () => ({
    type: SIGN_UP_FAILURE
});
