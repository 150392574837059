import { WaffleState } from '../actions/Waffle/types';

const initialState: WaffleState = {
    featureFlags: {}
};

export const Waffle = (state = initialState, action: any) => {
    switch (action.type) {
        case 'GET_FEATURE_FLAGS': {
            return {
                ...state,
                featureFlags: { ...action.data.flags, ...action.data.switches }
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default Waffle;
