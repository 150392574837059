import React from 'react';
import { components } from 'react-select';
import { theme as themeColorsBOMA } from '../../../theme';
import { MultiValueWrapper } from './styles';

export const Option = (props: any) => {
    return (
        <div title={props.data.label}>
            <components.Option {...props} />
        </div>
    );
};

export const MultiValue = (props: any) => {
    const {
        index,
        customProps: { showMaxLimitMsg, maxLimitMsg, selectedOptionsLimit }
    } = props;
    const isLastSelectedOption = index === selectedOptionsLimit - 1;
    return (
        <MultiValueWrapper title={props.data.label}>
            <components.MultiValue {...props} />
            {showMaxLimitMsg && isLastSelectedOption && (
                <div className='max-limit-msg' title={maxLimitMsg}>
                    {maxLimitMsg}
                </div>
            )}
        </MultiValueWrapper>
    );
};

export const Placeholder = (props: any) => {
    return (
        <div className={'w-100'} title={props.children}>
            <components.Placeholder {...props} />
        </div>
    );
};

export const SingleValue = (props: any) => {
    return (
        <div title={props.data.label}>
            <components.SingleValue {...props} />
        </div>
    );
};

export const CustomThemeColor = (theme: any) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: themeColorsBOMA.primary.THEME
    }
});

export const DropdownIndicator = (props: any) => {
    const { isDisabled } = props;

    return (
        <components.DropdownIndicator {...props}>
            {!isDisabled ? null : !null}
        </components.DropdownIndicator>
    );
};

export const Menu = (props: any) => {
    if (props.selectProps.inputValue.length === 0 && !props.selectProps.openMenuOnClick)
        return null;

    return (
        <>
            <components.Menu {...props} />
        </>
    );
};
