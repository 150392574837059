import { any } from 'prop-types';
import {
    ADD_COMMENT,
    ADD_COMMENT_SUCCESS,
    ASSIGN_VERIFIER,
    ASSIGN_VERIFIER_SUCCESS,
    CREATE_VERIFICATION_QUESTION,
    CREATE_VERIFICATION_QUESTION_SUCCESS,
    EDIT_ANSWERS,
    EDIT_ANSWERS_SUCCESS,
    EDIT_VERIFICATION_QUESTION,
    EDIT_VERIFICATION_QUESTION_SUCCESS,
    GET_PROPERTY_USER,
    GET_PROPERTY_USER_RECEIVED,
    GET_START_VERIFICATIONS,
    GET_START_VERIFICATIONS_SUCCESS,
    GET_VERIFICATIONS,
    GET_VERIFICATIONS_SUCCESS,
    GET_VERIFICATION_OPTIONS_SUCCESS,
    GET_VERIFIERS,
    GET_VERIFIERS_SUCCESS,
    SCHEDULE_VERIFICATION,
    SCHEDULE_VERIFICATION_SUCCESS,
    START_VERIFICATION,
    START_VERIFICATION_SUCCESS,
    VerificationActionTypes,
    VerificationState
} from '../actions/Verification/types';

const initialState: VerificationState = {
    assignVerificationdata: null,
    verifiersData: null,
    startVerificationdata: null,
    verificationQuestionData: null,
    isFetchingAssignData: false,
    isFetchingStartData: false,
    editAnswerDetails: null,
    verificationOptions: any,
    propertyUserData: null,
    verificationReportData: null,
    isFetchingVerificationReport: false,
    isFetchingVerificationQuestion: false
};

const Verification = (state = initialState, action: VerificationActionTypes): VerificationState => {
    switch (action.type) {
        case GET_VERIFICATIONS: {
            return {
                ...state,
                isFetchingAssignData: true
            };
        }
        case GET_VERIFICATIONS_SUCCESS: {
            if (action.shouldAppend) {
                action.payload.results = [
                    ...state.assignVerificationdata.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                assignVerificationdata: action.payload,
                isFetchingAssignData: false
            };
        }
        case GET_VERIFIERS: {
            return {
                ...state
            };
        }
        case GET_VERIFIERS_SUCCESS: {
            return {
                ...state,
                verifiersData: action.payload
            };
        }
        case ASSIGN_VERIFIER: {
            return {
                ...state
            };
        }
        case ASSIGN_VERIFIER_SUCCESS: {
            return {
                ...state
            };
        }
        case GET_START_VERIFICATIONS: {
            return {
                ...state,
                isFetchingStartData: true
            };
        }
        case GET_START_VERIFICATIONS_SUCCESS: {
            if (action.appendData && state.startVerificationdata.results) {
                action.payload.results = [
                    ...state.startVerificationdata.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                startVerificationdata: action.payload,
                isFetchingStartData: false
            };
        }
        case START_VERIFICATION: {
            return {
                ...state
            };
        }
        case START_VERIFICATION_SUCCESS: {
            return {
                ...state
            };
        }
        case SCHEDULE_VERIFICATION: {
            return {
                ...state
            };
        }
        case SCHEDULE_VERIFICATION_SUCCESS: {
            return {
                ...state
            };
        }
        case ADD_COMMENT: {
            return {
                ...state
            };
        }
        case ADD_COMMENT_SUCCESS: {
            return {
                ...state
            };
        }
        case CREATE_VERIFICATION_QUESTION: {
            return {
                ...state
            };
        }
        case CREATE_VERIFICATION_QUESTION_SUCCESS: {
            return {
                ...state
            };
        }
        case EDIT_VERIFICATION_QUESTION: {
            return {
                ...state
            };
        }
        case EDIT_VERIFICATION_QUESTION_SUCCESS: {
            return {
                ...state
            };
        }
        case GET_VERIFICATION_OPTIONS_SUCCESS: {
            return {
                ...state,
                verificationOptions: action.data
            };
        }

        case EDIT_ANSWERS: {
            return {
                ...state
            };
        }
        case EDIT_ANSWERS_SUCCESS: {
            return {
                ...state,
                editAnswerDetails: action.payload
            };
        }
        case GET_PROPERTY_USER: {
            return {
                ...state
            };
        }
        case GET_PROPERTY_USER_RECEIVED: {
            return {
                ...state,
                propertyUserData: action.payload
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default Verification;
