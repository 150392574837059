import {
    createNewUserState,
    CREATE_NEW_USER_SUCCESS,
    createNewUserActionTypes,
    CREATE_NEW_USER,
    CHECK_USER,
    CHECK_USER_SUCCESS,
    USER_EXIST,
    CREATE_NEW_USER_FAILURE,
    CHECK_USER_FAILURE,
    USER_EXIST_RESET
} from '../actions/CreateNewUser/types';

const initialState: createNewUserState = {
    isNewUserCreated: false,
    isUserExist: false,
    isUserChecked: false
};

export const CreateNewUser = (
    state = initialState,
    action: createNewUserActionTypes
): createNewUserState => {
    switch (action.type) {
        case CREATE_NEW_USER: {
            return {
                ...state,
                isNewUserCreated: true
            };
        }
        case CREATE_NEW_USER_SUCCESS: {
            return {
                ...state,
                isNewUserCreated: false
            };
        }
        case CREATE_NEW_USER_FAILURE: {
            return {
                ...state,
                isNewUserCreated: false
            };
        }

        case CHECK_USER: {
            return {
                ...state
            };
        }
        case CHECK_USER_SUCCESS: {
            return {
                ...state,
                isUserChecked: true
            };
        }
        case CHECK_USER_FAILURE: {
            return {
                ...state,
                isUserChecked: false
            };
        }

        case USER_EXIST: {
            return {
                ...state,
                isUserExist: true
            };
        }

        case USER_EXIST_RESET: {
            return {
                ...state,
                isUserExist: false
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default CreateNewUser;
