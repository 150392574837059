import { defaultGetResponse } from '../../interfaces/interfaces';
import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FetchUserActionTypes,
    FetchUserState
} from '../actions/ViewUsers/types';

const initialState: FetchUserState = {
    isFetchingUser: false,
    fetchUserData: defaultGetResponse
};

const ViewUser = (state = initialState, action: FetchUserActionTypes): FetchUserState => {
    switch (action.type) {
        case FETCH_USER: {
            return {
                ...state,
                isFetchingUser: true
            };
        }
        case FETCH_USER_SUCCESS: {
            if (action.shouldAppend) {
                action.data.results = [...state.fetchUserData.results, ...action.data.results];
            }
            return {
                ...state,
                isFetchingUser: false,
                fetchUserData: action.data
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default ViewUser;
