import { GetRequestInterface, GetResponseInterface } from '../../../interfaces/interfaces';

export const SET_ISMODAL_OPEN = 'SET_ISMODAL_OPEN';
export const SET_FIRSTTIME_LOGIN = 'SET_FIRSTTIME_LOGIN';
export const GET_GROUPS_LIST = 'GET_GROUPS_LIST';
export const GET_GROUPS_LIST_SUCCESS = 'GET_GROUPS_LIST_SUCCESS';
export const GET_REGIONS_LIST = 'GET_REGIONS_LIST';
export const GET_REGIONS_LIST_SUCCESS = 'GET_REGIONS_LIST_SUCCESS';
export const GET_SUB_REGIONS_LIST = 'GET_SUB_REGIONS_LIST';
export const GET_SUB_REGIONS_LIST_SUCCESS = 'GET_SUB_REGIONS_LIST_SUCCESS';
export const SET_IS_EDIT = 'SET_IS_EDIT';
export const GET_USER_REGIONS_AND_SUB_REGIONS_LIST = 'GET_USER_REGIONS_AND_SUB_REGIONS_LIST';
export const GET_USER_REGIONS_AND_SUB_REGIONS_LIST_SUCCESS =
    'GET_USER_REGIONS_AND_SUB_REGIONS_LIST_SUCCESS';
export const SET_SHOW_LOADER = 'SET_SHOW_LOADER';
export const SET_TABLE_ROW_LIMIT = 'SET_TABLE_ROW_LIMIT';
export const CREATE_REGION = 'CREATE_REGION';
export const CREATE_REGION_SUCCESS = 'CREATE_REGION_SUCCESS';
export const EDIT_REGION = 'EDIT_REGION';
export const EDIT_REGION_SUCCESS = 'EDIT_REGION_SUCCESS';
export const CREATE_SUB_REGION = 'CREATE_SUB_REGION';
export const CREATE_SUB_REGION_SUCCESS = 'CREATE_SUB_REGION_SUCCESS';
export const EDIT_SUB_REGION = 'EDIT_SUB_REGION';
export const EDIT_SUB_REGION_SUCCESS = 'EDIT_SUB_REGION_SUCCESS';
export const GET_CHOICES = 'GET_CHOICES';
export const CHOICES_RECIEVED = 'CHOICES_RECIEVED';
export const SET_SHOW_APP_LOADER = 'SET_SHOW_APP_LOADER';

export interface commonParams extends GetRequestInterface {
    params: {};
}
export interface commonJson extends GetResponseInterface {
    results: [];
}
export interface isModalOpenAction {
    type: typeof SET_ISMODAL_OPEN;
    isModalOpen: boolean;
}

export interface CommonAction {
    type: typeof GET_GROUPS_LIST | typeof GET_SUB_REGIONS_LIST | typeof GET_REGIONS_LIST;
    params: commonParams;
    shouldAppend?: any;
}

export interface GetUserRegionsAndSubRegionsAction {
    type: typeof GET_USER_REGIONS_AND_SUB_REGIONS_LIST;
    params: commonParams;
    id: number;
}

export interface CommonActionSuccess {
    type:
        | typeof GET_GROUPS_LIST_SUCCESS
        | typeof GET_SUB_REGIONS_LIST_SUCCESS
        | typeof GET_REGIONS_LIST_SUCCESS
        | typeof GET_USER_REGIONS_AND_SUB_REGIONS_LIST_SUCCESS;
    payload: any;
    shouldAppend?: any;
}

export interface GetUserRegionsAndSubRegionsActionSuccess {
    type: typeof GET_USER_REGIONS_AND_SUB_REGIONS_LIST;
    payload: commonJson;
}

export interface isFirstTimeLoginAction {
    type: typeof SET_FIRSTTIME_LOGIN;
    isFirstTimeLogin: boolean;
}

export interface setIsEditAction {
    type: typeof SET_IS_EDIT;
    data: boolean;
}

export interface setShowLoaderAction {
    type: typeof SET_SHOW_LOADER;
    data: boolean;
}
export interface setShowAppLoader {
    type: typeof SET_SHOW_APP_LOADER;
    data: boolean;
}

export interface setTableRowLimit {
    type: typeof SET_TABLE_ROW_LIMIT;
    limit: number;
}

export interface createRegionAction {
    type: typeof CREATE_REGION;
    params: any;
}
export interface createRegionSuccessAction {
    type: typeof CREATE_REGION_SUCCESS;
}

export interface editRegionAction {
    type: typeof EDIT_REGION;
    id: number;
    params: any;
}
export interface editRegionSuccessAction {
    type: typeof EDIT_REGION_SUCCESS;
}

export interface createSubRegionAction {
    type: typeof CREATE_SUB_REGION;
    params: any;
}
export interface createSubRegionSuccessAction {
    type: typeof CREATE_SUB_REGION_SUCCESS;
}

export interface editSubRegionAction {
    type: typeof EDIT_SUB_REGION;
    id: number;
    params: any;
}
export interface editSubRegionSuccessAction {
    type: typeof EDIT_SUB_REGION_SUCCESS;
}

export interface getChoicesAction {
    type: typeof GET_CHOICES;
}

export interface choicesReceivedAction {
    type: typeof CHOICES_RECIEVED;
    data: any;
}

export interface CommonState {
    isFirstTimeLogin: boolean;
    isModalOpen: boolean;
    isFetchingGroupsList: boolean;
    groupsListData: any;
    isFetchingRegionsList: boolean;
    regionsListData: commonJson | any;
    isFetchingSubRegionsList: boolean;
    subRegionsListData: commonJson | any;
    isEdit: boolean;
    isFetchingUserRegionsAndSubRegionsList: boolean;
    userRegionsAndSubRegionsListData: commonJson | null;
    isRightPanelOpen: boolean;
    showLoader: boolean;
    showAppLoader: boolean;
    tableDataLimit: number;
    choices: any;
}

export type CommonActionTypes =
    | isFirstTimeLoginAction
    | isModalOpenAction
    | CommonAction
    | CommonActionSuccess
    | setIsEditAction
    | GetUserRegionsAndSubRegionsAction
    | GetUserRegionsAndSubRegionsActionSuccess
    | setShowLoaderAction
    | setShowAppLoader
    | setTableRowLimit
    | createRegionAction
    | createRegionSuccessAction
    | editRegionAction
    | editRegionSuccessAction
    | createSubRegionAction
    | createSubRegionSuccessAction
    | editSubRegionAction
    | editSubRegionSuccessAction
    | getChoicesAction
    | choicesReceivedAction;
