export const GET_DIRECTORIES = 'GET_DIRECTORIES';
export const GET_DIRECTORIES_SUCCESS = 'GET_DIRECTORIES_SUCCESS';
export const CREATE_DIRECTORIES = 'CREATE_DIRECTORIES';
export const EDIT_DIRECTORIES = 'EDIT_DIRECTORIES';
export const DELETE_DIRECTORIES = 'DELETE_DIRECTORIES';
export const CREATE_DIRECTORIES_SUCCESS = 'CREATE_DIRECTORIES_SUCCESS';
export const EDIT_DIRECTORIES_SUCCESS = 'EDIT_DIRECTORIES_SUCCESS';
export const DELETE_DIRECTORIES_SUCCESS = 'DELETE_DIRECTORIES_SUCCESS';
export const GET_FILES = 'GET_FILES';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const CREATE_FILES = 'CREATE_FILES';
export const EDIT_FILES = 'EDIT_FILES';
export const DELETE_FILES = 'DELETE_FILES';
export const CREATE_FILES_SUCCESS = 'CREATE_FILES_SUCCESS';
export const EDIT_FILES_SUCCESS = 'EDIT_FILES_SUCCESS';
export const DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS';

export interface getDirectoriesAction {
    type: typeof GET_DIRECTORIES;
    url: string;
    params: any;
}

export interface directoriesRecievedAction {
    type: typeof GET_DIRECTORIES_SUCCESS;
    data: any;
}

export interface createDirectoriesAction {
    type: typeof CREATE_DIRECTORIES;
    url: string;
    params: any;
}

export interface editDirectoriesAction {
    type: typeof EDIT_DIRECTORIES;
    url: string;
    id: number;
    params: any;
    isSubDir: boolean;
}

export interface deleteDirectoriesAction {
    type: typeof DELETE_DIRECTORIES;
    url: string;
    directoryId: number;
    isSubDir: boolean;
}

export interface getFilesAction {
    type: typeof GET_FILES;
    url: string;
    params: any;
}

export interface filesRecievedAction {
    type: typeof GET_FILES_SUCCESS;
    data: any;
}

export interface createFilesAction {
    type: typeof CREATE_FILES;
    url: string;
    params: any;
    onUploadProgress: any;
    onUploadSuccess: any;
}

export interface editFilesAction {
    type: typeof EDIT_FILES;
    url: string;
    id: any;
    params: any;
}

export interface deleteFilesAction {
    type: typeof DELETE_FILES;
    url: string;
    id: number;
}

export interface createDirectoriesSuccessAction {
    type: typeof CREATE_DIRECTORIES_SUCCESS;
}
export interface editDirectoriesSuccessAction {
    type: typeof EDIT_DIRECTORIES_SUCCESS;
}
export interface deleteDirectoriesSuccessAction {
    type: typeof DELETE_DIRECTORIES_SUCCESS;
}
export interface createFilesSuccessAction {
    type: typeof CREATE_FILES_SUCCESS;
}
export interface editFilesSuccessAction {
    type: typeof EDIT_FILES_SUCCESS;
}
export interface deleteFilesSuccessAction {
    type: typeof DELETE_FILES_SUCCESS;
}

export type DocumentActionTypes =
    | getDirectoriesAction
    | directoriesRecievedAction
    | createDirectoriesAction
    | editDirectoriesAction
    | deleteDirectoriesAction
    | getFilesAction
    | filesRecievedAction
    | createFilesAction
    | editFilesAction
    | deleteFilesAction
    | createDirectoriesSuccessAction
    | editDirectoriesSuccessAction
    | deleteDirectoriesSuccessAction
    | createFilesSuccessAction
    | editFilesSuccessAction
    | deleteFilesSuccessAction;

export interface DocumentState {
    directoriesData: any;
    subDirectoriesData: any;
    isFetchingDirectories: boolean;
    filesData: any;
    isFetchingFiles: boolean;
    isCreatingDirectories: boolean;
    isEditingDirectories: boolean;
    isDeletingDirectories: boolean;
    isCreatingFiles: boolean;
    isEditingFiles: boolean;
    isDeletingFiles: boolean;
}
