import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './initialSetup';
import App from './App';
import { Provider } from 'react-redux';
import { theme } from './theme';
import { ThemeProvider } from 'styled-components/macro';
import store from './Store/index';
import { BrowserRouter } from 'react-router-dom';
import UserLeaveConfirmation from './components/UserLeaveConfirmation';
import './cssImports';
import IBILoader from './components/UIComponents/IBILoader';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter getUserConfirmation={UserLeaveConfirmation}>
                    <Suspense fallback={<IBILoader />}>
                        <App />
                    </Suspense>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
