import {
    SET_ISACCESS_REQUEST,
    BuildingsActionTypes,
    GET_BUILDINGS_COMPANY_LIST,
    GET_BUILDINGS_COMPANY_LIST_SUCCESS,
    GET_BUILDING_TYPE_LIST,
    GET_BUILDING_TYPE_LIST_SUCCESS,
    CHECK_BUILDING,
    CHECK_BUILDING_SUCCESS,
    addNewBuildingParams,
    ADD_NEW_BUILDING,
    ADD_NEW_BUILDING_SUCCESS,
    ADD_NEW_BUILDING_FAILURE,
    GET_BUILDINGS_OPTIONS,
    GET_BUILDINGS_OPTIONS_SUCCESS,
    SEND_BUILDINGS_ACCESS_REQUEST,
    SEND_BUILDINGS_ACCESS_REQUEST_SUCCESS,
    GET_BUILDING_DETAILS,
    GET_BUILDING_DETAILS_RECEIVED,
    GET_BUILDINGS_DATA,
    GET_BUILDINGS_DATA_SUCCESS,
    GET_USERS,
    GET_USERS_RECEIVED,
    UPDATE_BUILDING,
    UPDATE_BUILDING_SUCCESS,
    ADD_BUILDING_ACCESS,
    ADD_BUILDING_ACCESS_SUCCESS,
    ASSIGN_CERTIFIED_LEVEL,
    ASSIGN_CERTIFIED_LEVEL_SUCCESS,
    REMOVE_CERTIFIED_LEVEL,
    REMOVE_CERTIFIED_LEVEL_SUCCESS,
    MAP_BOX_OBJECT,
    SET_BUILDING_PROPERTY_TAB
} from './types';

export const setIsAccessRequest = (isAccessRequest: boolean): BuildingsActionTypes => ({
    type: SET_ISACCESS_REQUEST,
    isAccessRequest
});

export const getBuildingsCompanyListData = (params: any): BuildingsActionTypes => ({
    type: GET_BUILDINGS_COMPANY_LIST,
    params
});

export const getBuildingsCompanyListDataRecieved = (payload: any): BuildingsActionTypes => ({
    type: GET_BUILDINGS_COMPANY_LIST_SUCCESS,
    payload
});

export const getBuildingTypeListData = (params: any): BuildingsActionTypes => ({
    type: GET_BUILDING_TYPE_LIST,
    params
});

export const getBuildingTypeListDataRecieved = (payload: any): BuildingsActionTypes => ({
    type: GET_BUILDING_TYPE_LIST_SUCCESS,
    payload
});

export const setBuildingPropertyTab = (tab: string): BuildingsActionTypes => ({
    type: SET_BUILDING_PROPERTY_TAB,
    tab
});

export const checkBuilding = (
    params: any,
    history: any,
    buildingData: any,
    isNewBuilding: any
): BuildingsActionTypes => ({
    type: CHECK_BUILDING,
    params,
    history,
    buildingData,
    isNewBuilding
});

export const checkBuildingSuccess = (params: any): BuildingsActionTypes => ({
    type: CHECK_BUILDING_SUCCESS,
    params
});

export const addNewBuilding = (
    params: addNewBuildingParams,
    history: any
): BuildingsActionTypes => ({
    type: ADD_NEW_BUILDING,
    params,
    history
});

export const addNewBuildingSuccess = (): BuildingsActionTypes => ({
    type: ADD_NEW_BUILDING_SUCCESS
});

export const addNewBuildingFailure = (): BuildingsActionTypes => ({
    type: ADD_NEW_BUILDING_FAILURE
});

export const getBuildingsOptions = (): BuildingsActionTypes => ({
    type: GET_BUILDINGS_OPTIONS
});

export const getBuildingsOptionsSuccess = (payload: any): BuildingsActionTypes => ({
    type: GET_BUILDINGS_OPTIONS_SUCCESS,
    payload
});

export const sendBuildingsAccessRequest = (
    params: any,
    history: any,
    setShowLoader?: any
): BuildingsActionTypes => ({
    type: SEND_BUILDINGS_ACCESS_REQUEST,
    params,
    history,
    setShowLoader
});

export const sendBuildingsAccessRequestSuccess = (): BuildingsActionTypes => ({
    type: SEND_BUILDINGS_ACCESS_REQUEST_SUCCESS
});

export const getBuildingDetails = (id: number): BuildingsActionTypes => ({
    type: GET_BUILDING_DETAILS,
    id
});

export const getBuildingDetailsReceived = (payload: any): BuildingsActionTypes => ({
    type: GET_BUILDING_DETAILS_RECEIVED,
    payload
});

export const getBuildingsData = (params: any, appendData?: boolean): BuildingsActionTypes => ({
    type: GET_BUILDINGS_DATA,
    params,
    appendData
});

export const getBuildingsDataRecieved = (
    payload: any,
    appendData = false
): BuildingsActionTypes => ({
    type: GET_BUILDINGS_DATA_SUCCESS,
    payload,
    appendData
});

export const getUsers = (id: number): BuildingsActionTypes => ({
    type: GET_USERS,
    id
});

export const getUsersReceived = (payload: any): BuildingsActionTypes => ({
    type: GET_USERS_RECEIVED,
    payload
});

export const updateBuildingData = (
    params: any,
    id: number,
    history?: any
): BuildingsActionTypes => ({
    type: UPDATE_BUILDING,
    params,
    id,
    history
});

export const updateBuildingDataSuccess = (): BuildingsActionTypes => ({
    type: UPDATE_BUILDING_SUCCESS
});

export const assignCertifiedLevel = (params: any, name: any): BuildingsActionTypes => ({
    type: ASSIGN_CERTIFIED_LEVEL,
    params,
    name
});

export const assignCertifiedLevelSuccess = (): BuildingsActionTypes => ({
    type: ASSIGN_CERTIFIED_LEVEL_SUCCESS
});

export const removeCertifiedLevel = (params: any, name: any): BuildingsActionTypes => ({
    type: REMOVE_CERTIFIED_LEVEL,
    params,
    name
});

export const removeCertifiedLevelSuccess = (): BuildingsActionTypes => ({
    type: REMOVE_CERTIFIED_LEVEL_SUCCESS
});

export const addBuildingAccess = (params: any, id?: number): BuildingsActionTypes => ({
    type: ADD_BUILDING_ACCESS,
    params,
    id
});

export const addBuildingAccessSuccess = (): BuildingsActionTypes => ({
    type: ADD_BUILDING_ACCESS_SUCCESS
});

export const setMapBoxObject = (data: any, markerData: any): BuildingsActionTypes => ({
    type: MAP_BOX_OBJECT,
    data,
    markerData
});
