import { GetRequestInterface } from '../../../interfaces/interfaces';

export const ADD_CLIENT_COMPANY = 'ADD_CLIENT_COMPANY';
export const ADD_CLIENT_COMPANY_SUCCESS = 'ADD_CLIENT_COMPANY_SUCCESS';
export const ADD_CLIENT_COMPANY_FAILURE = 'ADD_CLIENT_COMPANY_FAILURE';
export const GET_CLIENT_COMPANY = 'GET_CLIENT_COMPANY';
export const GET_CLIENT_COMPANY_SUCCESS = 'GET_CLIENT_COMPANY_SUCCESS';

export interface ClientCompanyInterFace {
    companyName: string;
    companyType: Array<any>;
}

export interface clientCompanyNamesJson {
    results: [];
}
export interface clientCompanyParams extends GetRequestInterface {
    params: Array<ClientCompanyInterFace>;
}

export interface clientCompanyNamesParams {
    params: {};
}
export interface addClientCompanyAction {
    type: typeof ADD_CLIENT_COMPANY;
    params: clientCompanyParams;
    history?: any;
}

export interface getClientCompanyAction {
    type: typeof GET_CLIENT_COMPANY;
    params: clientCompanyNamesParams;
}

export interface addClientCompanySuccessAction {
    type: typeof ADD_CLIENT_COMPANY_SUCCESS;
}

export interface addClientCompanyFailureAction {
    type: typeof ADD_CLIENT_COMPANY_FAILURE;
}
export interface getClientCompanySuccessAction {
    type: typeof GET_CLIENT_COMPANY_SUCCESS;
    data: clientCompanyNamesJson;
}

export type AddClientCompanyActionTypes =
    | addClientCompanyAction
    | addClientCompanySuccessAction
    | addClientCompanyFailureAction
    | getClientCompanyAction
    | getClientCompanySuccessAction;

export interface AddClientCompanyState {
    isClientCompanyAdded: boolean;
    isFetching: boolean;
    clientCompanyNamesData: any;
}
