import { combineReducers } from 'redux';
import Login from './Login';
import Common from './Common';
import UserProfileUpdate from './UserProfileUpdate';
import CurrentUser from './CurrentUser';
import AddClientCompany from './AddClientCompany';
import ViewCompany from './ViewCompany';
import Buildings from './Buildings';
import Company from './Company';
import CreateNewUser from './CreateNewUser';
import ViewUser from './ViewUser';
import Certification from './Certification';
import Category from './Category';
import Finances from './Finances';
import Questionnaire from './Questionnaire';
import Notifications from './Notifications';
import Benchmarking from './Benchmarking';
import Invoice from './Invoice';
import Documents from './Documents';
import Verification from './Verification';
import Waffle from './Waffle';

const rootReducer = combineReducers({
    Login,
    Common,
    UserProfileUpdate,
    CurrentUser,
    AddClientCompany,
    ViewCompany,
    Buildings,
    Company,
    CreateNewUser,
    ViewUser,
    Certification,
    Category,
    Finances,
    Questionnaire,
    Notifications,
    Benchmarking,
    Documents,
    Invoice,
    Verification,
    Waffle
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
