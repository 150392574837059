import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import images from '../../../../assets';
import { constantStrings } from '../../../../constantStrings';
import GenericImage from '../../../DesignSystem/GenericImage/GenericImage';
import RCSlider from '../../../DesignSystem/RCSlider';
import MapBoxMap from '../../../Properties/Buildings/MapBoxMap';
import { FlexRow, FormHeading, FormPrimaryContainer } from '../../../styles';
import {
    CertificateAndMapBoxContainer,
    FeatureProjectBox,
    MapAndGraphContainer
} from '../../styles';
import BBBuildingsComponent from './BBBuildingsComponent';
import BBCertifiedBuildingsComponent from './BBCertifiedBuildings';
import CardsComponent from './BBCertifiedBuildings/CardsComponent';

const buildingsData = (building: any) => [
    { heading: i18n.t(constantStrings.buildingName), context: building.buildingName },
    { heading: i18n.t(constantStrings.buildingType), context: building.assetTypeName },
    {
        heading: i18n.t(constantStrings.buildingAddress),
        context: `${building.addressLane1}${
            building.addressLane2 ? `, ${building.addressLane2}` : ''
        }`
    },
    {
        heading: i18n.t(constantStrings.managementCompany),
        context: building.managementCompanyName
    },
    { heading: i18n.t(constantStrings.certificationName), context: building.certificationName },
    { heading: i18n.t(constantStrings.certificationLevel), context: building.certificationLevel },
    {
        heading: i18n.t(constantStrings.certificationDate),
        context: building.certificationIssueDate
    },
    { heading: i18n.t(constantStrings.expiryDate), context: building.certificationExpiryDate },
    { heading: i18n.t(constantStrings.localAssociation), context: building.country }
];

const DashBoardsAndFPComponent = ({ allBuildingsData, filteredBuildingData }: any) => {
    const { t } = useTranslation();

    const featureBuilding = allBuildingsData?.buildingData?.filter(
        (data: any) => data.isFeatureBuilding
    );

    return (
        <>
            <FlexRow className='m-1 flex-column flex-lg-row'>
                <MapAndGraphContainer className={'col-lg-10 col-12'}>
                    <CertificateAndMapBoxContainer className='m-1 gap-2 flex-column flex-lg-row'>
                        <CardsComponent {...{ filteredBuildingData }} />
                        <BBBuildingsComponent {...{ filteredBuildingData }} />
                    </CertificateAndMapBoxContainer>
                    <div className='col'>
                        <BBCertifiedBuildingsComponent {...{ filteredBuildingData }} />
                    </div>
                </MapAndGraphContainer>
                {featureBuilding?.length > 0 && (
                    <FeatureProjectBox className={'col-12 col-lg-2'}>
                        <div className={'d-flex w-100  m-1'}>
                            <FormPrimaryContainer border={true} className='w-100'>
                                <div className={'summary-slider'}>
                                    <FormHeading className={'mt-0 mb-2'}>
                                        {t(constantStrings.featuredProject)}
                                    </FormHeading>
                                    <RCSlider className={'building-summary-slider'}>
                                        {false ? (
                                            <GenericImage
                                                className={'small-profile-icon'}
                                                path={''}
                                                alt={t(constantStrings.icon)}
                                            />
                                        ) : (
                                            <img
                                                src={images['BuildingIcon']}
                                                className={'building-image'}
                                                alt={`${t(constantStrings.icon)}`}
                                            />
                                        )}
                                        <FlexRow className={'w-100'}>
                                            <MapBoxMap
                                                draggable={false}
                                                buildingLat={featureBuilding[0].latitude}
                                                buildingLng={featureBuilding[0].longitude}
                                            />
                                        </FlexRow>
                                    </RCSlider>
                                </div>

                                <div className={'summary-details'}>
                                    <RCSlider className={'building-summary-slider'}>
                                        {featureBuilding.map(
                                            (featureBuildingData: any, index: number) => (
                                                <div key={index} className='col-12'>
                                                    <div key={index} className='row'>
                                                        {buildingsData(featureBuildingData)?.map(
                                                            (item: any, index: number) => (
                                                                <div
                                                                    key={index}
                                                                    className='col-12 col-md-6 col-lg-12'
                                                                >
                                                                    <h4>{item.heading}</h4>
                                                                    <p>{item.context}</p>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </RCSlider>
                                </div>
                            </FormPrimaryContainer>
                        </div>
                    </FeatureProjectBox>
                )}
            </FlexRow>
        </>
    );
};

export default DashBoardsAndFPComponent;
