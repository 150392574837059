import { all, put, takeLatest } from 'redux-saga/effects';
import {
    deleteApiData,
    getApiData,
    patchApiData,
    postApiData,
    uploadFileWithProgress
} from '../../CommonUtilities';
import { api_prefix } from '../../config';
import {
    directoriesRecieved,
    filesRecieved,
    createDirectoriesSuccess,
    createFilesSuccess,
    deleteDirectoriesSuccess,
    editDirectoriesSuccess,
    editFilesSuccess,
    getDirectories,
    getFiles
} from '../actions';
import {
    createDirectoriesAction,
    createFilesAction,
    CREATE_DIRECTORIES,
    CREATE_FILES,
    deleteDirectoriesAction,
    deleteFilesAction,
    DELETE_DIRECTORIES,
    DELETE_FILES,
    editDirectoriesAction,
    editFilesAction,
    EDIT_DIRECTORIES,
    EDIT_FILES,
    getDirectoriesAction,
    getFilesAction,
    GET_DIRECTORIES,
    GET_FILES
} from '../actions/Documents/types';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* getDirectoriesRequest(action: getDirectoriesAction): any {
    const json = yield getApiData(`${api_prefix}${action.url}`, action);
    if (json) {
        yield put(directoriesRecieved(json));
    }
}

export function* getDirectoriesSagas() {
    yield all([takeLatest(GET_DIRECTORIES, getDirectoriesRequest)]);
}

function* editDirectoriesRequest(action: editDirectoriesAction): any {
    const json = yield patchApiData(
        `${api_prefix}${action.url}${action.id}/`,
        action,
        i18n.t(
            `${action.isSubDir ? constantStrings.subFolder : constantStrings.folder} ${
                constantStrings.renamedSuccessfully
            }`
        ),
        true
    );
    if (json) {
        yield put(editDirectoriesSuccess());
    }
    yield put(getDirectories(action.url, { ordering: 'id', limit: 'all' }));
}

export function* editDirectoriesSagas() {
    yield all([takeLatest(EDIT_DIRECTORIES, editDirectoriesRequest)]);
}

function* createDirectoriesRequest(action: createDirectoriesAction): any {
    const json = yield postApiData(
        `${api_prefix}${action.url}`,
        action,
        i18n.t(
            `${action.params.parent ? constantStrings.subFolder : constantStrings.folder} ${
                constantStrings.addedSuccessfully
            }`
        ),
        true
    );
    if (json) {
        yield put(createDirectoriesSuccess());
    }
    yield put(getDirectories(action.url, { ordering: 'id', limit: 'all' }));
}

export function* createDirectoriesSagas() {
    yield all([takeLatest(CREATE_DIRECTORIES, createDirectoriesRequest)]);
}

function* deleteDirectoriesRequest(action: deleteDirectoriesAction): any {
    const json = yield deleteApiData(
        `${api_prefix}${action.url}${action.directoryId}/`,
        action,
        i18n.t(
            `${action.isSubDir ? constantStrings.subFolder : constantStrings.folder} ${
                constantStrings.deletedSuccess
            }`
        )
    );
    if (!json) {
        yield put(deleteDirectoriesSuccess());
    }
    yield put(getDirectories(action.url, { ordering: 'id', limit: 'all' }));
}

export function* deleteDirectoriesSagas() {
    yield all([takeLatest(DELETE_DIRECTORIES, deleteDirectoriesRequest)]);
}

function* getFilesRequest(action: getFilesAction): any {
    const json = yield getApiData(`${api_prefix}${action.url}`, action);
    yield put(filesRecieved(json));
}

export function* getFilesSagas() {
    yield all([takeLatest(GET_FILES, getFilesRequest)]);
}

function* createFilesRequest(action: createFilesAction): any {
    const json = yield uploadFileWithProgress(
        `${api_prefix}${action.url}`,
        action,
        i18n.t(constantStrings.fileUploaded),
        true
    );
    if (json) {
        yield put(createFilesSuccess());
    }
    yield put(getFiles(action.url, { ordering: 'id', limit: 'all' }));
}

export function* createFilesSagas() {
    yield all([takeLatest(CREATE_FILES, createFilesRequest)]);
}

function* editFilesRequest(action: editFilesAction): any {
    const json = yield patchApiData(
        `${api_prefix}${action.url}${action.id}/`,
        action,
        i18n.t(constantStrings.fileRenamed),
        true
    );
    if (json) {
        yield put(editFilesSuccess());
    }
    yield put(getFiles(action.url, { ordering: 'id', limit: 'all' }));
}

export function* editFilesSaga() {
    yield all([takeLatest(EDIT_FILES, editFilesRequest)]);
}

function* deleteFileRequest(action: deleteFilesAction): any {
    const json = yield deleteApiData(
        `${api_prefix}${action.url}${action.id}/`,
        action,
        i18n.t(constantStrings.fileDeleted)
    );
    if (!json) {
        yield put(deleteDirectoriesSuccess());
    }
    yield put(getFiles(action.url, { ordering: 'id', limit: 'all' }));
}

export function* deleteFilesSaga() {
    yield all([takeLatest(DELETE_FILES, deleteFileRequest)]);
}
