export const AnimateFadeInClassnames = 'animate__animated animate__fadeIn';
export const BoxPrimaryClassnames =
    'd-flex align-items-center justify-content-between p-3 py-2 flex-wrap flex-lg-nowrap';
export const BoxPrimaryRightClassnames =
    'd-flex align-items-center flex-wrap right justify-content-start justify-content-sm-end';
export const SmallScreenTextHideClassnames = 'd-none d-md-block';
export const VHFlexCenterClassnames = 'd-flex justify-content-center align-items-center';
export const CollapseSubHeadingClassnames =
    'subMenuHeading d-flex justify-content-between p-3 border-top';
export const VerticalCenterWrapClassnames = 'd-flex align-items-center flex-wrap';
