import React, { FunctionComponent } from 'react';
import Button from './DesignSystem/Button';
import WithModal from './DesignSystem/Modal';
import { ButtonsList } from './styles';
import { useTranslation } from 'react-i18next';
import { constantStrings } from '../constantStrings';

const UserConfirmationModal: FunctionComponent = ({ onCancel, onConfirm, content }: any) => {
    const { t } = useTranslation();
    return (
        <>
            <div className='d-flex align-items-center m-3'>{content}</div>
            <ButtonsList className={'d-flex align-items-center '}>
                <Button
                    variant='secondary'
                    title={t(constantStrings.cancel)}
                    className={'flex-fill'}
                    onClick={onCancel}
                >
                    {t(constantStrings.cancel)}
                </Button>
                <Button
                    variant='primary'
                    title={t(constantStrings.ok)}
                    className={'flex-fill'}
                    onClick={onConfirm}
                >
                    {t(constantStrings.ok)}
                </Button>
            </ButtonsList>
        </>
    );
};

export default WithModal(UserConfirmationModal);
