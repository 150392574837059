import styled from 'styled-components';
import { entryInputHeight } from '../../../variables';
import { fontMedium } from '../../styles';

interface SearchInputProps {
    isFindBuildingsSearchBox?: boolean;
    isSearchboxSecondaryBorderRadius?: boolean;
}

export const SearchInput = styled.input<SearchInputProps>`
    height: ${entryInputHeight};
    background-color: ${(props) => props.theme.standard.WHITE};
    border: ${(props) => (props.isFindBuildingsSearchBox ? 'none' : '1px solid')};
    border-color: ${(props) => props.theme.light_primary.LIGHT_PRIMARY};
    border-radius: ${(props) => (props.isSearchboxSecondaryBorderRadius ? '8px' : '20px')};
    padding: 5px 15px;
    ${fontMedium}
    padding-right: 40px;
    font-size: 1rem;
    width: 100%;
    color: ${(props) => props.theme.primary.primaryHeadingFontColor};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: ${(props) => (props.isFindBuildingsSearchBox ? '0px 4px 16px #0000001A' : 'none')};
    opacity: 2;
    padding-left: 2.5rem;

    &:hover {
        border: solid 1px ${(props: any) => props.theme.primary.THEME};
    }

    &::placeholder {
        opacity: 1;

        @media only screen and (max-width: 480px) {
            font-size: 0.75rem;
        }
    }

    @media (-webkit-min-device-pixel-ratio: 1.2) {
        height: calc(${entryInputHeight} / 1.2);
        border-radius: ${(props) =>
            props.isSearchboxSecondaryBorderRadius ? 'calc(8px / 1.2)' : 'calc(20px / 1.2)'};
        font-size: 0.8rem;
    }

    &:focus-visible {
        outline: none;
        border: 1.5px solid ${(props) => props.theme.primary.THEME};
    }
`;
