import styled from 'styled-components';
import { fontSize_BASE, fontSize_XXXL } from '../../customFonts';
import { fontRegular } from '../../styles';

export const HeadingText = styled.div`
    ${fontSize_XXXL}
    font-weight: 700;
    letter-spacing: 0px;
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
`;

export const HeadingTagLineText = styled.div`
    ${fontSize_BASE}
    ${fontRegular}
  color: ${(props) => props.theme.light_primary.LIGHT_NONARY};
`;
