export const GET_NOTIFICATIONS_MESSAGE = 'GET_NOTIFICATIONS_MESSAGE';
export const GET_PREVIOUS_NOTIFICATIONS_MESSAGE = 'GET_PREVIOUS_NOTIFICATIONS_MESSAGE';
export const GET_PREVIOUS_NOTIFICATIONS_MESSAGE_SUCCESS =
    'GET_PREVIOUS_NOTIFICATIONS_MESSAGE_SUCCESS';
export const MARK_AS_READ_NOTIFICATION = 'MARK_AS_READ_NOTIFICATION';
export const MARK_AS_READ_NOTIFICATION_SUCCESS = 'MARK_AS_READ_NOTIFICATION_SUCCESS';
export const GET_PENDING_REQUEST_ACCESS_DATA = 'GET_PENDING_REQUEST_ACCESS_DATA';
export const GET_PENDING_REQUEST_ACCESS_DATA_SUCCESS = 'GET_PENDING_REQUEST_ACCESS_DATA_SUCCESS';
export const MARK_ALL_AS_READ_NOTIFICATION = 'MARK_ALL_AS_READ_NOTIFICATION';
export const MARK_ALL_AS_READ_NOTIFICATION_SUCCESS = 'MARK_ALL_AS_READ_NOTIFICATION_SUCCESS';
export const SET_PERMISSION_TAB = 'SET_PERMISSION_TAB';

export interface getNotificationsMessageAction {
    type: typeof GET_NOTIFICATIONS_MESSAGE;
    message: any;
}

export interface getPreviousNotificationsMessageAction {
    type: typeof GET_PREVIOUS_NOTIFICATIONS_MESSAGE;
    params: any;
    url?: string;
    appendData?: boolean;
}

export interface getPreviousNotificationsMessageActionSuccess {
    type: typeof GET_PREVIOUS_NOTIFICATIONS_MESSAGE_SUCCESS;
    previousMessage: any;
    appendData?: boolean;
}

export interface markAsReadNotificationAction {
    type: typeof MARK_AS_READ_NOTIFICATION;
    params: any;
    notificationParams: any;
}

export interface markAsReadNotificationSuccessAction {
    type: typeof MARK_AS_READ_NOTIFICATION_SUCCESS;
}

export interface markAllAsReadNotificationAction {
    type: typeof MARK_ALL_AS_READ_NOTIFICATION;
    params: any;
}

export interface markAllAsReadNotificationSuccessAction {
    type: typeof MARK_ALL_AS_READ_NOTIFICATION_SUCCESS;
}

export interface getPendingRequestAccessDataAction {
    type: typeof GET_PENDING_REQUEST_ACCESS_DATA;
    params: any;
    filterType: string;
    appendData: boolean;
}

export interface getPendingRequestAccessDataSuccessAction {
    type: typeof GET_PENDING_REQUEST_ACCESS_DATA_SUCCESS;
    payload: any;
    appendData?: boolean;
}

export interface setPermissionTabAction {
    type: typeof SET_PERMISSION_TAB;
    data: string;
}

export type notificationsActionTypes =
    | getNotificationsMessageAction
    | getPreviousNotificationsMessageAction
    | getPreviousNotificationsMessageActionSuccess
    | markAsReadNotificationAction
    | markAsReadNotificationSuccessAction
    | markAllAsReadNotificationAction
    | markAllAsReadNotificationSuccessAction
    | getPendingRequestAccessDataAction
    | getPendingRequestAccessDataSuccessAction
    | setPermissionTabAction;

export interface NotificationsState {
    isFetchingPreviousMessageData: boolean;
    isFetchingPendingRequestAccessData: boolean;
    notificationMessage: any;
    previousNotificationsMessageData: any;
    pendingRequestAccessData: any;
    selectedPermissionTab: string;
}
