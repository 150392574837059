import styled from 'styled-components';
import { variant } from 'styled-system';
import { theme } from '../../../theme';
import { entryInputHeight } from '../../../variables';

type Props = {
    variant?: string;
};

export interface FormElementProps {
    className?: string;
    selected?: boolean;
    determinate?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    title?: string;
    size?: number;
    id?: string;
    value?: string;
    defaultChecked?: boolean;
    onChange?: (useEvent: never) => void;
    color?: string;
    name?: string;
    backgroundColor?: string;
    borderColor?: string;
    hoverColor?: string;
    customStyles?: {
        paddingLeft?: string;
        disabledColor?: string;
        opacity?: string;
    };
}

export const VariantLableHeading = styled.label<Props>`
    ${variant({
        variants: {
            primary: {
                color: theme.light_primary.LIGHT_QUINARY,
                fontSize: '0.875rem',
                fontWeight: '400'
            },
            loginpage: {
                color: theme.standard.WHITE,
                fontSize: '1rem',
                transform: 'translateY(-40px)'
            },
            uploadedtextlabel: {
                color: theme.primary.THEME,
                cursor: 'pointer'
            },
            choosefilelabel: {
                border: '1px solid ' + theme.primary.THEME,
                color: `${theme.primary.THEME} !important`,
                borderRadius: '8px',
                minHeight: entryInputHeight,
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
                minWidth: 'fit-content'
            },
            disabled: {
                color: '#808080',
                cursor: 'pointer',
                pointerEvents: 'none'
            }
        }
    })}
`;

export const VariantEntryInput = styled.input<Props>`
    ${variant({
        variants: {
            primary: {
                backgroundColor: theme.standard.WHITE,
                border: '1px solid',
                borderRadius: '8px',
                padding: '5px 10px',
                borderColor: theme.light_primary.LIGHT_PRIMARY,
                color: theme.light_primary.LIGHT_NONARY,

                '&:hover,&:focus,&:focus-visible': {
                    borderColor: theme.primary.THEME,
                    outline: 'none'
                },
                '::placeholder': {
                    color: theme.light_primary.LIGHT_NONARY
                }
            },
            disabled: {
                backgroundColor: theme.standard.LIGHT_BLACK,
                color: theme.light_primary.LIGHT_NONARY,
                border: '1px solid',
                borderRadius: '8px',
                padding: '5px 10px',
                borderColor: theme.standard.LIGHT_BLACK
            },
            loginpage: {
                border: 'none',
                borderBottom: '2px solid ' + theme.light_primary.LIGHT_PRIMARY,
                borderRadius: '0px',
                padding: '10px',
                caretColor: theme.primary.THEME,
                outline: 'none',

                '&:hover,&:focus,&:focus-visible': {
                    borderColor: theme.primary.THEME
                }
            },
            addbuttonfield: {
                backgroundColor: theme.standard.WHITE,
                border: '1px solid',
                borderRadius: '8px',
                padding: '5px 135px 5px 10px',
                borderColor: theme.light_primary.LIGHT_PRIMARY,
                color: theme.light_primary.LIGHT_NONARY,

                '&:hover,&:focus,&:focus-visible': {
                    borderColor: theme.primary.THEME,
                    outline: 'none'
                },
                '::placeholder': {
                    color: theme.light_primary.LIGHT_NONARY
                }
            }
        }
    })}
`;

export const VariantEntryTextArea = styled.textarea<Props>`
    ${variant({
        variants: {
            primary: {
                '&:hover,&:focus,&:focus-visible': {
                    borderColor: theme.primary.THEME,
                    border: '1px solid',
                    outline: 'none',
                    backgroundColor: theme.standard.WHITE
                }
            },
            disabled: {
                backgroundColor: theme.standard.LIGHT_BLACK,
                color: theme.light_primary.LIGHT_NONARY,
                borderColor: theme.standard.LIGHT_BLACK
            }
        }
    })}
`;
