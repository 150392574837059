import i18n from './translations/i18n';
import axios from 'axios';
import { baseHTTPURL, environment } from './config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { clearLocalStorageAfterLogout } from './CommonUtilities';
import { constantStrings } from './constantStrings';

axios.defaults.baseURL = baseHTTPURL;

axios.interceptors.request.use(
    (config) => {
        axios.defaults.headers.delete['Content-Type'] = 'application/json; charset=utf-8';
        axios.defaults.headers.patch['Content-Type'] = 'application/json; charset=utf-8';
        axios.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8';
        axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

        if (!window.navigator.onLine) {
            throw new axios.Cancel(i18n.t(constantStrings.looksLikeOffline));
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            clearLocalStorageAfterLogout(error.response.data?.code === 'token_not_valid');
        }
        return Promise.reject(error);
    }
);

if (environment === 'PRODUCTION') {
    Sentry.init({
        dsn: 'https://4e9690a093ea419aa13a92b9a45e1c33@o72951.ingest.sentry.io/6539341',
        attachStacktrace: true,
        integrations: [new Integrations.BrowserTracing()]
    });
}
