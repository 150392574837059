import { GetResponseInterface, GetRequestInterface } from '../../../interfaces/interfaces';

export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAILURE = 'CREATE_NEW_USER_FAILURE';

export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'CHECK_USER_FAILURE';

export const USER_EXIST = 'USER_EXIST';
export const USER_EXIST_RESET = 'USER_EXIST_RESET';

export interface CreateNewUserInterface {}

export interface createNewUserParams extends GetRequestInterface {
    params: Array<CreateNewUserInterface>;
}

export interface createNewUserJson extends GetResponseInterface {
    results: [];
}

export interface createNewUserAction {
    type: typeof CREATE_NEW_USER;
    params: createNewUserParams;
    history: any;
    limit?: any;
}
export interface createNewUserSuccessAction {
    type: typeof CREATE_NEW_USER_SUCCESS;
}

export interface createNewUserFailureAction {
    type: typeof CREATE_NEW_USER_FAILURE;
}

export interface checkUserAction {
    type: typeof CHECK_USER;
    payload: any;
}

export interface checkUserSuccessAction {
    type: typeof CHECK_USER_SUCCESS | typeof USER_EXIST;
}

export interface checkUserFailureAction {
    type: typeof CHECK_USER_FAILURE | typeof USER_EXIST_RESET;
}

export type createNewUserActionTypes =
    | createNewUserAction
    | createNewUserSuccessAction
    | checkUserAction
    | checkUserSuccessAction
    | createNewUserFailureAction
    | checkUserFailureAction;

export interface createNewUserState {
    isNewUserCreated: boolean;
    isUserExist: boolean;
    isUserChecked: boolean;
}
