import {
    categoryActionTypes,
    CREATE_CATEGORY,
    CREATE_CATEGORY_SUCCESS,
    CREATE_SUB_CATEGORY,
    CREATE_SUB_CATEGORY_SUCCESS,
    CREATE_TOPIC,
    CREATE_TOPIC_SUCCESS,
    EDIT_CATEGORY,
    EDIT_CATEGORY_SUCCESS,
    EDIT_SUB_CATEGORY,
    EDIT_SUB_CATEGORY_SUCCESS,
    EDIT_TOPIC,
    EDIT_TOPIC_SUCCESS,
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_SUB_CATEGORY_LIST,
    GET_SUB_CATEGORY_LIST_SUCCESS,
    GET_TOPIC_LIST,
    GET_TOPIC_LIST_SUCCESS
} from './types';

export const getCategoryListData = (params: any, appendData?: boolean): categoryActionTypes => ({
    type: GET_CATEGORY_LIST,
    params,
    appendData
});

export const getCategoryListDataSuccess = (
    payload: any,
    appendData?: boolean
): categoryActionTypes => ({
    type: GET_CATEGORY_LIST_SUCCESS,
    payload,
    appendData
});

export const createCategory = (params: any): categoryActionTypes => ({
    type: CREATE_CATEGORY,
    params
});

export const createCategorySuccess = (): categoryActionTypes => ({
    type: CREATE_CATEGORY_SUCCESS
});

export const editCategory = (params: any, id: number): categoryActionTypes => ({
    type: EDIT_CATEGORY,
    params,
    id
});

export const editCategorySuccess = (): categoryActionTypes => ({
    type: EDIT_CATEGORY_SUCCESS
});

export const getSubCategoryListData = (params: any, appendData?: boolean): categoryActionTypes => ({
    type: GET_SUB_CATEGORY_LIST,
    params,
    appendData
});

export const getSubCategoryListDataSuccess = (
    payload: any,
    appendData?: boolean
): categoryActionTypes => ({
    type: GET_SUB_CATEGORY_LIST_SUCCESS,
    payload,
    appendData
});

export const createSubCategory = (params: any): categoryActionTypes => ({
    type: CREATE_SUB_CATEGORY,
    params
});

export const createSubCategorySuccess = (): categoryActionTypes => ({
    type: CREATE_SUB_CATEGORY_SUCCESS
});

export const editSubCategory = (params: any, id: number): categoryActionTypes => ({
    type: EDIT_SUB_CATEGORY,
    params,
    id
});

export const editSubCategorySuccess = (): categoryActionTypes => ({
    type: EDIT_SUB_CATEGORY_SUCCESS
});

export const getTopicListData = (params: any, appendData?: boolean): categoryActionTypes => ({
    type: GET_TOPIC_LIST,
    params,
    appendData
});

export const getTopicListDataSuccess = (
    payload: any,
    appendData?: boolean
): categoryActionTypes => ({
    type: GET_TOPIC_LIST_SUCCESS,
    payload,
    appendData
});

export const createTopic = (params: any): categoryActionTypes => ({
    type: CREATE_TOPIC,
    params
});

export const createTopicSuccess = (): categoryActionTypes => ({
    type: CREATE_TOPIC_SUCCESS
});

export const editTopic = (params: any, id: number): categoryActionTypes => ({
    type: EDIT_TOPIC,
    params,
    id
});

export const editTopicSuccess = (): categoryActionTypes => ({
    type: EDIT_TOPIC_SUCCESS
});
