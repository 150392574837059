import {
    certificationActionTypes,
    GET_CERTIFICATION_TYPES_LIST,
    GET_CERTIFICATION_TYPES_LIST_SUCCESS,
    certificationState,
    CREATE_CERTIFICATION_TYPE,
    CREATE_CERTIFICATION_TYPE_SUCCESS,
    CREATE_CERTIFICATION_LEVEL_DATA,
    CREATE_CERTIFICATION_LEVEL_DATA_SUCCESS,
    GET_CERTIFICATION_LEVEL_LIST,
    GET_CERTIFICATION_LEVEL_LIST_SUCCESS,
    GET_CERTIFICATION_LEVEL_OPTIONS,
    GET_CERTIFICATION_LEVEL_OPTIONS_SUCCESS,
    EDIT_CERTIFICATION_LEVEL_DATA,
    EDIT_CERTIFICATION_LEVEL_DATA_SUCCESS,
    GET_CERTIFICATION_BUILDINGS_LIST,
    GET_CERTIFICATION_BUILDINGS_LIST_SUCCESS
} from '../actions/Certification/types';

const initialState: certificationState = {
    certificationTypesListData: null,
    certificationLevelListData: null,
    certificationLevelOptionsData: null,
    certificationBuildingsListData: null,
    isFetchingCertificationTypesList: false,
    isFetchingCertificationBuildingsList: false
};

export const Certification = (
    state = initialState,
    action: certificationActionTypes
): certificationState => {
    switch (action.type) {
        case CREATE_CERTIFICATION_TYPE: {
            return {
                ...state
            };
        }
        case CREATE_CERTIFICATION_TYPE_SUCCESS: {
            return {
                ...state
            };
        }

        case GET_CERTIFICATION_TYPES_LIST: {
            return {
                ...state,
                isFetchingCertificationTypesList: true
            };
        }

        case GET_CERTIFICATION_TYPES_LIST_SUCCESS: {
            if (action.shouldAppend) {
                action.payload.results = [
                    ...state.certificationTypesListData.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                certificationTypesListData: action.payload,
                isFetchingCertificationTypesList: false
            };
        }

        case CREATE_CERTIFICATION_LEVEL_DATA: {
            return {
                ...state
            };
        }
        case CREATE_CERTIFICATION_LEVEL_DATA_SUCCESS: {
            return {
                ...state
            };
        }

        case EDIT_CERTIFICATION_LEVEL_DATA: {
            return {
                ...state
            };
        }
        case EDIT_CERTIFICATION_LEVEL_DATA_SUCCESS: {
            return {
                ...state
            };
        }

        case GET_CERTIFICATION_LEVEL_LIST: {
            return {
                ...state
            };
        }

        case GET_CERTIFICATION_LEVEL_LIST_SUCCESS: {
            return {
                ...state,
                certificationLevelListData: action.payload
            };
        }

        case GET_CERTIFICATION_LEVEL_OPTIONS: {
            return {
                ...state
            };
        }

        case GET_CERTIFICATION_LEVEL_OPTIONS_SUCCESS: {
            return {
                ...state,
                certificationLevelOptionsData: action.payload
            };
        }

        case GET_CERTIFICATION_BUILDINGS_LIST: {
            return {
                ...state,
                isFetchingCertificationBuildingsList: true
            };
        }

        case GET_CERTIFICATION_BUILDINGS_LIST_SUCCESS: {
            if (action.shouldAppend) {
                action.payload.results = [
                    ...state.certificationBuildingsListData.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                certificationBuildingsListData: action.payload,
                isFetchingCertificationBuildingsList: false
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default Certification;
