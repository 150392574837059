export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const GET_SUB_CATEGORY_LIST = 'GET_SUB_CATEGORY_LIST';
export const GET_SUB_CATEGORY_LIST_SUCCESS = 'GET_SUB_CATEGORY_LIST_SUCCESS';
export const CREATE_SUB_CATEGORY = 'CREATE_SUB_CATEGORY';
export const CREATE_SUB_CATEGORY_SUCCESS = 'CREATE_SUB_CATEGORY_SUCCESS';
export const EDIT_SUB_CATEGORY = 'EDIT_SUB_CATEGORY';
export const EDIT_SUB_CATEGORY_SUCCESS = 'EDIT_SUB_CATEGORY_SUCCESS';
export const GET_TOPIC_LIST = 'GET_TOPIC_LIST';
export const GET_TOPIC_LIST_SUCCESS = 'GET_TOPIC_LIST_SUCCESS';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const CREATE_TOPIC_SUCCESS = 'CREATE_TOPIC_SUCCESS';
export const EDIT_TOPIC = 'EDIT_TOPIC';
export const EDIT_TOPIC_SUCCESS = 'EDIT_TOPIC_SUCCESS';

export interface getCategoryListAction {
    type: typeof GET_CATEGORY_LIST;
    params: any;
    appendData?: boolean;
}

export interface getCategoryListSuccessAction {
    type: typeof GET_CATEGORY_LIST_SUCCESS;
    payload: any;
    appendData?: boolean;
}

export interface createCategoryAction {
    type: typeof CREATE_CATEGORY;
    params: any;
}

export interface createCategorySuccessAction {
    type: typeof CREATE_CATEGORY_SUCCESS;
}

export interface editCategoryAction {
    type: typeof EDIT_CATEGORY;
    params: any;
    id: number;
}

export interface editCategorySuccessAction {
    type: typeof EDIT_CATEGORY_SUCCESS;
}

export interface getSubCategoryListAction {
    type: typeof GET_SUB_CATEGORY_LIST;
    params: any;
    appendData?: boolean;
}

export interface getSubCategoryListSuccessAction {
    type: typeof GET_SUB_CATEGORY_LIST_SUCCESS;
    payload: any;
    appendData?: boolean;
}

export interface createSubCategoryAction {
    type: typeof CREATE_SUB_CATEGORY;
    params: any;
}

export interface createSubCategorySuccessAction {
    type: typeof CREATE_SUB_CATEGORY_SUCCESS;
}

export interface editSubCategoryAction {
    type: typeof EDIT_SUB_CATEGORY;
    params: any;
    id: number;
}

export interface editSubCategorySuccessAction {
    type: typeof EDIT_SUB_CATEGORY_SUCCESS;
}

export interface getTopicListAction {
    type: typeof GET_TOPIC_LIST;
    params: any;
    appendData?: boolean;
}

export interface getTopicListSuccessAction {
    type: typeof GET_TOPIC_LIST_SUCCESS;
    payload: any;
    appendData?: boolean;
}

export interface createTopicAction {
    type: typeof CREATE_TOPIC;
    params: any;
}

export interface createTopicSuccessAction {
    type: typeof CREATE_TOPIC_SUCCESS;
}

export interface editTopicAction {
    type: typeof EDIT_TOPIC;
    params: any;
    id: number;
}

export interface editTopicSuccessAction {
    type: typeof EDIT_TOPIC_SUCCESS;
}

export type categoryActionTypes =
    | getCategoryListAction
    | getCategoryListSuccessAction
    | createCategoryAction
    | createCategorySuccessAction
    | editCategoryAction
    | editCategorySuccessAction
    | getSubCategoryListAction
    | getSubCategoryListSuccessAction
    | createSubCategoryAction
    | createSubCategorySuccessAction
    | getTopicListAction
    | getTopicListSuccessAction
    | createTopicAction
    | createTopicSuccessAction
    | editSubCategoryAction
    | editSubCategorySuccessAction
    | editTopicAction
    | editTopicSuccessAction;

export interface CategoryState {
    categoryListData: any;
    subCategoryListData: any;
    topicListData: any;
}
