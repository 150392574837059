import { put, takeLatest } from 'redux-saga/effects';
import { getApiData } from '../../CommonUtilities';
import { api_prefix } from '../../config';
import { getInvoiceDetailsReceived } from '../actions';
import { getInvoiceDetailsAction, GET_INVOICE_DETAILS } from '../actions/Invoice/types';
import { defaultGetResponse } from '../../interfaces/interfaces';

function* getInvoiceDetailsSaga(action: getInvoiceDetailsAction): any {
    const json = yield getApiData(
        `${api_prefix}/buildings/${action.id}/get_invoice_payment/`,
        action
    );
    if (json.results) {
        yield put(getInvoiceDetailsReceived(json));
    } else {
        yield put(getInvoiceDetailsReceived(defaultGetResponse));
    }
}

export function* getInvoiceDetailsSagas() {
    yield takeLatest(GET_INVOICE_DETAILS, getInvoiceDetailsSaga);
}
