import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { FlexColumn } from '../../styles';
import { CustomCheckBox, ErrorText, LabelHeading, CheckboxOptions } from './styles';

interface CheckBoxInputHookProps {
    name: string;
    displayName?: string;
    options?: any;
    asterisk?: boolean;
    className?: string;
    verticalDirection?: boolean;
    noMargin?: boolean;
    isDisabled?: boolean;
    checked?: any;
    optionsStyle?: any;
}

const FormCheckBox: FunctionComponent<CheckBoxInputHookProps> = ({
    name,
    displayName,
    options,
    asterisk = true,
    className,
    verticalDirection = false,
    noMargin = false,
    isDisabled,
    checked,
    optionsStyle
}) => {
    const {
        register,
        formState: { errors }
    }: any = useFormContext();

    return (
        <FlexColumn
            className={className + ' px-2 justify-content-between ' + (noMargin ? ' ' : ' mb-3 ')}
        >
            <LabelHeading variant={''} role='checkbox'>
                {displayName}
                {asterisk ? <span className='text-danger'> * </span> : ''}
            </LabelHeading>
            <div
                className={
                    'd-flex ' + (verticalDirection ? ' flex-column ' : ' align-items-center ')
                }
            >
                {options &&
                    options?.map((option: any, index: number) => (
                        <div className='d-flex align-items-start my-1' key={index}>
                            <CustomCheckBox
                                key={index}
                                className={'d-flex align-items-center'}
                                isDisabled={isDisabled}
                            >
                                <input
                                    type='checkbox'
                                    id={name + index}
                                    name={name + index}
                                    value={option.value}
                                    checked={checked && checked[option.label] === option.value}
                                    {...register(name)}
                                    aria-label={option.label}
                                    disabled={isDisabled}
                                />
                                <div className='duplicate-checkbox'></div>
                            </CustomCheckBox>

                            <CheckboxOptions
                                className={'ms-3 mb-0 me-3'}
                                htmlFor={name + index}
                                optionsStyle={optionsStyle}
                            >
                                {option.label}
                            </CheckboxOptions>
                        </div>
                    ))}
            </div>
            <ErrorText>{errors[name] && errors[name].message}</ErrorText>
        </FlexColumn>
    );
};

export default FormCheckBox;
