import { parseOptionsToSelect } from '../../CommonUtilities';
import { defaultGetResponse } from '../../interfaces/interfaces';
import {
    BenchmarkingState,
    BenchmarkingActionTypes,
    GET_ENERGY_STAR_PROPERTY_DETAILS,
    GET_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    GET_CONSUMPTION_DATA,
    GET_CONSUMPTION_DATA_SUCCESS,
    GET_CONSUMPTION_DATA_FAILURE,
    GET_WASTEMETERS,
    GET_WASTEMETERS_SUCCESS,
    GET_METERS,
    GET_METERS_SUCCESS,
    GET_PROPERTY_OPTIONS,
    GET_PROPERTY_OPTIONS_SUCCESS,
    GET_METER_OPTIONS,
    GET_METER_OPTIONS_SUCCESS,
    GET_WASTEMETER_OPTIONS,
    GET_WASTEMETER_OPTIONS_SUCCESS,
    GET_WASTE_CONSUMPTION_DATA,
    GET_WASTE_CONSUMPTION_DATA_SUCCESS,
    GET_DELIVERY_DATA,
    GET_DELIVERY_DATA_SUCCESS,
    CREATE_METER_SUCCESS,
    EDIT_METER_SUCCESS,
    DELETE_METER_SUCCESS,
    CREATE_WASTEMETER_SUCCESS,
    EDIT_WASTEMETER_SUCCESS,
    DELETE_WASTEMETER_SUCCESS,
    CREATE_CONSUMPTION_DATA_SUCCESS,
    EDIT_CONSUMPTION_DATA_SUCCESS,
    DELETE_CONSUMPTION_DATA_SUCCESS,
    CREATE_DELIVERY_DATA_SUCCESS,
    EDIT_DELIVERY_DATA_SUCCESS,
    DELETE_DELIVERY_DATA_SUCCESS,
    CREATE_WASTE_CONSUMPTION_DATA_SUCCESS,
    EDIT_WASTE_CONSUMPTION_DATA_SUCCESS,
    DELETE_WASTE_CONSUMPTION_DATA_SUCCESS,
    CREATE_METER,
    EDIT_METER,
    DELETE_METER,
    CREATE_WASTEMETER,
    EDIT_WASTEMETER,
    DELETE_WASTEMETER,
    CREATE_CONSUMPTION_DATA,
    EDIT_CONSUMPTION_DATA,
    EDIT_CONSUMPTION_DATA_FAILURE,
    DELETE_CONSUMPTION_DATA,
    CREATE_DELIVERY_DATA,
    EDIT_DELIVERY_DATA,
    DELETE_DELIVERY_DATA,
    CREATE_WASTE_CONSUMPTION_DATA,
    EDIT_WASTE_CONSUMPTION_DATA,
    DELETE_WASTE_CONSUMPTION_DATA,
    CREATE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    EDIT_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    DELETE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    CREATE_ENERGY_STAR_PROPERTY_DETAILS,
    EDIT_ENERGY_STAR_PROPERTY_DETAILS,
    GET_EGRID_OPTIONS,
    GET_EGRID_OPTIONS_SUCCESS,
    CREATE_METER_FAILURE,
    CREATE_WASTEMETER_FAILURE,
    GET_METERS_FAILURE,
    EDIT_WASTE_CONSUMPTION_DATA_FAILURE
} from '../actions/Benchmarking/types';

const initialState: BenchmarkingState = {
    energyStarPropertyDetails: defaultGetResponse,
    isFetchingEnergyStarPropertyDetails: false,
    energyConsumed: defaultGetResponse,
    isFetchingEnergyConsumed: false,
    consumptionData: defaultGetResponse,
    deliveryData: defaultGetResponse,
    wasteConsumptionData: defaultGetResponse,
    isFetchingWasteConsumptionData: false,
    isFetchingDeliveryData: false,
    isFetchingConsumptionData: false,
    energyProduced: defaultGetResponse,
    isFetchingEnergyProduced: false,
    water: defaultGetResponse,
    isFetchingWater: false,
    wasteMeters: defaultGetResponse,
    isFetchingWasteMeters: false,
    eGridOptions: defaultGetResponse,
    isFetchingEGridOptions: false,
    propertyOptions: {
        irrigatedAreaUnitsOptions: [],
        grossFloorAreaUnitsOptions: [],
        stateOptions: [],
        constructionStatusOptions: [],
        federalOwnerOptions: [],
        primaryFunctionsOptions: [],
        occupancyPercentageOptions: [],
        countryOptions: [],
        agencyOptions: [],
        eGridSubRegionOptions: []
    },
    isFetchingPropertyOptions: false,
    meterOptions: {
        dataEntryMethod: [],
        typeUnitMap: {}
    },
    isFetchingMeterOptions: false,
    wasteMeterOptions: {
        wasteMeterTypes: [],
        unitOfMeasureOptions: []
    },
    isFetchingWasteMeterOptions: false,
    isCreatingMeter: false,
    isEditingMeter: false,
    isDeletingMeter: false,
    isCreatingWastemeter: false,
    isEditingWastemeter: false,
    isDeletingWastemeter: false,
    isCreatingConsumptionData: false,
    isEditingConsumptionData: false,
    isDeletingConsumptionData: false,
    isCreatingDeliveryData: false,
    isEditingDeliveryData: false,
    isDeletingDeliveryData: false,
    isCreatingWasteConsumptionData: false,
    isEditingWasteConsumptionData: false,
    isDeletingWasteConsumptionData: false,
    iscreatingEnergyStarPropertyDetails: false,
    iseditingEnergyStarPropertyDetails: false,
    isdeletingEnergyStarPropertyDetails: false
};

const Benchmarking = (state = initialState, action: BenchmarkingActionTypes): BenchmarkingState => {
    switch (action.type) {
        case GET_ENERGY_STAR_PROPERTY_DETAILS: {
            return {
                ...state,
                isFetchingEnergyStarPropertyDetails: true
            };
        }
        case GET_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS: {
            return {
                ...state,
                energyStarPropertyDetails: action.data,
                isFetchingEnergyStarPropertyDetails: false
            };
        }
        case GET_METERS: {
            return {
                ...state,
                isFetchingEnergyConsumed: true
            };
        }
        case GET_METERS_SUCCESS: {
            return {
                ...state,
                energyConsumed: action.data,
                isFetchingEnergyConsumed: false
            };
        }

        case GET_METERS_FAILURE: {
            return {
                ...state,
                isFetchingEnergyConsumed: false
            };
        }
        case GET_CONSUMPTION_DATA: {
            return {
                ...state,
                isFetchingConsumptionData: true
            };
        }
        case GET_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                consumptionData: action.data,
                isFetchingConsumptionData: false
            };
        }
        case GET_CONSUMPTION_DATA_FAILURE: {
            return {
                ...state,
                isFetchingConsumptionData: false
            };
        }
        case GET_DELIVERY_DATA: {
            return {
                ...state,
                isFetchingDeliveryData: true
            };
        }
        case GET_DELIVERY_DATA_SUCCESS: {
            return {
                ...state,
                deliveryData: action.data,
                isFetchingDeliveryData: false
            };
        }
        case GET_WASTE_CONSUMPTION_DATA: {
            return {
                ...state,
                isFetchingWasteConsumptionData: true
            };
        }
        case GET_WASTE_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                wasteConsumptionData: action.data,
                isFetchingWasteConsumptionData: false
            };
        }
        case GET_WASTEMETERS: {
            return {
                ...state,
                isFetchingWasteMeters: true
            };
        }
        case GET_WASTEMETERS_SUCCESS: {
            return {
                ...state,
                wasteMeters: action.data,
                isFetchingWasteMeters: false
            };
        }
        case GET_EGRID_OPTIONS: {
            return {
                ...state,
                isFetchingEGridOptions: true
            };
        }
        case GET_EGRID_OPTIONS_SUCCESS: {
            return {
                ...state,
                eGridOptions: action.data,
                isFetchingEGridOptions: false
            };
        }
        case GET_PROPERTY_OPTIONS: {
            return {
                ...state,
                isFetchingPropertyOptions: true
            };
        }
        case GET_PROPERTY_OPTIONS_SUCCESS: {
            let propertyOptions = action.data;
            propertyOptions = {
                irrigatedAreaUnitsOptions: parseOptionsToSelect(
                    propertyOptions.irrigatedArea_units.choices
                ),
                grossFloorAreaUnitsOptions: parseOptionsToSelect(
                    propertyOptions.grossFloorArea_units.choices
                ),
                stateOptions: parseOptionsToSelect(propertyOptions.state.choices),
                constructionStatusOptions: parseOptionsToSelect(
                    propertyOptions.constructionStatus.choices
                ),
                federalOwnerOptions: parseOptionsToSelect(propertyOptions.federalOwner.choices),
                primaryFunctionsOptions: parseOptionsToSelect(
                    propertyOptions.primaryFunction.choices
                ),
                occupancyPercentageOptions: parseOptionsToSelect(
                    propertyOptions.occupancyPercentage.choices
                ),
                countryOptions: parseOptionsToSelect(propertyOptions.country.choices),
                agencyOptions: propertyOptions.agencyOptions,
                eGridSubRegionOptions: propertyOptions.eGridSubRegionOptions
            };
            return {
                ...state,
                propertyOptions,
                isFetchingPropertyOptions: false
            };
        }
        case GET_METER_OPTIONS: {
            return {
                ...state,
                isFetchingMeterOptions: true
            };
        }
        case GET_METER_OPTIONS_SUCCESS: {
            let meterOptions = action.data;
            meterOptions = {
                dataEntryMethod: parseOptionsToSelect(meterOptions.dataEntryMethod.choices),
                typeUnitMap: action.data?.typeUnitMap
            };
            return {
                ...state,
                meterOptions,
                isFetchingMeterOptions: false
            };
        }
        case GET_WASTEMETER_OPTIONS: {
            return {
                ...state,
                isFetchingWasteMeterOptions: true
            };
        }
        case GET_WASTEMETER_OPTIONS_SUCCESS: {
            let wasteMeterOptions = action.data;
            wasteMeterOptions = {
                wasteMeterTypes: parseOptionsToSelect(wasteMeterOptions.type.choices),
                unitOfMeasureOptions: parseOptionsToSelect(wasteMeterOptions.unitOfMeasure.choices)
            };
            return {
                ...state,
                wasteMeterOptions,
                isFetchingWasteMeterOptions: false
            };
        }
        case CREATE_METER: {
            return {
                ...state,
                isCreatingMeter: true
            };
        }
        case CREATE_METER_SUCCESS: {
            return {
                ...state,
                isCreatingMeter: false
            };
        }
        case CREATE_METER_FAILURE: {
            return {
                ...state,
                isCreatingMeter: false
            };
        }
        case EDIT_METER: {
            return {
                ...state,
                isEditingMeter: true
            };
        }
        case EDIT_METER_SUCCESS: {
            return {
                ...state,
                isEditingMeter: false
            };
        }
        case DELETE_METER: {
            return {
                ...state,
                isEditingMeter: true
            };
        }
        case DELETE_METER_SUCCESS: {
            return {
                ...state,
                isDeletingMeter: false
            };
        }
        case CREATE_WASTEMETER: {
            return {
                ...state,
                isCreatingWastemeter: true
            };
        }
        case CREATE_WASTEMETER_SUCCESS: {
            return {
                ...state,
                isCreatingWastemeter: false
            };
        }
        case CREATE_WASTEMETER_FAILURE: {
            return {
                ...state,
                isCreatingWastemeter: false
            };
        }
        case EDIT_WASTEMETER: {
            return {
                ...state,
                isEditingWastemeter: true
            };
        }
        case EDIT_WASTEMETER_SUCCESS: {
            return {
                ...state,
                isEditingWastemeter: false
            };
        }
        case DELETE_WASTEMETER: {
            return {
                ...state,
                isDeletingWastemeter: true
            };
        }
        case DELETE_WASTEMETER_SUCCESS: {
            return {
                ...state,
                isDeletingWastemeter: false
            };
        }
        case CREATE_CONSUMPTION_DATA: {
            return {
                ...state,
                isCreatingConsumptionData: true
            };
        }
        case CREATE_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                isCreatingConsumptionData: false
            };
        }
        case EDIT_CONSUMPTION_DATA: {
            return {
                ...state,
                isEditingConsumptionData: true
            };
        }
        case EDIT_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                isEditingConsumptionData: false
            };
        }
        case EDIT_CONSUMPTION_DATA_FAILURE: {
            return {
                ...state,
                isEditingConsumptionData: false
            };
        }
        case DELETE_CONSUMPTION_DATA: {
            return {
                ...state,
                isDeletingWasteConsumptionData: true
            };
        }
        case DELETE_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                isDeletingConsumptionData: false
            };
        }
        case CREATE_DELIVERY_DATA: {
            return {
                ...state,
                isCreatingDeliveryData: true
            };
        }
        case CREATE_DELIVERY_DATA_SUCCESS: {
            return {
                ...state,
                isCreatingDeliveryData: false
            };
        }
        case EDIT_DELIVERY_DATA: {
            return {
                ...state,
                isEditingDeliveryData: true
            };
        }
        case EDIT_DELIVERY_DATA_SUCCESS: {
            return {
                ...state,
                isEditingDeliveryData: false
            };
        }
        case DELETE_DELIVERY_DATA: {
            return {
                ...state,
                isDeletingDeliveryData: true
            };
        }
        case DELETE_DELIVERY_DATA_SUCCESS: {
            return {
                ...state,
                isDeletingDeliveryData: false
            };
        }
        case CREATE_WASTE_CONSUMPTION_DATA: {
            return {
                ...state,
                isCreatingWasteConsumptionData: true
            };
        }
        case CREATE_WASTE_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                isCreatingWasteConsumptionData: false
            };
        }
        case EDIT_WASTE_CONSUMPTION_DATA: {
            return {
                ...state,
                isEditingWasteConsumptionData: true
            };
        }
        case EDIT_WASTE_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                isEditingWasteConsumptionData: false
            };
        }
        case EDIT_WASTE_CONSUMPTION_DATA_FAILURE: {
            return {
                ...state,
                isEditingWasteConsumptionData: false
            };
        }
        case DELETE_WASTE_CONSUMPTION_DATA: {
            return {
                ...state,
                isDeletingWasteConsumptionData: true
            };
        }
        case DELETE_WASTE_CONSUMPTION_DATA_SUCCESS: {
            return {
                ...state,
                isDeletingWasteConsumptionData: false
            };
        }
        case CREATE_ENERGY_STAR_PROPERTY_DETAILS: {
            return {
                ...state,
                iscreatingEnergyStarPropertyDetails: true
            };
        }
        case EDIT_ENERGY_STAR_PROPERTY_DETAILS: {
            return {
                ...state,
                iseditingEnergyStarPropertyDetails: true
            };
        }
        case CREATE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS: {
            return {
                ...state,
                iscreatingEnergyStarPropertyDetails: false
            };
        }
        case EDIT_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS: {
            return {
                ...state,
                iseditingEnergyStarPropertyDetails: false
            };
        }
        case DELETE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS: {
            return {
                ...state,
                isdeletingEnergyStarPropertyDetails: false
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default Benchmarking;
