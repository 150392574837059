export const CREATE_CERTIFICATION_TYPE = 'CREATE_CERTIFICATION_TYPE';
export const CREATE_CERTIFICATION_TYPE_SUCCESS = 'CREATE_CERTIFICATION_TYPE_SUCCESS';
export const CREATE_CERTIFICATION_LEVEL_DATA = 'CREATE_CERTIFICATION_LEVEL_DATA';
export const CREATE_CERTIFICATION_LEVEL_DATA_SUCCESS = 'CREATE_CERTIFICATION_LEVEL_DATA_SUCCESS';
export const EDIT_CERTIFICATION_LEVEL_DATA = 'EDIT_CERTIFICATION_LEVEL_DATA';
export const EDIT_CERTIFICATION_LEVEL_DATA_SUCCESS = 'EDIT_CERTIFICATION_LEVEL_DATA_SUCCESS';
export const GET_CERTIFICATION_LEVEL_LIST = 'GET_CERTIFICATION_LEVEL_LIST';
export const GET_CERTIFICATION_LEVEL_LIST_SUCCESS = 'GET_CERTIFICATION_LEVEL_LIST_SUCCESS';
export const GET_CERTIFICATION_LEVEL_OPTIONS = 'GET_CERTIFICATION_LEVEL_OPTIONS';
export const GET_CERTIFICATION_LEVEL_OPTIONS_SUCCESS = 'GET_CERTIFICATION_LEVEL_OPTIONS_SUCCESS';
export const GET_CERTIFICATION_TYPES_LIST = 'GET_CERTIFICATION_TYPES_LIST';
export const GET_CERTIFICATION_TYPES_LIST_SUCCESS = 'GET_CERTIFICATION_TYPES_LIST_SUCCESS';
export const GET_CERTIFICATION_BUILDINGS_LIST = 'GET_CERTIFICATION_BUILDINGS_LIST';
export const GET_CERTIFICATION_BUILDINGS_LIST_SUCCESS = 'GET_CERTIFICATION_BUILDINGS_LIST_SUCCESS';

export interface createCertificationTypeAction {
    type: typeof CREATE_CERTIFICATION_TYPE;
    params: any;
}
export interface createCertificationTypeSuccessAction {
    type: typeof CREATE_CERTIFICATION_TYPE_SUCCESS;
}

export interface createCertificationLevelDataAction {
    type: typeof CREATE_CERTIFICATION_LEVEL_DATA;
    params: any;
    id?: number;
}
export interface createCertificationLevelDataSuccessAction {
    type: typeof CREATE_CERTIFICATION_LEVEL_DATA_SUCCESS;
}

export interface editCertificationLevelDataAction {
    type: typeof EDIT_CERTIFICATION_LEVEL_DATA;
    params: any;
    certification_id: number;
    id?: number;
}
export interface editCertificationLevelDataSuccessAction {
    type: typeof EDIT_CERTIFICATION_LEVEL_DATA_SUCCESS;
}

export interface getCertificationLevelListAction {
    type: typeof GET_CERTIFICATION_LEVEL_LIST;
    params: any;
    certification_id?: number;
}

export interface getCertificationLevelListSuccessAction {
    type: typeof GET_CERTIFICATION_LEVEL_LIST_SUCCESS;
    payload: any;
}

export interface getCertificationLevelOptionsAction {
    type: typeof GET_CERTIFICATION_LEVEL_OPTIONS;
}

export interface getCertificationLevelOptionsSuccessAction {
    type: typeof GET_CERTIFICATION_LEVEL_OPTIONS_SUCCESS;
    payload: any;
}

export interface getCertificationTypesListAction {
    type: typeof GET_CERTIFICATION_TYPES_LIST;
    params: any;
    shouldAppend?: boolean;
}

export interface getCertificationTypesListSuccessAction {
    type: typeof GET_CERTIFICATION_TYPES_LIST_SUCCESS;
    payload: any;
    shouldAppend?: any;
}

export interface getCertificationBuildingsListAction {
    type: typeof GET_CERTIFICATION_BUILDINGS_LIST;
    name: string;
    params: any;
    shouldAppend?: boolean;
}

export interface getCertificationBuildingsListActionSuccess {
    type: typeof GET_CERTIFICATION_BUILDINGS_LIST_SUCCESS;
    payload: any;
    shouldAppend?: boolean;
}

export type certificationActionTypes =
    | createCertificationTypeAction
    | createCertificationTypeSuccessAction
    | getCertificationTypesListAction
    | getCertificationTypesListSuccessAction
    | createCertificationLevelDataAction
    | createCertificationLevelDataSuccessAction
    | editCertificationLevelDataAction
    | editCertificationLevelDataSuccessAction
    | getCertificationLevelOptionsAction
    | getCertificationLevelOptionsSuccessAction
    | getCertificationLevelListAction
    | getCertificationLevelListSuccessAction
    | getCertificationBuildingsListAction
    | getCertificationBuildingsListActionSuccess;

export interface certificationState {
    isFetchingCertificationTypesList: boolean;
    isFetchingCertificationBuildingsList: boolean;
    certificationTypesListData: any;
    certificationLevelListData: any;
    certificationLevelOptionsData: any;
    certificationBuildingsListData: any;
}
