import {
    USER_PROFILE_UPDATE,
    USER_PROFILE_UPDATE_SUCCESS,
    UserProfileUpdateActionTypes,
    userProfileParams,
    USER_PROFILE_UPDATE_FAILURE
} from './types';

export const getUpdatedUserProfileData = (
    params: userProfileParams,
    id: number,
    successMessage?: string,
    history?: any
): UserProfileUpdateActionTypes => ({
    type: USER_PROFILE_UPDATE,
    params,
    id,
    successMessage,
    history
});

export const getUpdatedUserProfileDataReceived = (): UserProfileUpdateActionTypes => ({
    type: USER_PROFILE_UPDATE_SUCCESS
});

export const getUpdatedUserProfileDataFailed = (): UserProfileUpdateActionTypes => ({
    type: USER_PROFILE_UPDATE_FAILURE
});
