import { put, takeLatest } from 'redux-saga/effects';
import { api_prefix } from '../../config';
import {
    getApiData,
    optionsApiData,
    patchApiData,
    postApiData,
    showPopUp
} from '../../CommonUtilities';
import {
    addNewBuildingAction,
    ADD_NEW_BUILDING,
    checkBuildingAction,
    GET_BUILDINGS_DATA,
    GET_BUILDINGS_OPTIONS,
    GET_BUILDING_DETAILS,
    getBuildingDetailsAction,
    GET_USERS,
    updateBuildingDataAction,
    UPDATE_BUILDING,
    getUsersAction,
    getBuildingsDataAction,
    assignCertifiedLevelAction,
    removeCertifiedLevelAction,
    ADD_BUILDING_ACCESS,
    addBuildingAccessAction,
    BuildingsActionTypes,
    CHECK_BUILDING,
    getBuildingsCompanyListDataAction,
    GET_BUILDING_TYPE_LIST,
    GET_BUILDINGS_COMPANY_LIST,
    sendBuildingsAccessRequestAction,
    SEND_BUILDINGS_ACCESS_REQUEST,
    ASSIGN_CERTIFIED_LEVEL,
    REMOVE_CERTIFIED_LEVEL
} from '../actions/Buildings/types';
import {
    checkBuildingSuccess,
    addNewBuildingFailure,
    addNewBuildingSuccess,
    getBuildingTypeListDataRecieved,
    getBuildingsCompanyListDataRecieved,
    getBuildingsOptionsSuccess,
    addBuildingAccessSuccess,
    getBuildingDetailsReceived,
    getBuildingsDataRecieved,
    getUsersReceived,
    sendBuildingsAccessRequestSuccess,
    updateBuildingDataSuccess,
    assignCertifiedLevelSuccess,
    removeCertifiedLevelSuccess
} from '../actions/Buildings';
import {
    getCertificationBuildingsList,
    setIsEdit,
    getUserData,
    setShowAppLoader
} from '../actions';
import i18n from 'i18next';
import { defaultGetResponse } from '../../interfaces/interfaces';
import { constantStrings } from '../../constantStrings';

function* getBuildingCompanyListData(action: getBuildingsCompanyListDataAction): any {
    const json = yield getApiData(`${api_prefix}/buildings/company_list/`, action);

    if (json) {
        yield put(getBuildingsCompanyListDataRecieved(json));
    }
}

export function* getBuildingsCompanyListDataSaga() {
    yield takeLatest(GET_BUILDINGS_COMPANY_LIST, getBuildingCompanyListData);
}

function* getBuildingTypeListData(action: BuildingsActionTypes): any {
    const json = yield getApiData(`${api_prefix}/assets/`, action);
    if (json) {
        yield put(getBuildingTypeListDataRecieved(json));
    }
}

export function* getBuildingTypeListDataSaga() {
    yield takeLatest(GET_BUILDING_TYPE_LIST, getBuildingTypeListData);
}

function* checkBuilding(action: checkBuildingAction): any {
    const json = yield getApiData(`${api_prefix}/request-access/check_building/`, action);
    if (json) {
        yield put(checkBuildingSuccess(json));
        if (json.length > 0) {
            action.history.push('/SuggestedBuildings', action.buildingData);
        } else {
            if (action.isNewBuilding)
                action.history.push('/BuildingCreation', { data: action.buildingData });
            else
                showPopUp({
                    titleText: i18n.t(constantStrings.noBuildingDataFound),
                    type: 'error',
                    showConfirmButton: true,
                    noCustomHeader: true
                });
        }
    }
}

export function* checkBuildingSaga() {
    yield takeLatest(CHECK_BUILDING, checkBuilding);
}

function* addNewBuildingSaga(action: addNewBuildingAction): any {
    const json = yield postApiData(
        `${api_prefix}/buildings/create_new_building/`,
        action,
        i18n.t(constantStrings.buildingAdded),
        true
    );
    if (!json.data) {
        yield put(addNewBuildingSuccess());
        action.history.push('/BuildingDetails', {
            id: json.id,
            is_disabled: json.is_disabled,
            tab: 'Documents'
        });
    } else {
        yield put(addNewBuildingFailure());
    }
    yield put(setShowAppLoader(false));
}

export function* newBuildingSaga() {
    yield takeLatest(ADD_NEW_BUILDING, addNewBuildingSaga);
}

function* getBuildingsOptionsSaga(action: BuildingsActionTypes): any {
    const json = yield optionsApiData(`${api_prefix}/buildings/`, action);
    if (json && json.name && json.actions && json.actions.POST) {
        yield put(getBuildingsOptionsSuccess(json.actions.POST));
    }
}

export function* getBuildingsOptSaga() {
    yield takeLatest(GET_BUILDINGS_OPTIONS, getBuildingsOptionsSaga);
}

function* sendBuildingsAccessRequest(action: sendBuildingsAccessRequestAction): any {
    const json = yield postApiData(
        `${api_prefix}/request-access/request_access/`,
        action,
        '',
        false,
        action?.setShowLoader
    );
    if (!json.data) {
        yield put(sendBuildingsAccessRequestSuccess());
        action.history && action.history.push('/BuildingList');
        showPopUp({
            titleText: i18n.t(`${constantStrings.congratulations}!!`),
            text: `<p> ${i18n.t(constantStrings.requestShared)}</p> <p>${i18n.t(
                constantStrings.furtherInformation
            )}</p>`,
            type: 'success',
            showConfirmButton: true,
            showCloseButton: false,
            noCustomHeader: true,
            isBtnDanger: false,
            successText: '',
            showCancelButton: false
        });
    }
}

export function* sendBuildingsAccessRequestSaga() {
    yield takeLatest(SEND_BUILDINGS_ACCESS_REQUEST, sendBuildingsAccessRequest);
}

function* getBuildingDetails(action: getBuildingDetailsAction): any {
    const json = yield getApiData(`${api_prefix}/buildings/${action.id}/`, action);
    if (json) {
        yield put(getBuildingDetailsReceived(json));
    } else {
        yield put(getBuildingDetailsReceived(defaultGetResponse));
    }
}

export function* getBuildingDetailsSaga() {
    yield takeLatest(GET_BUILDING_DETAILS, getBuildingDetails);
}

function* getBuildingsDataSaga(action: getBuildingsDataAction): any {
    const json = yield getApiData(`${api_prefix}/buildings/`, action);
    if (json && json.results) {
        yield put(getBuildingsDataRecieved(json, action.appendData));
    }
}

export function* getBuildingSaga() {
    yield takeLatest(GET_BUILDINGS_DATA, getBuildingsDataSaga);
}

function* getUsers(action: getUsersAction): any {
    const json = yield getApiData(`${api_prefix}/buildings/${action.id}/accessible_users/`, action);
    if (json) {
        yield put(getUsersReceived(json));
    }
}

export function* getUsersSaga() {
    yield takeLatest(GET_USERS, getUsers);
}

function* updateBuildingData(action: updateBuildingDataAction): any {
    const json = yield patchApiData(
        `${api_prefix}/buildings/${action.id}/`,
        action,
        i18n.t(constantStrings.buildingUpdated),
        true
    );
    if (json.id) {
        yield put(updateBuildingDataSuccess());
        action.history &&
            action.history.push('/BuildingDetails', {
                id: json.id,
                is_disabled: json.is_disabled,
                tab: 'Documents'
            });
        yield put(setIsEdit(false));
    }
    yield put(setShowAppLoader(false));
}

export function* updateBuildingDataSaga() {
    yield takeLatest(UPDATE_BUILDING, updateBuildingData);
}

function* assignCertifiedLevel(action: assignCertifiedLevelAction): any {
    const json = yield postApiData(
        `${api_prefix}/certification-portfolios/add_certified/`,
        action,
        i18n.t(constantStrings.certifiedLevelAssigned),
        true
    );
    let params: any = {
        ordering: 'id'
    };
    if (!json.data) {
        yield put(assignCertifiedLevelSuccess());
    }
    yield put(getCertificationBuildingsList(action.name, params));
}

export function* assignCertifiedLevelSaga() {
    yield takeLatest(ASSIGN_CERTIFIED_LEVEL, assignCertifiedLevel);
}

function* removeCertifiedLevel(action: removeCertifiedLevelAction): any {
    const json = yield postApiData(
        `${api_prefix}/certification-portfolios/remove_certified/`,
        action,
        i18n.t(constantStrings.certifiedLevelRemoved),
        true
    );
    let params: any = {
        ordering: 'id'
    };
    if (!json.data) {
        yield put(removeCertifiedLevelSuccess());
    }
    yield put(getCertificationBuildingsList(action.name, params));
}

export function* removeCertifiedLevelSaga() {
    yield takeLatest(REMOVE_CERTIFIED_LEVEL, removeCertifiedLevel);
}

function* addBuildingAccess(action: addBuildingAccessAction): any {
    const json = yield postApiData(
        `${api_prefix}/users/add_building_access/`,
        action,
        i18n.t(constantStrings.buildingAccessAdded),
        true
    );
    let params: any = {
        ordering: '-id'
    };
    if (!json.data) {
        yield put(addBuildingAccessSuccess());
    }
    if (action.id) {
        yield put(getUserData(params, false, action.id));
    }
}

export function* addBuildingAccessSaga() {
    yield takeLatest(ADD_BUILDING_ACCESS, addBuildingAccess);
}
