import {
    USER_PROFILE_UPDATE_SUCCESS,
    UserProfileUpdateActionTypes,
    UserProfileUpdateState,
    USER_PROFILE_UPDATE_FAILURE,
    USER_PROFILE_UPDATE
} from '../actions/UserProfileUpdate/types';

const initialState: UserProfileUpdateState = {
    isLoading: false
};

const UserProfileUpdate = (
    state = initialState,
    action: UserProfileUpdateActionTypes
): UserProfileUpdateState => {
    switch (action.type) {
        case USER_PROFILE_UPDATE: {
            return {
                ...state,
                isLoading: true
            };
        }

        case USER_PROFILE_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case USER_PROFILE_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default UserProfileUpdate;
