import { buildingPropertiesTabs } from '../../components/Properties/BuildingDetails/constants';
import {
    SET_ISACCESS_REQUEST,
    BuildingsActionTypes,
    BuildingsState,
    GET_BUILDINGS_COMPANY_LIST,
    GET_BUILDINGS_COMPANY_LIST_SUCCESS,
    GET_BUILDING_TYPE_LIST,
    GET_BUILDING_TYPE_LIST_SUCCESS,
    CHECK_BUILDING,
    CHECK_BUILDING_SUCCESS,
    ADD_NEW_BUILDING,
    ADD_NEW_BUILDING_SUCCESS,
    ADD_NEW_BUILDING_FAILURE,
    GET_BUILDINGS_OPTIONS,
    GET_BUILDINGS_OPTIONS_SUCCESS,
    SEND_BUILDINGS_ACCESS_REQUEST,
    SEND_BUILDINGS_ACCESS_REQUEST_SUCCESS,
    GET_BUILDING_DETAILS,
    GET_BUILDING_DETAILS_RECEIVED,
    GET_BUILDINGS_DATA,
    GET_BUILDINGS_DATA_SUCCESS,
    GET_USERS,
    GET_USERS_RECEIVED,
    UPDATE_BUILDING,
    UPDATE_BUILDING_SUCCESS,
    ADD_BUILDING_ACCESS,
    ADD_BUILDING_ACCESS_SUCCESS,
    ASSIGN_CERTIFIED_LEVEL,
    ASSIGN_CERTIFIED_LEVEL_SUCCESS,
    REMOVE_CERTIFIED_LEVEL,
    REMOVE_CERTIFIED_LEVEL_SUCCESS,
    MAP_BOX_OBJECT,
    SET_BUILDING_PROPERTY_TAB
} from '../actions/Buildings/types';

const initialState: BuildingsState = {
    isAccessRequest: false,
    isFetchingCompanyList: false,
    buildingCompanyListData: null,
    isFetchingBuildingTypeList: false,
    buildingTypeListData: null,
    suggestedBuildingsData: null,
    isNewBuildingAdded: false,
    buildingOptionsData: null,
    isBuildingsAccessRequestSent: false,
    buildingDetails: null,
    isFetchingBuildingList: false,
    buildingsData: null,
    usersData: null,
    isAddingBuildingAccess: false,
    mapBoxObjectData: null,
    markerDataObject: null,
    isDetailsFetched: false,
    selectedBuildingPropertyTab:
        localStorage.getItem('selectedBuildingPropertyTab') || buildingPropertiesTabs.documents
};

const Buildings = (state = initialState, action: BuildingsActionTypes): BuildingsState => {
    switch (action.type) {
        case SET_ISACCESS_REQUEST: {
            return {
                ...state,
                isAccessRequest: action.isAccessRequest
            };
        }
        case GET_BUILDINGS_COMPANY_LIST: {
            return {
                ...state
            };
        }
        case GET_BUILDINGS_COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                isFetchingCompanyList: true,
                buildingCompanyListData: action.payload
            };
        }
        case GET_BUILDING_TYPE_LIST: {
            return {
                ...state
            };
        }
        case GET_BUILDING_TYPE_LIST_SUCCESS: {
            return {
                ...state,
                isFetchingBuildingTypeList: true,
                buildingTypeListData: action.payload
            };
        }
        case CHECK_BUILDING: {
            return {
                ...state
            };
        }
        case CHECK_BUILDING_SUCCESS: {
            return {
                ...state,
                suggestedBuildingsData: action.params
            };
        }

        case SET_BUILDING_PROPERTY_TAB: {
            return {
                ...state,
                selectedBuildingPropertyTab: action.tab
            };
        }

        case ADD_NEW_BUILDING: {
            return {
                ...state
            };
        }
        case ADD_NEW_BUILDING_SUCCESS: {
            return {
                ...state,
                isNewBuildingAdded: false
            };
        }
        case ADD_NEW_BUILDING_FAILURE: {
            return {
                ...state,
                isNewBuildingAdded: false
            };
        }

        case GET_BUILDINGS_OPTIONS: {
            return {
                ...state
            };
        }

        case GET_BUILDINGS_OPTIONS_SUCCESS: {
            return {
                ...state,
                buildingOptionsData: action.payload
            };
        }

        case SEND_BUILDINGS_ACCESS_REQUEST: {
            return {
                ...state
            };
        }

        case SEND_BUILDINGS_ACCESS_REQUEST_SUCCESS: {
            return {
                ...state,
                isBuildingsAccessRequestSent: true
            };
        }

        case GET_BUILDING_DETAILS: {
            return {
                ...state,
                isDetailsFetched: true
            };
        }

        case GET_BUILDING_DETAILS_RECEIVED: {
            return {
                ...state,
                buildingDetails: action.payload,
                isDetailsFetched: false
            };
        }

        case GET_BUILDINGS_DATA: {
            return {
                ...state,
                isFetchingBuildingList: true
            };
        }

        case GET_BUILDINGS_DATA_SUCCESS: {
            if (action.appendData && state?.buildingsData?.results?.length) {
                action.payload.results = [
                    ...state.buildingsData?.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                isFetchingBuildingList: false,
                buildingsData: action.payload
            };
        }

        case GET_USERS: {
            return {
                ...state
            };
        }

        case GET_USERS_RECEIVED: {
            return {
                ...state,
                usersData: action.payload
            };
        }

        case UPDATE_BUILDING: {
            return {
                ...state
            };
        }

        case UPDATE_BUILDING_SUCCESS: {
            return {
                ...state
            };
        }

        case ASSIGN_CERTIFIED_LEVEL: {
            return {
                ...state
            };
        }

        case ASSIGN_CERTIFIED_LEVEL_SUCCESS: {
            return {
                ...state
            };
        }

        case REMOVE_CERTIFIED_LEVEL: {
            return {
                ...state
            };
        }

        case REMOVE_CERTIFIED_LEVEL_SUCCESS: {
            return {
                ...state
            };
        }

        case ADD_BUILDING_ACCESS: {
            return {
                ...state,
                isAddingBuildingAccess: true
            };
        }

        case ADD_BUILDING_ACCESS_SUCCESS: {
            return {
                ...state,
                isAddingBuildingAccess: false
            };
        }

        case MAP_BOX_OBJECT: {
            return {
                ...state,
                mapBoxObjectData: action.data,
                markerDataObject: action.markerData
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default Buildings;
