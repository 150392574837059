import { put, takeLatest } from 'redux-saga/effects';

import { fetchCurrentUserAction, FETCH_CURRENT_USER } from '../actions/CurrentUser/types';

import { getCurrentUserDataReceived } from '../actions/CurrentUser';

import { api_prefix } from '../../config';
import { getApiData } from '../../CommonUtilities';

function* fetchCurrentUser(action: fetchCurrentUserAction): any {
    const json = yield getApiData(`${api_prefix}/users/user-details/`, action);
    if (!json.data) {
        yield put(getCurrentUserDataReceived(json));
    }
}

export function* fetchCurrentUserSaga() {
    yield takeLatest(FETCH_CURRENT_USER, fetchCurrentUser);
}
