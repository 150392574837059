import {
    GET_ENERGY_STAR_PROPERTY_DETAILS,
    EDIT_ENERGY_STAR_PROPERTY_DETAILS,
    CREATE_WASTEMETER,
    GET_WASTEMETERS,
    EDIT_WASTEMETER,
    DELETE_WASTEMETER,
    CREATE_CONSUMPTION_DATA,
    GET_CONSUMPTION_DATA,
    EDIT_CONSUMPTION_DATA,
    DELETE_CONSUMPTION_DATA,
    CREATE_DELIVERY_DATA,
    GET_DELIVERY_DATA,
    EDIT_DELIVERY_DATA,
    DELETE_DELIVERY_DATA,
    CREATE_WASTE_CONSUMPTION_DATA,
    GET_WASTE_CONSUMPTION_DATA,
    EDIT_WASTE_CONSUMPTION_DATA,
    DELETE_WASTE_CONSUMPTION_DATA,
    BenchmarkingActionTypes,
    GET_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    CREATE_ENERGY_STAR_PROPERTY_DETAILS,
    GET_METERS_SUCCESS,
    GET_METERS,
    GET_CONSUMPTION_DATA_SUCCESS,
    GET_CONSUMPTION_DATA_FAILURE,
    GET_WASTEMETERS_SUCCESS,
    CREATE_METER,
    EDIT_METER,
    DELETE_METER,
    GET_PROPERTY_OPTIONS,
    GET_PROPERTY_OPTIONS_SUCCESS,
    GET_METER_OPTIONS,
    GET_METER_OPTIONS_SUCCESS,
    GET_WASTEMETER_OPTIONS,
    GET_WASTEMETER_OPTIONS_SUCCESS,
    GET_WASTE_CONSUMPTION_DATA_SUCCESS,
    GET_DELIVERY_DATA_SUCCESS,
    CREATE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    EDIT_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    DELETE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    CREATE_METER_SUCCESS,
    EDIT_METER_SUCCESS,
    DELETE_METER_SUCCESS,
    CREATE_WASTEMETER_SUCCESS,
    EDIT_WASTEMETER_SUCCESS,
    DELETE_WASTEMETER_SUCCESS,
    CREATE_CONSUMPTION_DATA_SUCCESS,
    EDIT_CONSUMPTION_DATA_SUCCESS,
    EDIT_CONSUMPTION_DATA_FAILURE,
    DELETE_CONSUMPTION_DATA_SUCCESS,
    CREATE_DELIVERY_DATA_SUCCESS,
    EDIT_DELIVERY_DATA_SUCCESS,
    DELETE_DELIVERY_DATA_SUCCESS,
    CREATE_WASTE_CONSUMPTION_DATA_SUCCESS,
    EDIT_WASTE_CONSUMPTION_DATA_SUCCESS,
    DELETE_WASTE_CONSUMPTION_DATA_SUCCESS,
    GET_EGRID_OPTIONS,
    GET_EGRID_OPTIONS_SUCCESS,
    CREATE_METER_FAILURE,
    CREATE_WASTEMETER_FAILURE,
    GET_METERS_FAILURE,
    EDIT_WASTE_CONSUMPTION_DATA_FAILURE,
    GET_WASTE_CONSUMPTION_DATA_FAILURE,
    getMeterParamsType
} from './types';

export const getEnergyStarPropertyDetails = (id: any): BenchmarkingActionTypes => ({
    type: GET_ENERGY_STAR_PROPERTY_DETAILS,
    id
});

export const createEnergyStarPropertyDetails = (params: any): BenchmarkingActionTypes => ({
    type: CREATE_ENERGY_STAR_PROPERTY_DETAILS,
    params
});

export const energyStarPropertyDetailsRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS,
    data
});

export const editEnergyStarPropertyDetails = (
    id: number,
    params: any
): BenchmarkingActionTypes => ({
    type: EDIT_ENERGY_STAR_PROPERTY_DETAILS,
    id,
    params
});

export const getMeters = (id: number, meterType: string): BenchmarkingActionTypes => ({
    type: GET_METERS,
    id,
    meterType
});

export const metersRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_METERS_SUCCESS,
    data
});

export const getMeterFailure = (): BenchmarkingActionTypes => ({
    type: GET_METERS_FAILURE
});

export const createMeter = (
    params: any,
    getMeterParams: getMeterParamsType
): BenchmarkingActionTypes => ({
    type: CREATE_METER,
    params,
    getMeterParams
});

export const editMeter = (id: number, payload: any): BenchmarkingActionTypes => ({
    type: EDIT_METER,
    payload,
    id
});

export const deleteMeter = (id: number): BenchmarkingActionTypes => ({
    type: DELETE_METER,
    id
});

export const createWastemeter = (
    params: any,
    energyStarPropertyID: number
): BenchmarkingActionTypes => ({
    type: CREATE_WASTEMETER,
    params,
    energyStarPropertyID
});

export const getWastemeters = (id: any): BenchmarkingActionTypes => ({
    type: GET_WASTEMETERS,
    id
});

export const wasteMeterRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_WASTEMETERS_SUCCESS,
    data
});

export const editWastemeter = (id: number, params: any): BenchmarkingActionTypes => ({
    type: EDIT_WASTEMETER,
    id,
    params
});

export const deleteWastemeter = (id: any): BenchmarkingActionTypes => ({
    type: DELETE_WASTEMETER,
    id
});

export const createConsumptionData = (
    params: any,
    meters: Array<number>
): BenchmarkingActionTypes => ({
    type: CREATE_CONSUMPTION_DATA,
    params,
    meters
});

export const getConsumptionData = (idArray: Array<number>): BenchmarkingActionTypes => ({
    type: GET_CONSUMPTION_DATA,
    idArray
});

export const consumptionDataRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_CONSUMPTION_DATA_SUCCESS,
    data
});

export const consumptionDataFailure = (): BenchmarkingActionTypes => ({
    type: GET_CONSUMPTION_DATA_FAILURE
});

export const editConsumptionData = (
    id: number,
    params: any,
    meters: Array<number>
): BenchmarkingActionTypes => ({
    type: EDIT_CONSUMPTION_DATA,
    id,
    params,
    meters
});

export const deleteConsumptionData = (id: any): BenchmarkingActionTypes => ({
    type: DELETE_CONSUMPTION_DATA,
    id
});

export const createDeliveryData = (
    params: any,
    meters: Array<number>
): BenchmarkingActionTypes => ({
    type: CREATE_DELIVERY_DATA,
    params,
    meters
});

export const getDeliveryData = (idArray: Array<number>): BenchmarkingActionTypes => ({
    type: GET_DELIVERY_DATA,
    idArray
});

export const deliveryDataRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_DELIVERY_DATA_SUCCESS,
    data
});

export const editDeliveryData = (
    id: number,
    params: any,
    meters: Array<number>
): BenchmarkingActionTypes => ({
    type: EDIT_DELIVERY_DATA,
    id,
    params,
    meters
});

export const deleteDeliveryData = (id: any): BenchmarkingActionTypes => ({
    type: DELETE_DELIVERY_DATA,
    id
});

export const createWasteConsumptionData = (
    params: any,
    wasteMeters: Array<number>
): BenchmarkingActionTypes => ({
    type: CREATE_WASTE_CONSUMPTION_DATA,
    params,
    wasteMeters
});

export const getWasteConsumptionData = (idArray: Array<number>): BenchmarkingActionTypes => ({
    type: GET_WASTE_CONSUMPTION_DATA,
    idArray
});

export const wasteConsumptionDataRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_WASTE_CONSUMPTION_DATA_SUCCESS,
    data
});

export const wasteConsumptionDataFailure = (): BenchmarkingActionTypes => ({
    type: GET_WASTE_CONSUMPTION_DATA_FAILURE
});

export const editWasteConsumptionData = (
    id: number,
    params: any,
    wasteMeters: Array<number>
): BenchmarkingActionTypes => ({
    type: EDIT_WASTE_CONSUMPTION_DATA,
    id,
    params,
    wasteMeters
});

export const deleteWasteConsumptionData = (id: any): BenchmarkingActionTypes => ({
    type: DELETE_WASTE_CONSUMPTION_DATA,
    id
});

export const getEGridOptions = (): BenchmarkingActionTypes => ({
    type: GET_EGRID_OPTIONS
});

export const eGridOptionsRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_EGRID_OPTIONS_SUCCESS,
    data
});

export const getPropertyOptions = (): BenchmarkingActionTypes => ({
    type: GET_PROPERTY_OPTIONS
});

export const propertyOptionsRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_PROPERTY_OPTIONS_SUCCESS,
    data
});

export const getMeterOptions = (): BenchmarkingActionTypes => ({
    type: GET_METER_OPTIONS
});

export const meterOptionsRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_METER_OPTIONS_SUCCESS,
    data
});

export const getWasteMeterOptions = (): BenchmarkingActionTypes => ({
    type: GET_WASTEMETER_OPTIONS
});

export const wasteMeterOptionsRecieved = (data: any): BenchmarkingActionTypes => ({
    type: GET_WASTEMETER_OPTIONS_SUCCESS,
    data
});

export const createMeterSuccess = (): BenchmarkingActionTypes => ({
    type: CREATE_METER_SUCCESS
});

export const createMeterFailure = (): BenchmarkingActionTypes => ({
    type: CREATE_METER_FAILURE
});

export const editMeterSuccess = (): BenchmarkingActionTypes => ({
    type: EDIT_METER_SUCCESS
});

export const deleteMeterSuccess = (): BenchmarkingActionTypes => ({
    type: DELETE_METER_SUCCESS
});

export const createWastemeterSuccess = (): BenchmarkingActionTypes => ({
    type: CREATE_WASTEMETER_SUCCESS
});

export const createWastemeterFailure = (): BenchmarkingActionTypes => ({
    type: CREATE_WASTEMETER_FAILURE
});

export const editWastemeterSuccess = (): BenchmarkingActionTypes => ({
    type: EDIT_WASTEMETER_SUCCESS
});

export const deleteWastemeterSuccess = (): BenchmarkingActionTypes => ({
    type: DELETE_WASTEMETER_SUCCESS
});

export const createConsumptionDataSuccess = (): BenchmarkingActionTypes => ({
    type: CREATE_CONSUMPTION_DATA_SUCCESS
});

export const editConsumptionDataSuccess = (): BenchmarkingActionTypes => ({
    type: EDIT_CONSUMPTION_DATA_SUCCESS
});

export const editConsumptionDataFailure = (): BenchmarkingActionTypes => ({
    type: EDIT_CONSUMPTION_DATA_FAILURE
});

export const deleteConsumptionDataSuccess = (): BenchmarkingActionTypes => ({
    type: DELETE_CONSUMPTION_DATA_SUCCESS
});

export const createDeliveryDataSuccess = (): BenchmarkingActionTypes => ({
    type: CREATE_DELIVERY_DATA_SUCCESS
});

export const editDeliveryDataSuccess = (): BenchmarkingActionTypes => ({
    type: EDIT_DELIVERY_DATA_SUCCESS
});

export const deleteDeliveryDataSuccess = (): BenchmarkingActionTypes => ({
    type: DELETE_DELIVERY_DATA_SUCCESS
});

export const createWasteConsumptionDataSuccess = (): BenchmarkingActionTypes => ({
    type: CREATE_WASTE_CONSUMPTION_DATA_SUCCESS
});

export const editWasteConsumptionDataSuccess = (): BenchmarkingActionTypes => ({
    type: EDIT_WASTE_CONSUMPTION_DATA_SUCCESS
});

export const editWasteConsumptionDataFailure = (): BenchmarkingActionTypes => ({
    type: EDIT_WASTE_CONSUMPTION_DATA_FAILURE
});

export const deleteWasteConsumptionDataSuccess = (): BenchmarkingActionTypes => ({
    type: DELETE_WASTE_CONSUMPTION_DATA_SUCCESS
});

export const createEnergyStarPropertyDetailsSuccess = () => ({
    type: CREATE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS
});
export const editEnergyStarPropertyDetailsSuccess = () => ({
    type: EDIT_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS
});
export const deleteEnergyStarPropertyDetailsSuccess = () => ({
    type: DELETE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS
});
