import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { theme } from '../../theme';

export default function IBILoader(props) {
    const { color, height, width, timeout, className, type = false } = props;
    return (
        <div className={'d-flex align-items-center justify-content-center w-100 flex-grow-1'}>
            <Loader
                type={type}
                color={color}
                height={height}
                width={width}
                timeout={timeout}
                className={className}
            />
        </div>
    );
}

IBILoader.defaultProps = {
    color: theme.primary.THEME,
    type: 'TailSpin',
    height: 100,
    width: 100,
    className: 'loader'
};

IBILoader.propTypes = {
    height: PropTypes.any,
    width: PropTypes.any,
    color: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    timeout: PropTypes.number
};
