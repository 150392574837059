import { GetResponseInterface, GetRequestInterface } from '../../../interfaces/interfaces';

export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';

export interface ViewCompanyInterFace {
    companyId: string;
    companyName: string;
    address: string;
    companyType: string;
    registeredBuildings: number;
    certificatioCount: number;
}

export interface viewCompanyParams extends GetRequestInterface {
    params: string;
}
export interface viewCompanyJson extends GetResponseInterface {
    results: Array<ViewCompanyInterFace>;
}

export interface fetchCompanyAction {
    type: typeof FETCH_COMPANY;
    params: viewCompanyParams;
    shouldAppend?: boolean;
}

export interface fetchCompanySuccessAction {
    type: typeof FETCH_COMPANY_SUCCESS;
    data: any;
    shouldAppend?: boolean;
}

export type FetchCompanyActionTypes = fetchCompanyAction | fetchCompanySuccessAction;

export interface FetchCompanyState {
    isFetchingCompany: boolean;
    fetchCompanyData: viewCompanyJson;
}
