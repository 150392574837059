import ReactDOM from 'react-dom';
import React from 'react';
import UserConfirmationModal from './UserConfirmationModal';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import store from '../Store/index';
import { createBrowserHistory } from 'history';

export const customStyles = {
    content: {
        minWidth: '15%',
        minHeight: '15%'
    }
};

const UserLeaveConfirmation = (message: any, callback: any): any => {
    const { header, content } = JSON.parse(message);
    const container = document.createElement('div');
    const browserHistory = createBrowserHistory();

    const handleConfirm = (callbackState: any) => {
        ReactDOM.unmountComponentAtNode(container);
        callback(callbackState);
    };

    const handleCancel = (e: any) => {
        e.preventDefault();
        browserHistory.push(browserHistory.location.pathname);
        ReactDOM.unmountComponentAtNode(container);
    };

    const postModalClose = () => {
        ReactDOM.unmountComponentAtNode(container);
    };

    document.body.appendChild(container);
    return ReactDOM.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <UserConfirmationModal
                    isOpen={true}
                    title={header}
                    onCancel={handleCancel}
                    isModalHeaderShown={true}
                    content={content}
                    onConfirm={handleConfirm}
                    style={customStyles}
                    postModalClose={postModalClose}
                />
            </ThemeProvider>
        </Provider>,
        container
    );
};

export default UserLeaveConfirmation;
