export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_INVOICE_DETAILS_RECEIVED = 'GET_INVOICE_DETAILS_RECEIVED';

export interface getInvoiceDetailsAction {
    type: typeof GET_INVOICE_DETAILS;
    id: number;
}

export interface getInvoiceDetailsReceivedAction {
    type: typeof GET_INVOICE_DETAILS_RECEIVED;
    payload: any;
}

export type InvoiceActionTypes = getInvoiceDetailsAction | getInvoiceDetailsReceivedAction;

export interface InvoiceState {
    invoiceDetailsData: any;
    isFetchingInvoiceDetails: boolean;
}
