export const CREATE_NEW_INVOICE_FEE = 'CREATE_NEW_INVOICE_FEE';
export const CREATE_NEW_INVOICE_FEE_SUCCESS = 'CREATE_NEW_INVOICE_FEE_SUCCESS';
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_OPTIONS = 'GET_INVOICE_OPTIONS';
export const GET_INVOICE_OPTIONS_SUCCESS = 'GET_INVOICE_OPTIONS_SUCCESS';
export const GET_INVOICE_DATA = 'GET_INVOICE_DATA';
export const GET_INVOICE_DATA_SUCCESS = 'GET_INVOICE_DATA_SUCCESS';
export const EDIT_INVOICE_DATA = 'EDIT_INVOICE_DATA';
export const EDIT_INVOICE_DATA_SUCCESS = 'EDIT_INVOICE_DATA_SUCCESS';
export const SET_INVOICE_TYPE = 'SET_INVOICE_TYPE';

export interface createNewInvoiceFeeAction {
    type: typeof CREATE_NEW_INVOICE_FEE;
    params: any;
    history: any;
    stream: string;
}
export interface createNewInvoiceFeeSuccessAction {
    type: typeof CREATE_NEW_INVOICE_FEE_SUCCESS;
}

export interface getInvoiceListAction {
    type: typeof GET_INVOICE_LIST;
    params: any;
    stream: string;
    appendData?: boolean;
}

export interface getInvoiceListSuccessAction {
    type: typeof GET_INVOICE_LIST_SUCCESS;
    payload: any;
    appendData?: boolean;
}

export interface getInvoicDatatAction {
    type: typeof GET_INVOICE_DATA;
    params: any;
    id: number;
}

export interface getInvoiceDataSuccessAction {
    type: typeof GET_INVOICE_DATA_SUCCESS;
    payload: any;
}

export interface editInvoicDatatAction {
    type: typeof EDIT_INVOICE_DATA;
    params: any;
    id: number;
    history: any;
    stream: string;
}

export interface editInvoiceDataSuccessAction {
    type: typeof EDIT_INVOICE_DATA_SUCCESS;
}

export interface getInvoiceOptionsAction {
    type: typeof GET_INVOICE_OPTIONS;
}

export interface getInvoiceOptionsSuccessAction {
    type: typeof GET_INVOICE_OPTIONS_SUCCESS;
    payload: any;
}

export interface setInvoiceTypeAction {
    type: typeof SET_INVOICE_TYPE;
    data: any;
}

export type createNewInvoiceFeeActionTypes =
    | createNewInvoiceFeeAction
    | createNewInvoiceFeeSuccessAction
    | getInvoiceListAction
    | getInvoiceListSuccessAction
    | getInvoiceOptionsAction
    | getInvoiceOptionsSuccessAction
    | getInvoicDatatAction
    | getInvoiceDataSuccessAction
    | editInvoicDatatAction
    | editInvoiceDataSuccessAction
    | setInvoiceTypeAction;

export interface createNewInvoiceFeeState {
    isNewInvoiceFeeCreated: boolean;
    isFetchingInvoiceDataList: boolean;
    invoiceDataList: any;
    invoiceOptionsData: any;
    invoiceData: any;
    isFetchingInvoiceData: boolean;
    isInvoiceDataEdited: boolean;
    invoiceType: any;
}
