import { defaultGetResponse } from '../../interfaces/interfaces';
import {
    CommonState,
    CommonActionTypes,
    SET_FIRSTTIME_LOGIN,
    SET_ISMODAL_OPEN,
    GET_GROUPS_LIST,
    GET_GROUPS_LIST_SUCCESS,
    GET_REGIONS_LIST,
    GET_REGIONS_LIST_SUCCESS,
    GET_SUB_REGIONS_LIST,
    GET_SUB_REGIONS_LIST_SUCCESS,
    SET_IS_EDIT,
    GET_USER_REGIONS_AND_SUB_REGIONS_LIST,
    GET_USER_REGIONS_AND_SUB_REGIONS_LIST_SUCCESS,
    SET_SHOW_LOADER,
    SET_TABLE_ROW_LIMIT,
    CREATE_REGION,
    CREATE_REGION_SUCCESS,
    EDIT_REGION,
    EDIT_REGION_SUCCESS,
    CREATE_SUB_REGION,
    CREATE_SUB_REGION_SUCCESS,
    EDIT_SUB_REGION,
    EDIT_SUB_REGION_SUCCESS,
    CHOICES_RECIEVED,
    SET_SHOW_APP_LOADER
} from '../actions/Common/types';

const initialState: CommonState = {
    isModalOpen: false,
    isRightPanelOpen: false,
    isFirstTimeLogin: false,
    isFetchingGroupsList: false,
    groupsListData: defaultGetResponse,
    isFetchingRegionsList: false,
    regionsListData: null,
    isFetchingSubRegionsList: false,
    subRegionsListData: null,
    isEdit: false,
    isFetchingUserRegionsAndSubRegionsList: false,
    userRegionsAndSubRegionsListData: null,
    showLoader: false,
    showAppLoader: false,
    tableDataLimit: 20,
    choices: {}
};

const Common = (state = initialState, action: CommonActionTypes): CommonState => {
    switch (action.type) {
        case SET_ISMODAL_OPEN: {
            return {
                ...state,
                isModalOpen: action.isModalOpen
            };
        }
        case SET_FIRSTTIME_LOGIN: {
            return {
                ...state,
                isFirstTimeLogin: action.isFirstTimeLogin
            };
        }

        case GET_GROUPS_LIST: {
            return {
                ...state,
                isFetchingGroupsList: true
            };
        }
        case GET_GROUPS_LIST_SUCCESS: {
            return {
                ...state,
                isFetchingGroupsList: false,
                groupsListData: action.payload
            };
        }

        case GET_REGIONS_LIST: {
            return {
                ...state,
                isFetchingRegionsList: true
            };
        }
        case GET_REGIONS_LIST_SUCCESS: {
            if (action.shouldAppend) {
                action.payload.results = [
                    ...state.regionsListData.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                isFetchingRegionsList: false,
                regionsListData: action.payload
            };
        }

        case GET_SUB_REGIONS_LIST: {
            return {
                ...state,
                isFetchingSubRegionsList: true
            };
        }
        case GET_SUB_REGIONS_LIST_SUCCESS: {
            if (action.shouldAppend) {
                action.payload.results = [
                    ...state.subRegionsListData.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                isFetchingSubRegionsList: false,
                subRegionsListData: action.payload
            };
        }

        case SET_IS_EDIT: {
            return {
                ...state,
                isEdit: action.data
            };
        }

        case GET_USER_REGIONS_AND_SUB_REGIONS_LIST: {
            return {
                ...state,
                isFetchingUserRegionsAndSubRegionsList: true
            };
        }
        case GET_USER_REGIONS_AND_SUB_REGIONS_LIST_SUCCESS: {
            return {
                ...state,
                isFetchingUserRegionsAndSubRegionsList: false,
                userRegionsAndSubRegionsListData: action.payload
            };
        }
        case SET_SHOW_LOADER: {
            return {
                ...state,
                showLoader: action.data
            };
        }

        case SET_SHOW_APP_LOADER: {
            return {
                ...state,
                showAppLoader: action.data
            };
        }
        case SET_TABLE_ROW_LIMIT: {
            return {
                ...state,
                tableDataLimit: action.limit
            };
        }
        case CREATE_REGION: {
            return {
                ...state
            };
        }
        case CREATE_REGION_SUCCESS: {
            return {
                ...state
            };
        }
        case EDIT_REGION: {
            return {
                ...state
            };
        }
        case EDIT_REGION_SUCCESS: {
            return {
                ...state
            };
        }
        case CREATE_SUB_REGION: {
            return {
                ...state
            };
        }
        case CREATE_SUB_REGION_SUCCESS: {
            return {
                ...state
            };
        }
        case EDIT_SUB_REGION: {
            return {
                ...state
            };
        }
        case EDIT_SUB_REGION_SUCCESS: {
            return {
                ...state
            };
        }
        case CHOICES_RECIEVED: {
            return {
                ...state,
                choices: action.data
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default Common;
