export const primaryNavigationWidth = '260px';
export const secondaryNavigationWidth = '258px';
export const entryInputHeight = '38px';
export const userProfileImgWidth = '30px';
export const secondaryProfileDimension = '122px';
export const primaryNavHeadingHeight = '70px';
export const customCheckBoxDimension = '18px';
export const customRadioDimension = '20px';
export const eyeIconWidth = '16px';
export const closeIconWidth = '25px';
export const tabItemActiveWidth = '140px';
export const tabItemHeight = '32px';
export const tabItemInActiveWidth = '120px';
export const rightSidePanelWidth = '380px';
export const sitckyBtnDimension = '56px';
export const userProfileIconWidth = '38px';
