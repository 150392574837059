export const GET_VERIFICATIONS = 'GET_VERIFICATIONS';
export const GET_VERIFICATIONS_SUCCESS = 'GET_VERIFICATIONS_SUCCESS';
export const GET_VERIFIERS = 'GET_VERIFIERS';
export const GET_VERIFIERS_SUCCESS = 'GET_VERIFIERS_SUCCESS';
export const ASSIGN_VERIFIER = 'ASSIGN_VERIFIER';
export const ASSIGN_VERIFIER_SUCCESS = 'ASSIGN_VERIFIER_SUCCESS';
export const GET_START_VERIFICATIONS = 'GET_START_VERIFICATIONS';
export const GET_START_VERIFICATIONS_SUCCESS = 'GET_START_VERIFICATIONS_SUCCESS';
export const START_VERIFICATION = 'START_VERIFICATION';
export const START_VERIFICATION_SUCCESS = 'START_VERIFICATION_SUCCESS';
export const SCHEDULE_VERIFICATION = 'SCHEDULE_VERIFICATION';
export const SCHEDULE_VERIFICATION_SUCCESS = 'SCHEDULE_VERIFICATION_SUCCESS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const CREATE_VERIFICATION_QUESTION = 'CREATE_VERIFICATION_QUESTION';
export const CREATE_VERIFICATION_QUESTION_SUCCESS = 'CREATE_VERIFICATION_QUESTION_SUCCESS';
export const EDIT_VERIFICATION_QUESTION = 'EDIT_VERIFICATION_QUESTION';
export const EDIT_VERIFICATION_QUESTION_SUCCESS = 'EDIT_VERIFICATION_QUESTION_SUCCESS';
export const GET_VERIFICATION_OPTIONS = 'GET_VERIFICATION_OPTIONS';
export const GET_VERIFICATION_OPTIONS_SUCCESS = 'GET_VERIFICATION_OPTIONS_SUCCESS';
export const EDIT_ANSWERS = 'EDIT_ANSWERS';
export const EDIT_ANSWERS_SUCCESS = 'EDIT_ANSWERS_SUCCESS';
export const GET_PROPERTY_USER = 'GET_PROPERTY_USER';
export const GET_PROPERTY_USER_RECEIVED = 'GET_PROPERTY_USER_RECEIVED';
export interface getVerificationsAction {
    type: typeof GET_VERIFICATIONS;
    params: any;
    shouldAppend: boolean;
}

export interface getVerificationsSuccessAction {
    type: typeof GET_VERIFICATIONS_SUCCESS;
    payload: any;
    shouldAppend: boolean;
}

export interface getVerifiersAction {
    type: typeof GET_VERIFIERS;
    id: number;
}

export interface getVerifiersSuccessAction {
    type: typeof GET_VERIFIERS_SUCCESS;
    payload: any;
}

export interface assignVerifierAction {
    type: typeof ASSIGN_VERIFIER;
    id: number;
    params: any;
}

export interface assignVerifierSuccessAction {
    type: typeof ASSIGN_VERIFIER_SUCCESS;
}

export interface getStartVerificationsAction {
    type: typeof GET_START_VERIFICATIONS;
    params: any;
    verificationType: any;
    appendData: boolean;
}

export interface getStartVerificationsSuccessAction {
    type: typeof GET_START_VERIFICATIONS_SUCCESS;
    payload: any;
    appendData: boolean;
}

export interface startVerificationAction {
    type: typeof START_VERIFICATION;
    params: any;
}

export interface startVerificationSuccessAction {
    type: typeof START_VERIFICATION_SUCCESS;
}

export interface scheduleVerificationAction {
    type: typeof SCHEDULE_VERIFICATION;
    id: number;
    params: any;
}

export interface scheduleVerificationSuccessAction {
    type: typeof SCHEDULE_VERIFICATION_SUCCESS;
}

export interface addCommentsAction {
    type: typeof ADD_COMMENT;
    id: number;
    params: any;
    history: any;
}

export interface addCommentsSuccessAction {
    type: typeof ADD_COMMENT_SUCCESS;
}
export interface createVerificationQuestionAction {
    type: typeof CREATE_VERIFICATION_QUESTION;
    params: any;
}
export interface createVerificationQuestionSuccessAction {
    type: typeof CREATE_VERIFICATION_QUESTION_SUCCESS;
}
export interface editVerificationQuestionAction {
    type: typeof EDIT_VERIFICATION_QUESTION;
    id: number;
    params: any;
}
export interface editVerificationQuestionSuccessAction {
    type: typeof EDIT_VERIFICATION_QUESTION_SUCCESS;
}

export interface getVerificationOptionsAction {
    type: typeof GET_VERIFICATION_OPTIONS;
}

export interface getVerificationOptionsSuccessAction {
    type: typeof GET_VERIFICATION_OPTIONS_SUCCESS;
    data: any;
}
export interface editAnswersAction {
    type: typeof EDIT_ANSWERS;
    params: any;
    id: number;
}

export interface editAnswersSuccessAction {
    type: typeof EDIT_ANSWERS_SUCCESS;
    payload: any;
}

export interface getPropertyUserAction {
    type: typeof GET_PROPERTY_USER;
    id: number;
}

export interface getPropertyUserSuccessAction {
    type: typeof GET_PROPERTY_USER_RECEIVED;
    payload: any;
}

export type VerificationActionTypes =
    | getVerificationsAction
    | getVerificationsSuccessAction
    | getVerifiersAction
    | getVerifiersSuccessAction
    | assignVerifierAction
    | assignVerifierSuccessAction
    | getStartVerificationsAction
    | getStartVerificationsSuccessAction
    | startVerificationAction
    | startVerificationSuccessAction
    | scheduleVerificationAction
    | scheduleVerificationSuccessAction
    | addCommentsAction
    | addCommentsSuccessAction
    | createVerificationQuestionAction
    | createVerificationQuestionSuccessAction
    | editVerificationQuestionAction
    | editVerificationQuestionSuccessAction
    | getVerificationOptionsAction
    | getVerificationOptionsSuccessAction
    | editAnswersAction
    | editAnswersSuccessAction
    | getPropertyUserAction
    | getPropertyUserSuccessAction;

export interface VerificationState {
    assignVerificationdata: any;
    verifiersData: any;
    startVerificationdata: any;
    verificationQuestionData: any;
    verificationOptions: any;
    isFetchingAssignData: boolean;
    isFetchingStartData: boolean;
    editAnswerDetails: any;
    propertyUserData: any;
    verificationReportData: any;
    isFetchingVerificationReport: boolean;
    isFetchingVerificationQuestion: boolean;
}
