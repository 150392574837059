import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LoginActionTypes,
    loginJson,
    loginParams,
    LOGOUT,
    forgotPasswordParams,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    resetParams,
    RESET,
    RESET_SUCCESS,
    UPDATE_PASSWORD,
    GET_MULTITENANT_CONFIG,
    GET_MULTITENANT_CONFIG_RECEIVED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE
} from './types';

export const getLoginJwt = (params: loginParams, history: any): LoginActionTypes => ({
    type: LOGIN,
    params,
    history
});

export const loginJwtReceived = (data: loginJson): LoginActionTypes => ({
    type: LOGIN_SUCCESS,
    data
});

export const getLoginFailed = (data: loginJson): LoginActionTypes => ({
    type: LOGIN_FAILURE,
    data
});

export const logout = (): LoginActionTypes => ({
    type: LOGOUT
});

export const logoutSuccess = (): LoginActionTypes => ({
    type: LOGOUT_SUCCESS
});

export const logoutFailure = (): LoginActionTypes => ({
    type: LOGOUT_FAILURE
});

export const forgotPasswordRequest = (params: forgotPasswordParams): LoginActionTypes => ({
    type: FORGOT_PASSWORD,
    params
});

export const forgotPasswordRequestSuccess = () => ({
    type: FORGOT_PASSWORD_SUCCESS
});

export const forgotPasswordRequestFailure = () => ({
    type: FORGOT_PASSWORD_FAILURE
});

export const resetPassword = (params: resetParams): LoginActionTypes => ({
    type: RESET,
    params
});

export const resetPasswordSuccess = () => ({
    type: RESET_SUCCESS
});

export const updatePassword = (params: any): LoginActionTypes => ({
    type: UPDATE_PASSWORD,
    params
});

export const getMultiTenantConfig = (): LoginActionTypes => ({
    type: GET_MULTITENANT_CONFIG
});

export const getMultiTenantConfigReceived = (payload: any): LoginActionTypes => ({
    type: GET_MULTITENANT_CONFIG_RECEIVED,
    payload
});
