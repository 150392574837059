import {
    ADD_COMMENT,
    ADD_COMMENT_SUCCESS,
    ASSIGN_VERIFIER,
    ASSIGN_VERIFIER_SUCCESS,
    CREATE_VERIFICATION_QUESTION,
    CREATE_VERIFICATION_QUESTION_SUCCESS,
    EDIT_ANSWERS,
    EDIT_ANSWERS_SUCCESS,
    EDIT_VERIFICATION_QUESTION,
    EDIT_VERIFICATION_QUESTION_SUCCESS,
    GET_PROPERTY_USER,
    GET_PROPERTY_USER_RECEIVED,
    GET_START_VERIFICATIONS,
    GET_START_VERIFICATIONS_SUCCESS,
    GET_VERIFICATIONS,
    GET_VERIFICATIONS_SUCCESS,
    GET_VERIFICATION_OPTIONS,
    GET_VERIFICATION_OPTIONS_SUCCESS,
    GET_VERIFIERS,
    GET_VERIFIERS_SUCCESS,
    SCHEDULE_VERIFICATION,
    SCHEDULE_VERIFICATION_SUCCESS,
    START_VERIFICATION,
    START_VERIFICATION_SUCCESS,
    VerificationActionTypes
} from './types';

export const getVerifications = (params: any, shouldAppend: boolean): VerificationActionTypes => ({
    type: GET_VERIFICATIONS,
    params,
    shouldAppend
});

export const getVerificationsSuccess = (
    payload: any,
    shouldAppend: boolean
): VerificationActionTypes => ({
    type: GET_VERIFICATIONS_SUCCESS,
    payload,
    shouldAppend
});

export const getVerifiers = (id: number): VerificationActionTypes => ({
    type: GET_VERIFIERS,
    id
});

export const getVerifiersSuccess = (payload: any): VerificationActionTypes => ({
    type: GET_VERIFIERS_SUCCESS,
    payload
});

export const assignVerifier = (id: number, params: any): VerificationActionTypes => ({
    type: ASSIGN_VERIFIER,
    id,
    params
});

export const assignVerifierSuccess = (): VerificationActionTypes => ({
    type: ASSIGN_VERIFIER_SUCCESS
});

export const getStartVerifications = (
    params: any,
    verificationType: any,
    appendData: boolean
): VerificationActionTypes => ({
    type: GET_START_VERIFICATIONS,
    params,
    verificationType,
    appendData
});

export const getStartVerificationsSuccess = (
    payload: any,
    appendData: boolean
): VerificationActionTypes => ({
    type: GET_START_VERIFICATIONS_SUCCESS,
    payload,
    appendData
});

export const startVerification = (params: any): VerificationActionTypes => ({
    type: START_VERIFICATION,
    params
});

export const startVerificationSuccess = (): VerificationActionTypes => ({
    type: START_VERIFICATION_SUCCESS
});

export const scheduleVerification = (id: number, params: any): VerificationActionTypes => ({
    type: SCHEDULE_VERIFICATION,
    id,
    params
});

export const scheduleVerificationSuccess = (): VerificationActionTypes => ({
    type: SCHEDULE_VERIFICATION_SUCCESS
});

export const addComments = (id: number, params: any, history: any): VerificationActionTypes => ({
    type: ADD_COMMENT,
    id,
    params,
    history
});

export const addCommentsSuccess = (): VerificationActionTypes => ({
    type: ADD_COMMENT_SUCCESS
});

export const createVerificationQuestion = (params: any): VerificationActionTypes => ({
    type: CREATE_VERIFICATION_QUESTION,
    params
});

export const createVerificationQuestionSuccess = (): VerificationActionTypes => ({
    type: CREATE_VERIFICATION_QUESTION_SUCCESS
});

export const editVerificationQuestion = (id: number, params: any): VerificationActionTypes => ({
    type: EDIT_VERIFICATION_QUESTION,
    id,
    params
});

export const editVerificationQuestionSuccess = (): VerificationActionTypes => ({
    type: EDIT_VERIFICATION_QUESTION_SUCCESS
});

export const getVerificationOptions = (): VerificationActionTypes => ({
    type: GET_VERIFICATION_OPTIONS
});

export const getVerificationOptionsSuccess = (data: any): VerificationActionTypes => ({
    type: GET_VERIFICATION_OPTIONS_SUCCESS,
    data
});

export const editAnswers = (params: any, id: any): VerificationActionTypes => ({
    type: EDIT_ANSWERS,
    params,
    id
});

export const editAnswersSuccess = (payload: any): VerificationActionTypes => ({
    type: EDIT_ANSWERS_SUCCESS,
    payload
});

export const getPropertyUser = (id: number): VerificationActionTypes => ({
    type: GET_PROPERTY_USER,
    id
});

export const getPropertyUserReceived = (payload: any): VerificationActionTypes => ({
    type: GET_PROPERTY_USER_RECEIVED,
    payload
});
