import { defaultGetResponse } from '../../interfaces/interfaces';
import {
    FETCH_COMPANY,
    FETCH_COMPANY_SUCCESS,
    FetchCompanyActionTypes,
    FetchCompanyState
} from '../actions/ViewCompany/types';

const initialState: FetchCompanyState = {
    isFetchingCompany: false,
    fetchCompanyData: defaultGetResponse
};

const ViewCompany = (state = initialState, action: FetchCompanyActionTypes): FetchCompanyState => {
    switch (action.type) {
        case FETCH_COMPANY: {
            return {
                ...state,
                isFetchingCompany: true
            };
        }
        case FETCH_COMPANY_SUCCESS: {
            if (action.shouldAppend) {
                action.data.results = [...state.fetchCompanyData?.results, ...action.data.results];
            }
            return {
                ...state,
                isFetchingCompany: false,
                fetchCompanyData: action.data
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default ViewCompany;
