export interface GetRequestInterface {
    fields?: string;
    ordering?: string;
    limit?: number | string;
    offset?: number;
    [propName: string]: any;
}

export interface GetResponseInterface {
    meta: {
        count: number;
        next: null | string;
        previous: null | string;
    };
    results: Array<{}>;
    count: number;
}

export const defaultGetResponse = {
    meta: {
        count: 0,
        next: null,
        previous: null
    },
    results: [],
    count: 0
};

export interface SelectValue {
    label: string | number;
    value: string | number;
}
export interface ResetPasswordInterface {
    Password: string;
    confirmPassword: string;
}
export interface UserProfileInterface {
    comapanyName: SelectValue;
    authorisationLevel: SelectValue;
    firstName: string;
    lastName: string;
    emailId: string;
    countryCode: SelectValue;
    contactNumber: number;
    region: SelectValue;
    subRegion: SelectValue;
}

export interface BuildingRegistrationInterface {
    comapanyName: string;
    buildingType: SelectValue;
    region: SelectValue;
    subRegion: SelectValue;
    buildingName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateOrProvinceOrRegion: string;
    postalCode: number;
    country: SelectValue;
}

export interface CreateNewBuildingInterface {
    buildingType: SelectValue;
    region: SelectValue;
    subRegion: SelectValue;
    registrationType: SelectValue;
    portfolioStream: string;
    buildingName: string;
}

export interface CompanyDataInterface {
    id: string;
    name: string;
    address: string;
    status: string;
    region: string;
    type: string;
    certification: string;
    certificationexpiry: string;
    certificationscore: string;
    grossfloorarea: string;
    testbuilding: string;
    program: string;
    propertymanagementcompany: string;
    propertyownercompany: string;
}

export interface NewCompanyRegistrationInterface {
    companyName: string;
    companyType: SelectValue;
}
