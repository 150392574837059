import { defaultGetResponse } from '../../interfaces/interfaces';
import {
    ADD_CLIENT_COMPANY_SUCCESS,
    AddClientCompanyActionTypes,
    AddClientCompanyState,
    ADD_CLIENT_COMPANY,
    ADD_CLIENT_COMPANY_FAILURE,
    GET_CLIENT_COMPANY,
    GET_CLIENT_COMPANY_SUCCESS
} from '../actions/AddClientCompany/types';

const initialState: AddClientCompanyState = {
    isClientCompanyAdded: false,
    isFetching: false,
    clientCompanyNamesData: defaultGetResponse
};

const AddClientCompany = (
    state = initialState,
    action: AddClientCompanyActionTypes
): AddClientCompanyState => {
    switch (action.type) {
        case ADD_CLIENT_COMPANY: {
            return {
                ...state,
                isClientCompanyAdded: true
            };
        }
        case ADD_CLIENT_COMPANY_SUCCESS: {
            return {
                ...state,
                isClientCompanyAdded: false
            };
        }

        case ADD_CLIENT_COMPANY_FAILURE: {
            return {
                ...state,
                isClientCompanyAdded: false
            };
        }

        case GET_CLIENT_COMPANY: {
            return {
                ...state
            };
        }
        case GET_CLIENT_COMPANY_SUCCESS: {
            return {
                ...state,
                isFetching: true,
                clientCompanyNamesData: action.data
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default AddClientCompany;
