import styled from 'styled-components';
import { device } from '../responsiveBreakpoints';

export const DashBoardContainer = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: auto;
`;

export const MainContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
    background-color: ${(props) => props.theme.light_secondary.LIGHT_DARK};
    padding-top: 24px;
    overflow: auto;
`;

export const BuildingCard = styled.div`
    display: flex;
    flex-wrap: wrap;
    border: solid 1px #dedede;
    border-left: solid 5px ${(props: any) => props.theme.primary.THEME};
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    width: calc(33.33% - 16px);

    h4 {
        color: ${(props: any) => props.theme.secondaryColor};
        font-size: 15px;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
        font-weight: 600;
    }

    p {
        color: #575b64;
        font-size: 14px;
        letter-spacing: 0.28px;
    }

    @media ${device.tablet} {
        width: fit-content;
    }
`;

export const BuildingCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    border: 1px solid ${(props: any) => props.theme.light_secondary.LIGHT_DARK};
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(44, 40, 40, 0.08);
    background-color: white;
`;

export const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: between;
    border-bottom: 1px solid ${(props: any) => props.theme.light_secondary.LIGHT_DARK};
`;

export const CertificateAndMapBoxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 500px;
`;

export const MapAndGraphContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FeatureProjectBox = styled.div`
    flex: 1;
    display: flex;

    .highlight-animation {
        animation: focus 1s;
        animation-iteration-count: 1;
        border-radius: 12px;

        @keyframes focus {
            50% {
                box-shadow: 0 0 5px rgb(38 55 87 / 46%);
            }
        }
    }

    .building-summary-slider {
        .slick-next,
        .slick-prev {
            top: auto;
            bottom: -30px;
            z-index: 99;
        }

        .slick-next {
            right: 0;
            right: 35%;
        }

        .slick-prev {
            left: 0;
            left: 35%;
        }

        .slick-prev:before,
        .slick-next:before {
            color: ${(props: any) => props.theme.primary.THEME};
        }
    }

    .summary-details {
        flex: 1;
        overflow-y: auto;
        margin-top: 100px;

        .group {
            margin-bottom: 20px;
        }

        .add-building-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4,
            p {
                margin: 5px 0;
            }
        }

        h4 {
            color: ${(props: any) => props.theme.secondaryColor};
            font-size: 15px;
            letter-spacing: 0.3px;
            margin-bottom: 5px;
            font-weight: 600;
        }

        p {
            color: #575b64;
            font-size: 14px;
            letter-spacing: 0.28px;
        }
    }

    .small-profile-icon,
    .building-image {
        object-fit: contain;
        height: 200px;
    }
`;
