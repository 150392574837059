import {
    notificationsActionTypes,
    GET_NOTIFICATIONS_MESSAGE,
    GET_PREVIOUS_NOTIFICATIONS_MESSAGE,
    GET_PREVIOUS_NOTIFICATIONS_MESSAGE_SUCCESS,
    MARK_AS_READ_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION_SUCCESS,
    GET_PENDING_REQUEST_ACCESS_DATA,
    GET_PENDING_REQUEST_ACCESS_DATA_SUCCESS,
    MARK_ALL_AS_READ_NOTIFICATION,
    MARK_ALL_AS_READ_NOTIFICATION_SUCCESS,
    SET_PERMISSION_TAB
} from './types';

export const getNotificationsMessage = (message: any): notificationsActionTypes => ({
    type: GET_NOTIFICATIONS_MESSAGE,
    message
});

export const getPreviousNotificationsMessage = (
    params: any,
    url?: string,
    appendData = false
): notificationsActionTypes => ({
    type: GET_PREVIOUS_NOTIFICATIONS_MESSAGE,
    params,
    url,
    appendData
});

export const getPreviousNotificationsMessageSuccess = (
    previousMessage: any,
    appendData?: boolean
): notificationsActionTypes => ({
    type: GET_PREVIOUS_NOTIFICATIONS_MESSAGE_SUCCESS,
    previousMessage,
    appendData
});

export const markAsReadNotification = (
    params: any,
    notificationParams: any
): notificationsActionTypes => ({
    type: MARK_AS_READ_NOTIFICATION,
    params,
    notificationParams
});

export const markAsReadNotificationSuccess = (): notificationsActionTypes => ({
    type: MARK_AS_READ_NOTIFICATION_SUCCESS
});

export const markAllAsReadNotification = (params: any): notificationsActionTypes => ({
    type: MARK_ALL_AS_READ_NOTIFICATION,
    params
});

export const markAllAsReadNotificationSuccess = (): notificationsActionTypes => ({
    type: MARK_ALL_AS_READ_NOTIFICATION_SUCCESS
});

export const getPendingRequestAccessData = (
    params: any,
    filterType: string,
    appendData = false
): notificationsActionTypes => ({
    type: GET_PENDING_REQUEST_ACCESS_DATA,
    params,
    filterType,
    appendData
});

export const getPendingRequestAccessDataSuccess = (
    payload: any,
    appendData?: boolean
): notificationsActionTypes => ({
    type: GET_PENDING_REQUEST_ACCESS_DATA_SUCCESS,
    payload,
    appendData
});

export const setPermissionTab = (data: string): notificationsActionTypes => ({
    type: SET_PERMISSION_TAB,
    data
});
