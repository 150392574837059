import loadable from '@loadable/component';
import axios from 'axios';
import { formatInTimeZone } from 'date-fns-tz';
import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import {
    canFeature,
    clearLocalStorageAfterLogout,
    onErrorCallback,
    setGenericTableLimit,
    setUserLanguage
} from './CommonUtilities';
import {
    getChoices,
    getCurrentUserData,
    getMultiTenantConfig,
    setShowAppLoader
} from './Store/actions';
import { getFeatureFlags } from './Store/actions/Waffle';
import { BomaLoader } from './assets/animationFiles';
import LottieAnimation from './components/DesignSystem/LottieAnimation';
import IBILoader from './components/UIComponents/IBILoader';
import PublicDashboardRoute from './components/routes/PublicDashboardRoute';
import { apiToken, api_prefix, isTokenBasedAuthentication } from './config';
import { featureFlags } from './constants';

const GlobalRouterContainer = loadable(() => import('./components/routes/GlobalRouterContainer'));
const ApplicationContainer = loadable(() => import('./components/ApplicationContainer'));

const customStyles = {
    content: {
        minWidth: '15%',
        minHeight: '15%',
        backgroundColor: 'transparent'
    }
};

export const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const routeName = location.pathname;

    const loginData = useSelector((state: any) => state.Login.loginData);
    const multiTenantDataArray = useSelector((state: any) => state.Login.multiTenantData);
    const multiTenantData = multiTenantDataArray && multiTenantDataArray[0];
    const showAppLoader = useSelector((state: any) => state.Common.showAppLoader);

    useEffect(() => {
        dispatch(getMultiTenantConfig());
    }, [dispatch]);

    const getWaffleFlags = useCallback(() => {
        axios
            .get(`${api_prefix}/waffle_json/`)
            .then((response) => {
                dispatch(getFeatureFlags(response.data));
            })
            .catch(onErrorCallback);
    }, [dispatch]);

    useEffect(() => {
        if (routeName === '/PublicDashboard' && !localStorage.getItem('accessToken')) {
            getWaffleFlags();
        }
    }, [getWaffleFlags, routeName]);

    useEffect(() => {
        if (multiTenantData?.admin_company) {
            document.title = `${multiTenantData?.admin_company.name}- Application`;
        }
    }, [multiTenantData]);

    useEffect(() => {
        const token = Cookies.get('csrftoken');

        if (window.name === 'NewUserLogin') {
            axios.defaults.headers.common['X-Login-As-User'] = Cookies.get('loginAsUser') ?? '';
        }

        if (loginData?.language) setUserLanguage(loginData?.language);
        if (isTokenBasedAuthentication && apiToken) {
            axios.defaults.headers.common['Authorization'] = `Token ${apiToken}`;
            dispatch(getCurrentUserData({}));
            dispatch(getChoices());
            getWaffleFlags();
        }

        if (token) {
            if (token) {
                axios.defaults.withCredentials = !isTokenBasedAuthentication;
                axios.defaults.headers.common['X-CSRFToken'] = token;
                dispatch(getCurrentUserData({}));
                dispatch(getChoices());
                getWaffleFlags();
            }
        } else {
            clearLocalStorageAfterLogout(false);
        }
    }, [dispatch, loginData, getWaffleFlags]);

    useEffect(() => {
        setGenericTableLimit('table-responsive');
        window.addEventListener('resize', () => {
            setGenericTableLimit('table-responsive');
        });

        const searchParams = new URLSearchParams(window.location.search);
        const verificationScheduleDate: any = searchParams.get('date');
        const verificationId: any = searchParams.get('verification_id');
        if (verificationScheduleDate)
            localStorage.setItem(
                'verificationScheduleDate',
                formatInTimeZone(new Date(verificationScheduleDate), 'UTC', 'Pp')
            );
        if (verificationId) localStorage.setItem('verificationId', verificationId?.toString());
    }, []);

    if (!multiTenantData) {
        return (
            <div className='d-flex h-100'>
                <IBILoader />
            </div>
        );
    }
    if (!localStorage.getItem('userLanguage') && !localStorage.getItem('accessToken')) {
        localStorage.setItem('userLanguage', 'en');
    }

    const getRouteComponent = () => {
        if (routeName === '/PublicDashboard' && canFeature(featureFlags.publicDashboard))
            return <PublicDashboardRoute />;
        if (localStorage.getItem('accessToken') || (isTokenBasedAuthentication && apiToken))
            return <ApplicationContainer />;
        return <GlobalRouterContainer />;
    };

    return (
        <>
            {getRouteComponent()}
            <LottieAnimation
                isOpen={showAppLoader}
                animationData={BomaLoader}
                title={''}
                isModalHeaderShown={false}
                style={customStyles}
                postModalClose={() => dispatch(setShowAppLoader(false))}
                noMargin
                animationModal
            />
        </>
    );
};

export default withRouter(App);
