import { put, all, takeLatest } from 'redux-saga/effects';
import { getApiData, patchApiData, putApiData } from '../../CommonUtilities';
import { api_prefix } from '../../config';
import {
    getPendingRequestAccessDataSuccess,
    getPreviousNotificationsMessage,
    getPreviousNotificationsMessageSuccess,
    markAllAsReadNotificationSuccess,
    markAsReadNotificationSuccess
} from '../actions';
import {
    GET_PREVIOUS_NOTIFICATIONS_MESSAGE,
    markAsReadNotificationAction,
    MARK_AS_READ_NOTIFICATION,
    GET_PENDING_REQUEST_ACCESS_DATA,
    getPreviousNotificationsMessageAction,
    markAllAsReadNotificationAction,
    MARK_ALL_AS_READ_NOTIFICATION,
    getPendingRequestAccessDataAction
} from '../actions/Notifications/types';

function* getPreviousNotificationsMessageSaga(action: getPreviousNotificationsMessageAction): any {
    const json = yield getApiData(action.url || `${api_prefix}/notifications/`, action);

    if (json && json.results) {
        yield put(getPreviousNotificationsMessageSuccess(json, action.appendData));
    }
}

export function* getPreviousNotificationsMessageDataSaga() {
    yield all([
        takeLatest(GET_PREVIOUS_NOTIFICATIONS_MESSAGE, getPreviousNotificationsMessageSaga)
    ]);
}

function* markAsReadNotificationSaga(action: markAsReadNotificationAction): any {
    const json = yield patchApiData(`${api_prefix}/notifications/`, action, '', false);

    if (!json.data) {
        yield put(markAsReadNotificationSuccess());
        yield put(getPreviousNotificationsMessage(action.notificationParams));
    }
}

export function* markAsReadNotificationSagas() {
    yield all([takeLatest(MARK_AS_READ_NOTIFICATION, markAsReadNotificationSaga)]);
}

function* markAllAsReadNotificationSaga(action: markAllAsReadNotificationAction): any {
    const json = yield putApiData(`${api_prefix}/users/mark_all_read/`, action, '', false);

    let params: any = {};
    if (!json.data) {
        yield put(markAllAsReadNotificationSuccess());
        yield put(getPreviousNotificationsMessage(params));
    }
}

export function* markAllAsReadNotificationSagas() {
    yield all([takeLatest(MARK_ALL_AS_READ_NOTIFICATION, markAllAsReadNotificationSaga)]);
}

function* getPendingRequestAccessDataSaga(action: getPendingRequestAccessDataAction): any {
    const json = yield getApiData(`${api_prefix}/request-access/pending_approvals/`, action);

    if (json?.results?.length >= 0) {
        yield put(getPendingRequestAccessDataSuccess(json, action.appendData));
    }
}

export function* getPendingRequestAccessDataSagas() {
    yield all([takeLatest(GET_PENDING_REQUEST_ACCESS_DATA, getPendingRequestAccessDataSaga)]);
}
