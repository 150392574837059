import PropTypes from 'prop-types';
import { FunctionComponent } from 'react';
import Modal from 'react-modal';
// @ts-ignore
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setIsModalOpen } from '../../../Store/actions/Common';
import { CrossICon, FlexColumn, FlexRow } from '../../styles';
import { ModalHeader, ModalHeadingPrimary, ModelBody } from './styles';

const WithModal = (Component: FunctionComponent) => {
    const HigherOrderComponent = (props: any) => {
        const styles = {
            overlay: {
                backgroundColor: 'rgba(114, 114, 114, 0.294)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 99
            },
            content: {
                left: 'unset',
                right: 'unset',
                top: 'unset',
                bottom: 'unset',
                border: 'none',
                padding: '0px',
                zIndex: '999',
                borderRadius: '10px',
                overflow: 'hidden',
                maxHeight: '80%',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '30%',
                maxWidth: '80%',
                backgroundColor: '#ffffff',
                position: 'relative'
            }
        };
        const dispatch = useDispatch();
        const closeModal = () => {
            if (props.postModalClose) {
                props.postModalClose();
                dispatch(setIsModalOpen(false));
            }
        };

        let modelStyles = _.merge({}, styles, props.style);

        const modalBodyClassName = () => {
            let className = '';
            if (!props.noMargin) className += 'p-3 ';
            if (props.animationModal) className += 'bg-transparent';
            return className;
        };

        return (
            <Modal
                isOpen={props.isOpen}
                onRequestClose={closeModal}
                id={'pop-up-modal'}
                style={modelStyles}
                shouldCloseOnOverlayClick={false}
                appElement={document.getElementById('root') as HTMLElement}
                shouldCloseOnEsc={true}
            >
                <ModalHeader className={props.isModalHeaderShown ? 'block' : 'd-none'}>
                    <FlexRow
                        className={
                            'flex-grow-1 justify-content-between align-items-lg-center py-3 text-truncate'
                        }
                    >
                        <div className='d-flex align-items-lg-center flex-column flex-lg-row w-100'>
                            {props.title ? (
                                <ModalHeadingPrimary className='title text-truncate mb-3 mb-lg-0'>
                                    {props.title}
                                </ModalHeadingPrimary>
                            ) : (
                                ''
                            )}
                            <div className='p-0 px-lg-3 col'>{props.children}</div>
                        </div>
                        <CrossICon
                            className='fas fa-times clickable pl-4 ml-auto'
                            onClick={() => closeModal()}
                        />
                    </FlexRow>
                </ModalHeader>

                <ModelBody className={modalBodyClassName()}>
                    <FlexColumn className={'overflow-auto h-100 flex-grow-1 compo-content'}>
                        <Component {...props} closeModal={closeModal} />
                    </FlexColumn>
                </ModelBody>
            </Modal>
        );
    };
    return HigherOrderComponent;
};

WithModal.propTypes = {
    postModalClose: PropTypes.func
};
export default WithModal;
