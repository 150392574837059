export const PUSH_TO_ENERGY_STAR = 'PUSH_TO_ENERGY_STAR';
export const GET_ENERGY_STAR_PROPERTY_DETAILS = 'GET_ENERGY_STAR_PROPERTY_DETAILS';
export const GET_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS = 'GET_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS';
export const CREATE_ENERGY_STAR_PROPERTY_DETAILS = 'CREATE_ENERGY_STAR_PROPERTY_DETAILS';
export const EDIT_ENERGY_STAR_PROPERTY_DETAILS = 'EDIT_ENERGY_STAR_PROPERTY_DETAILS';
export const DELETE_ENERGY_STAR_PROPERTY_DETAILS = 'DELETE_ENERGY_STAR_PROPERTY_DETAILS';
export const CREATE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS =
    'CREATE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS';
export const EDIT_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS =
    'EDIT_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS';
export const DELETE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS =
    'DELETE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS';
export const GET_METERS = 'GET_METERS';
export const GET_METERS_SUCCESS = 'GET_METERS_SUCCESS';
export const GET_METERS_FAILURE = 'GET_METERS_FAILURE';
export const CREATE_METER = 'CREATE_METER';
export const CREATE_METER_SUCCESS = 'CREATE_METER_SUCCESS';
export const CREATE_METER_FAILURE = 'CREATE_METER_FAILURE';
export const EDIT_METER = 'EDIT_METER';
export const EDIT_METER_SUCCESS = 'EDIT_METER_SUCCESS';
export const DELETE_METER = 'DELETE_METER';
export const DELETE_METER_SUCCESS = 'DELETE_METER_SUCCESS';
export const CREATE_WASTEMETER = 'CREATE_WASTEMETER';
export const CREATE_WASTEMETER_SUCCESS = 'CREATE_WASTEMETER_SUCCESS';
export const CREATE_WASTEMETER_FAILURE = 'CREATE_WASTEMETER_FAILURE';
export const GET_WASTEMETERS = 'GET_WASTEMETERS';
export const GET_WASTEMETERS_SUCCESS = 'GET_WASTEMETERS_SUCCESS';
export const EDIT_WASTEMETER = 'EDIT_WASTEMETER';
export const EDIT_WASTEMETER_SUCCESS = 'EDIT_WASTEMETER_SUCCESS';
export const DELETE_WASTEMETER = 'DELETE_WASTEMETER';
export const DELETE_WASTEMETER_SUCCESS = 'DELETE_WASTEMETER_SUCCESS';
export const CREATE_CONSUMPTION_DATA = 'CREATE_CONSUMPTION_DATA';
export const CREATE_CONSUMPTION_DATA_SUCCESS = 'CREATE_CONSUMPTION_DATA_SUCCESS';
export const GET_CONSUMPTION_DATA = 'GET_CONSUMPTION_DATA';
export const GET_CONSUMPTION_DATA_SUCCESS = 'GET_CONSUMPTION_DATA_SUCCESS';
export const GET_CONSUMPTION_DATA_FAILURE = 'GET_CONSUMPTION_DATA_FAILURE';
export const EDIT_CONSUMPTION_DATA = 'EDIT_CONSUMPTION_DATA';
export const EDIT_CONSUMPTION_DATA_SUCCESS = 'EDIT_CONSUMPTION_DATA_SUCCESS';
export const EDIT_CONSUMPTION_DATA_FAILURE = 'EDIT_CONSUMPTION_DATA_FAILURE';
export const DELETE_CONSUMPTION_DATA = 'DELETE_CONSUMPTION_DATA';
export const DELETE_CONSUMPTION_DATA_SUCCESS = 'DELETE_CONSUMPTION_DATA_SUCCESS';
export const CREATE_DELIVERY_DATA = 'CREATE_DELIVERY_DATA';
export const CREATE_DELIVERY_DATA_SUCCESS = 'CREATE_DELIVERY_DATA_SUCCESS';
export const GET_DELIVERY_DATA = 'GET_DELIVERY_DATA';
export const GET_DELIVERY_DATA_SUCCESS = 'GET_DELIVERY_DATA_SUCCESS';
export const EDIT_DELIVERY_DATA = 'EDIT_DELIVERY_DATA';
export const EDIT_DELIVERY_DATA_SUCCESS = 'EDIT_DELIVERY_DATA_SUCCESS';
export const DELETE_DELIVERY_DATA = 'DELETE_DELIVERY_DATA';
export const DELETE_DELIVERY_DATA_SUCCESS = 'DELETE_DELIVERY_DATA_SUCCESS';
export const CREATE_WASTE_CONSUMPTION_DATA = 'CREATE_WASTE_CONSUMPTION_DATA';
export const CREATE_WASTE_CONSUMPTION_DATA_SUCCESS = 'CREATE_WASTE_CONSUMPTION_DATA_SUCCESS';
export const GET_WASTE_CONSUMPTION_DATA = 'GET_WASTE_CONSUMPTION_DATA';
export const GET_WASTE_CONSUMPTION_DATA_SUCCESS = 'GET_WASTE_CONSUMPTION_DATA_SUCCESS';
export const GET_WASTE_CONSUMPTION_DATA_FAILURE = 'GET_WASTE_CONSUMPTION_DATA_FAILURE';
export const EDIT_WASTE_CONSUMPTION_DATA = 'EDIT_WASTE_CONSUMPTION_DATA';
export const EDIT_WASTE_CONSUMPTION_DATA_SUCCESS = 'EDIT_WASTE_CONSUMPTION_DATA_SUCCESS';
export const EDIT_WASTE_CONSUMPTION_DATA_FAILURE = 'EDIT_WASTE_CONSUMPTION_DATA_FAILURE';
export const DELETE_WASTE_CONSUMPTION_DATA = 'DELETE_WASTE_CONSUMPTION_DATA';
export const DELETE_WASTE_CONSUMPTION_DATA_SUCCESS = 'DELETE_WASTE_CONSUMPTION_DATA_SUCCESS';
export const GET_AGENCY_OPTIONS = 'GET_AGENCY_OPTIONS';
export const GET_AGENCY_OPTIONS_SUCCESS = 'GET_AGENCY_OPTIONS_SUCCESS';
export const GET_PROPERTY_OPTIONS = 'GET_PROPERTY_OPTIONS';
export const GET_PROPERTY_OPTIONS_SUCCESS = 'GET_PROPERTY_OPTIONS_SUCCESS';
export const GET_METER_OPTIONS = 'GET_METER_OPTIONS';
export const GET_METER_OPTIONS_SUCCESS = 'GET_METER_OPTIONS_SUCCESS';
export const GET_WASTEMETER_OPTIONS = 'GET_WASTEMETER_OPTIONS';
export const GET_WASTEMETER_OPTIONS_SUCCESS = 'GET_WASTEMETER_OPTIONS_SUCCESS';
export const GET_EGRID_OPTIONS = 'GET_EGRID_OPTIONS';
export const GET_EGRID_OPTIONS_SUCCESS = 'GET_EGRID_OPTIONS_SUCCESS';

export interface propertyOptionsType {
    irrigatedAreaUnitsOptions: any;
    grossFloorAreaUnitsOptions: any;
    stateOptions: any;
    constructionStatusOptions: any;
    federalOwnerStatusOptions: any;
    primaryFunctionsOptions: any;
    occupancyPercentageOptions: any;
}

export interface getMeterParamsType {
    property: any;
    meterType: string;
}

export interface getEnergyStarPropertyDetailsAction {
    type: typeof GET_ENERGY_STAR_PROPERTY_DETAILS;
    id: number;
}

export interface getEnergyStarPropertyDetailsRecievedAction {
    type: typeof GET_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS;
    data: any;
}

export interface createEnergyStarPropertyDetailsAction {
    type: typeof CREATE_ENERGY_STAR_PROPERTY_DETAILS;
    params: any;
}

export interface editEnergyStarPropertyDetailsAction {
    type: typeof EDIT_ENERGY_STAR_PROPERTY_DETAILS;
    id: number;
    params: any;
}

export interface deleteEnergyStarPropertyDetailsAction {
    type: typeof DELETE_ENERGY_STAR_PROPERTY_DETAILS;
    id: number;
}

export interface getMetersAction {
    type: typeof GET_METERS;
    id: number;
    meterType: string;
}

export interface metersRecievedAction {
    type: typeof GET_METERS_SUCCESS;
    data: any;
}

export interface getMeterFailureAction {
    type: typeof GET_METERS_FAILURE;
}

export interface createMeterAction {
    type: typeof CREATE_METER;
    params: any;
    getMeterParams: getMeterParamsType;
}

export interface editMeterAction {
    type: typeof EDIT_METER;
    payload: any;
    id: number;
}

export interface deleteMeterAction {
    type: typeof DELETE_METER;
    id: number;
}

export interface createWastemeterAction {
    type: typeof CREATE_WASTEMETER;
    params: any;
    energyStarPropertyID: number;
}

export interface getWastemetersAction {
    type: typeof GET_WASTEMETERS;
    id: number;
}

export interface getWastemetersRecievedAction {
    type: typeof GET_WASTEMETERS_SUCCESS;
    data: any;
}

export interface editWastemeterAction {
    type: typeof EDIT_WASTEMETER;
    id: number;
    params: any;
}

export interface deleteWastemeterAction {
    type: typeof DELETE_WASTEMETER;
    id: number;
}

export interface createConsumptionDataAction {
    type: typeof CREATE_CONSUMPTION_DATA;
    params: any;
    meters: any;
}

export interface getConsumptionDataAction {
    type: typeof GET_CONSUMPTION_DATA;
    idArray: Array<number>;
}

export interface getConsumptionRecievedDataAction {
    type: typeof GET_CONSUMPTION_DATA_SUCCESS;
    data: any;
}

export interface getConsumptionFailureDataAction {
    type: typeof GET_CONSUMPTION_DATA_FAILURE;
}

export interface editConsumptionDataAction {
    type: typeof EDIT_CONSUMPTION_DATA;
    id: number;
    params: any;
    meters: any;
}

export interface deleteConsumptionDataAction {
    type: typeof DELETE_CONSUMPTION_DATA;
    id: number;
}

export interface createDeliveryDataAction {
    type: typeof CREATE_DELIVERY_DATA;
    params: any;
    meters: any;
}

export interface getDeliveryDataAction {
    type: typeof GET_DELIVERY_DATA;
    idArray: Array<number>;
}

export interface getDeliveryDataRecievedAction {
    type: typeof GET_DELIVERY_DATA_SUCCESS;
    data: any;
}

export interface editDeliveryDataAction {
    type: typeof EDIT_DELIVERY_DATA;
    id: number;
    params: any;
    meters: any;
}

export interface deleteDeliveryDataAction {
    type: typeof DELETE_DELIVERY_DATA;
    id: number;
}

export interface createWasteConsumptionDataAction {
    type: typeof CREATE_WASTE_CONSUMPTION_DATA;
    params: any;
    wasteMeters: any;
}

export interface getWasteConsumptionDataAction {
    type: typeof GET_WASTE_CONSUMPTION_DATA;
    idArray: Array<number>;
}

export interface getWasteConsumptionDataRecievedAction {
    type: typeof GET_WASTE_CONSUMPTION_DATA_SUCCESS;
    data: any;
}

export interface getWasteConsumptionDataFailureAction {
    type: typeof GET_WASTE_CONSUMPTION_DATA_FAILURE;
}

export interface editWasteConsumptionDataAction {
    type: typeof EDIT_WASTE_CONSUMPTION_DATA;
    id: number;
    params: any;
    wasteMeters: any;
}

export interface deleteWasteConsumptionDataAction {
    type: typeof DELETE_WASTE_CONSUMPTION_DATA;
    id: number;
}

export interface getEGridOptionsAction {
    type: typeof GET_EGRID_OPTIONS;
}

export interface eGridOptionsRecievedAction {
    type: typeof GET_EGRID_OPTIONS_SUCCESS;
    data: any;
}

export interface getPropertyOptionsAction {
    type: typeof GET_PROPERTY_OPTIONS;
}

export interface propertyOptionsRecievedAction {
    type: typeof GET_PROPERTY_OPTIONS_SUCCESS;
    data: any;
}

export interface getMeterOptionsAction {
    type: typeof GET_METER_OPTIONS;
}

export interface meterOptionsRecievedAction {
    type: typeof GET_METER_OPTIONS_SUCCESS;
    data: any;
}

export interface getWasteMeterOptionsAction {
    type: typeof GET_WASTEMETER_OPTIONS;
}

export interface wasteMeterOptionsRecievedAction {
    type: typeof GET_WASTEMETER_OPTIONS_SUCCESS;
    data: any;
}

export interface createMeterSuccess {
    type: typeof CREATE_METER_SUCCESS;
}

export interface createMeterFailure {
    type: typeof CREATE_METER_FAILURE;
}

export interface editMeterSuccess {
    type: typeof EDIT_METER_SUCCESS;
}

export interface deleteMeterSuccess {
    type: typeof DELETE_METER_SUCCESS;
}

export interface createWastemeterSuccess {
    type: typeof CREATE_WASTEMETER_SUCCESS;
}

export interface createWastemeterFailure {
    type: typeof CREATE_WASTEMETER_FAILURE;
}

export interface editWastemeterSuccess {
    type: typeof EDIT_WASTEMETER_SUCCESS;
}

export interface deleteWastemeterSuccess {
    type: typeof DELETE_WASTEMETER_SUCCESS;
}

export interface createConsumptionDataSuccess {
    type: typeof CREATE_CONSUMPTION_DATA_SUCCESS;
}

export interface editConsumptionDataSuccess {
    type: typeof EDIT_CONSUMPTION_DATA_SUCCESS;
}

export interface editConsumptionDataFailure {
    type: typeof EDIT_CONSUMPTION_DATA_FAILURE;
}

export interface deleteConsumptionDataSuccess {
    type: typeof DELETE_CONSUMPTION_DATA_SUCCESS;
}

export interface createDeliveryDataSuccess {
    type: typeof CREATE_DELIVERY_DATA_SUCCESS;
}

export interface editDeliveryDataSuccess {
    type: typeof EDIT_DELIVERY_DATA_SUCCESS;
}

export interface deleteDeliveryDataSuccess {
    type: typeof DELETE_DELIVERY_DATA_SUCCESS;
}

export interface createWasteConsumptionDataSuccess {
    type: typeof CREATE_WASTE_CONSUMPTION_DATA_SUCCESS;
}

export interface editWasteConsumptionDataSuccess {
    type: typeof EDIT_WASTE_CONSUMPTION_DATA_SUCCESS;
}

export interface editWasteConsumptionDataFailure {
    type: typeof EDIT_WASTE_CONSUMPTION_DATA_FAILURE;
}
export interface deleteWasteConsumptionDataSuccess {
    type: typeof DELETE_WASTE_CONSUMPTION_DATA_SUCCESS;
}

export interface createEnergyStarPropertyDetailsSuccess {
    type: typeof CREATE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS;
}
export interface editEnergyStarPropertyDetailsSuccess {
    type: typeof EDIT_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS;
}
export interface deleteEnergyStarPropertyDetailsSuccess {
    type: typeof DELETE_ENERGY_STAR_PROPERTY_DETAILS_SUCCESS;
}

export type BenchmarkingActionTypes =
    | getEnergyStarPropertyDetailsAction
    | getEnergyStarPropertyDetailsRecievedAction
    | createEnergyStarPropertyDetailsAction
    | editEnergyStarPropertyDetailsAction
    | deleteEnergyStarPropertyDetailsAction
    | getMetersAction
    | metersRecievedAction
    | getMeterFailureAction
    | createMeterAction
    | createMeterFailure
    | editMeterAction
    | deleteMeterAction
    | createWastemeterAction
    | getWastemetersAction
    | getWastemetersRecievedAction
    | editWastemeterAction
    | deleteWastemeterAction
    | createConsumptionDataAction
    | getConsumptionDataAction
    | getConsumptionRecievedDataAction
    | getConsumptionFailureDataAction
    | editConsumptionDataAction
    | editConsumptionDataFailure
    | deleteConsumptionDataAction
    | createDeliveryDataAction
    | getDeliveryDataAction
    | getDeliveryDataRecievedAction
    | editDeliveryDataAction
    | deleteDeliveryDataAction
    | createWasteConsumptionDataAction
    | getWasteConsumptionDataAction
    | getWasteConsumptionDataRecievedAction
    | editWasteConsumptionDataAction
    | deleteWasteConsumptionDataAction
    | getEGridOptionsAction
    | eGridOptionsRecievedAction
    | getPropertyOptionsAction
    | propertyOptionsRecievedAction
    | getMeterOptionsAction
    | meterOptionsRecievedAction
    | getWasteMeterOptionsAction
    | wasteMeterOptionsRecievedAction
    | createMeterSuccess
    | editMeterSuccess
    | deleteMeterSuccess
    | createWastemeterSuccess
    | createWastemeterFailure
    | editWastemeterSuccess
    | deleteWastemeterSuccess
    | createConsumptionDataSuccess
    | editConsumptionDataSuccess
    | deleteConsumptionDataSuccess
    | createDeliveryDataSuccess
    | editDeliveryDataSuccess
    | deleteDeliveryDataSuccess
    | createWasteConsumptionDataSuccess
    | editWasteConsumptionDataSuccess
    | editWasteConsumptionDataFailure
    | deleteWasteConsumptionDataSuccess
    | createEnergyStarPropertyDetailsSuccess
    | editEnergyStarPropertyDetailsSuccess
    | deleteEnergyStarPropertyDetailsSuccess
    | getWasteConsumptionDataFailureAction;

export interface BenchmarkingState {
    energyStarPropertyDetails: any;
    isFetchingEnergyStarPropertyDetails: boolean;
    energyConsumed: any;
    isFetchingEnergyConsumed: boolean;
    consumptionData: any;
    isFetchingConsumptionData: boolean;
    deliveryData: any;
    isFetchingDeliveryData: boolean;
    wasteConsumptionData: any;
    isFetchingWasteConsumptionData: boolean;
    energyProduced: any;
    isFetchingEnergyProduced: boolean;
    water: any;
    isFetchingWater: boolean;
    wasteMeters: any;
    isFetchingWasteMeters: boolean;
    eGridOptions: any;
    isFetchingEGridOptions: boolean;
    propertyOptions: any;
    isFetchingPropertyOptions: boolean;
    meterOptions: any;
    isFetchingMeterOptions: boolean;
    wasteMeterOptions: any;
    isFetchingWasteMeterOptions: boolean;
    isCreatingMeter: boolean;
    isEditingMeter: boolean;
    isDeletingMeter: boolean;
    isCreatingWastemeter: boolean;
    isEditingWastemeter: boolean;
    isDeletingWastemeter: boolean;
    isCreatingConsumptionData: boolean;
    isEditingConsumptionData: boolean;
    isDeletingConsumptionData: boolean;
    isCreatingDeliveryData: boolean;
    isEditingDeliveryData: boolean;
    isDeletingDeliveryData: boolean;
    isCreatingWasteConsumptionData: boolean;
    isEditingWasteConsumptionData: boolean;
    isDeletingWasteConsumptionData: boolean;
    iscreatingEnergyStarPropertyDetails: boolean;
    iseditingEnergyStarPropertyDetails: boolean;
    isdeletingEnergyStarPropertyDetails: boolean;
}
