import axios from 'axios';
import i18n from 'i18next';
import { put, takeLatest } from 'redux-saga/effects';
import { getApiData, onErrorCallback, postApiData, putApiData } from '../../CommonUtilities';
import { api_prefix } from '../../config';
import { constantStrings } from '../../constantStrings';
import {
    choicesReceived,
    getGroupsListDataRecieved,
    getRegionsListData,
    getRegionsListDataRecieved,
    getSubRegionsListData,
    getSubRegionsListDataRecieved,
    getUserRegionsAndSubRegionsListDataRecieved
} from '../actions/Common/index';
import {
    CommonAction,
    CREATE_REGION,
    CREATE_SUB_REGION,
    createRegionAction,
    createSubRegionAction,
    EDIT_REGION,
    EDIT_SUB_REGION,
    editRegionAction,
    GET_CHOICES,
    GET_GROUPS_LIST,
    GET_REGIONS_LIST,
    GET_SUB_REGIONS_LIST,
    GET_USER_REGIONS_AND_SUB_REGIONS_LIST,
    getChoicesAction,
    GetUserRegionsAndSubRegionsAction
} from '../actions/Common/types';

function* groupsListSaga(action: CommonAction): any {
    const json = yield getApiData(`${api_prefix}/groups/`, action);
    if (json.results) {
        yield put(getGroupsListDataRecieved(json));
    }
}

export function* groupsSaga() {
    yield takeLatest(GET_GROUPS_LIST, groupsListSaga);
}

function* regionsListSaga(action: CommonAction): any {
    const json = yield getApiData(`${api_prefix}/regions/`, action);
    if (json.results) {
        yield put(getRegionsListDataRecieved(json, action.shouldAppend));
    }
}

export function* regionsSaga() {
    yield takeLatest(GET_REGIONS_LIST, regionsListSaga);
}

function* subRegionsListSaga(action: CommonAction): any {
    const json = yield getApiData(`${api_prefix}/subregions/`, action);
    if (json.results) {
        yield put(getSubRegionsListDataRecieved(json, action.shouldAppend));
    }
}

export function* subRegionsSaga() {
    yield takeLatest(GET_SUB_REGIONS_LIST, subRegionsListSaga);
}

function* userRegionsAndSubRegionsListSaga(action: GetUserRegionsAndSubRegionsAction): any {
    if (action.id) {
        const json = yield getApiData(
            `${api_prefix}/users/${action.id}/user_regions_subregions/`,
            action
        );
        if (!json.data) {
            yield put(getUserRegionsAndSubRegionsListDataRecieved(json));
        }
    }
}

export function* userSubRegionsSaga() {
    yield takeLatest(GET_USER_REGIONS_AND_SUB_REGIONS_LIST, userRegionsAndSubRegionsListSaga);
}

function* createRegion(action: createRegionAction): any {
    const json = yield postApiData(
        `${api_prefix}/regions/`,
        action,
        i18n.t(constantStrings.countryAdded),
        true
    );
    let params: any = {
        ordering: '-id'
    };
    if (json.name) {
        yield put(getRegionsListData(params));
    }
}

export function* createRegionSaga() {
    yield takeLatest(CREATE_REGION, createRegion);
}

function* editRegion(action: editRegionAction): any {
    const json = yield putApiData(
        `${api_prefix}/regions/${action.id}/`,
        action,
        i18n.t(constantStrings.countryUpdated),
        true
    );
    let params: any = {
        ordering: '-id'
    };
    if (json.name) {
        yield put(getRegionsListData(params));
    }
}

export function* editRegionSaga() {
    yield takeLatest(EDIT_REGION, editRegion);
}

function* createSubRegion(action: createSubRegionAction): any {
    const json = yield postApiData(
        `${api_prefix}/subregions/`,
        action,
        i18n.t(constantStrings.localAssociationAdded),
        true
    );
    let params: any = {
        ordering: '-id'
    };
    if (json.name) {
        yield put(getSubRegionsListData(params));
    }
}

export function* createSubRegionSaga() {
    yield takeLatest(CREATE_SUB_REGION, createSubRegion);
}

function* editSubRegion(action: editRegionAction): any {
    const json = yield putApiData(
        `${api_prefix}/subregions/${action.id}/`,
        action,
        i18n.t(constantStrings.localAssociationUpdated),
        true
    );
    let params: any = {
        ordering: '-id'
    };
    if (json.name) {
        yield put(getSubRegionsListData(params));
    }
}

export function* editSubRegionSaga() {
    yield takeLatest(EDIT_SUB_REGION, editSubRegion);
}

function* fetchChoices(action: getChoicesAction): any {
    const choicesData = yield axios
        .options(`${api_prefix}/users/`)
        .then((res) => res.data?.actions?.POST)
        .catch(onErrorCallback);
    yield put(choicesReceived(choicesData));
}

export function* getChoicesSaga() {
    yield takeLatest(GET_CHOICES, fetchChoices);
}
