import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FlexRow } from '../../styles';
import GenericHeadingText from '../../UIComponents/GenericHeading';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { validateYupSchema } from '../../../CommonUtilities';
import i18n from 'i18next';
import FormSelect from '../../DesignSystem/FormElements/FormSelect';
import Button from '../../DesignSystem/Button';
import { FiltersContainer } from '../styles';
import { constantStrings } from '../../../constantStrings';
import { ValueLabelType, buildingDataTypes, filtersFormDataType } from '../types';
import { FilterImg, Funnel } from '../../../assets/index';

const getOptions = (optionsData: any = []): ValueLabelType[] => {
    return optionsData.map((option: string) => ({
        value: option,
        label: i18n.t(option)
    }));
};

const validationSchema = yup.object().shape({
    language: validateYupSchema('select', i18n.t(constantStrings.language), false)
});

const defaultValues = {
    assetType: [{ value: 'Select All', label: i18n.t(constantStrings.selectAll) }],
    country: [{ value: 'Select All', label: i18n.t(constantStrings.selectAll) }],
    managementCompany: [{ value: 'Select All', label: i18n.t(constantStrings.selectAll) }],
    certificate: [{ value: 'Select All', label: i18n.t(constantStrings.selectAll) }],
    certificationLevel: [{ value: 'Select All', label: i18n.t(constantStrings.selectAll) }]
};

const FiltersComponent = ({ allBuildingsData, setFilteredBuildingData }: any) => {
    const { t } = useTranslation();
    const [showFilters, setShowFilters] = useState(false);

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const { reset } = methods;
    const certificationType =
        allBuildingsData?.certifications
            ?.map((certificate: any) => ({
                label: certificate.name,
                value: certificate.name
            }))
            ?.sort((current: any, prev: any) => current.label.localeCompare(prev.label)) || [];

    const countryList =
        allBuildingsData?.regions
            ?.map((region: any) => ({
                label: region.name,
                value: region.name
            }))
            ?.sort((current: any, prev: any) => current.label.localeCompare(prev.label)) || [];

    const managementCompanyList =
        allBuildingsData?.propertiesManagementCompanies
            ?.map((company: any) => ({
                label: company.name,
                value: company.name
            }))
            ?.sort((current: any, prev: any) => current.label.localeCompare(prev.label)) || [];

    const certificationLevelList = getOptions(allBuildingsData.certificationLevels);
    const assetTypeList = getOptions(allBuildingsData.assets);

    const listObject: any = {
        assetType: assetTypeList,
        country: countryList,
        managementCompany: managementCompanyList,
        certificate: certificationType,
        certificationLevel: certificationLevelList
    };

    const filtersObject = [
        {
            name: 'assetType',
            options: assetTypeList,
            placeholder: i18n.t(constantStrings.Select),
            displayName: i18n.t(constantStrings.assetType),
            isMulti: true
        },
        {
            name: 'country',
            options: countryList,
            placeholder: i18n.t(constantStrings.Select),
            displayName: i18n.t(constantStrings.country),
            isMulti: true
        },
        {
            name: 'managementCompany',
            options: managementCompanyList,
            placeholder: i18n.t(constantStrings.Select),
            displayName: i18n.t(constantStrings.managementCompany),
            isMulti: true
        },
        {
            name: 'certificate',
            options: certificationType,
            placeholder: i18n.t(constantStrings.Select),
            displayName: i18n.t(constantStrings.certificate),
            isMulti: true
        },
        {
            name: 'certificationLevel',
            options: certificationLevelList,
            placeholder: i18n.t(constantStrings.Select),
            displayName: i18n.t(constantStrings.certificationLevel),
            isMulti: true
        }
    ];

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setShowFilters(true);
            } else {
                setShowFilters(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleReset = () => {
        reset(defaultValues);

        const filteredData = allBuildingsData.buildingData.filter(
            (building: buildingDataTypes) =>
                countryList?.map((data: any) => data.value).includes(building.subRegionName) &&
                assetTypeList?.map((data: any) => data.value).includes(building.assetTypeName) &&
                managementCompanyList
                    ?.map((data: any) => data.value)
                    .includes(building.managementCompanyName) &&
                certificationType
                    ?.map((data: any) => data.value)
                    .includes(building.certificationName) &&
                certificationLevelList
                    ?.map((data) => data.value)
                    .includes(building.certificationLevel)
        );
        setFilteredBuildingData((prevData: any) => ({
            ...prevData,
            buildingData: filteredData
        }));
    };

    const getFilterData = (filterList: ValueLabelType[], listType: string) => {
        if (filterList?.some((data) => data.value === 'Select All'))
            return listObject[listType].map((data: any) => data.value);
        return filterList?.map((data) => data.value) || [];
    };

    const onSubmit = (formData: filtersFormDataType) => {
        const countryFilter = getFilterData(formData.country, 'country');
        const assetTypeFilter = getFilterData(formData.assetType, 'assetType');
        const pmCompanyFilter = getFilterData(formData.managementCompany, 'managementCompany');
        const certificateTypeFilter = getFilterData(formData.certificate, 'certificate');
        const certificateLevelFilter = getFilterData(
            formData.certificationLevel,
            'certificationLevel'
        );

        const filteredData = allBuildingsData.buildingData.filter(
            (building: buildingDataTypes) =>
                (!countryFilter?.length || countryFilter?.includes(building.country)) &&
                (!assetTypeFilter?.length || assetTypeFilter?.includes(building.assetTypeName)) &&
                (!pmCompanyFilter?.length ||
                    pmCompanyFilter?.includes(building.managementCompanyName)) &&
                (!certificateTypeFilter?.length ||
                    certificateTypeFilter?.includes(building.certificationName)) &&
                (!certificateLevelFilter?.length ||
                    certificateLevelFilter?.includes(building.certificationLevel))
        );
        setFilteredBuildingData((prevData: any) => ({
            ...prevData,
            buildingData: filteredData
        }));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FiltersContainer className='p-2 ps-4 d-flex flex-column flex-lg-row'>
                    <div className='d-flex w-100 align-items-center mb-2 justify-content-between'>
                        <GenericHeadingText
                            title={t(constantStrings.bbCertifiedBuildings)}
                            className='text-nowrap'
                        />
                        <div className='d-flex'>
                            <img
                                src={showFilters ? Funnel : FilterImg}
                                alt={t(constantStrings.filterIcon) || ''}
                                className='d-lg-none ms-auto me-2 cursor-pointer'
                                onClick={() => setShowFilters(!showFilters)}
                            />

                            <div className='d-lg-none d-none d-md-flex'>
                                {t(constantStrings.filters)}
                            </div>
                        </div>
                    </div>
                    <FlexRow
                        className={`col-lg-9 col-12 align-items-center flex-wrap ${
                            !showFilters ? 'd-none' : ''
                        }`}
                    >
                        {filtersObject.map((filter) => (
                            <FormSelect
                                className='filter-item col-12 col-lg-2 col-md-6'
                                options={filter.options}
                                displayName={filter.displayName}
                                name={filter.name}
                                placeholder={filter.placeholder}
                                isMulti={filter.isMulti}
                                maxHeight='38px'
                                enableSelectAll
                                key={filter.name}
                            />
                        ))}
                        <div>
                            <Button
                                variant='primary'
                                title={t(constantStrings.apply)}
                                type='submit'
                                className='mt-2'
                            >
                                {t(constantStrings.apply)}
                            </Button>
                            <Button
                                variant='secondary'
                                title={t(constantStrings.reset)}
                                className='mt-2'
                                onClick={handleReset}
                            >
                                {t(constantStrings.reset)}
                            </Button>
                        </div>
                    </FlexRow>
                </FiltersContainer>
            </form>
        </FormProvider>
    );
};

export default FiltersComponent;
