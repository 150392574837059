export const lineChartColors = [
    '#388968',
    '#546AAA',
    '#A65A88',
    '#8884d8',
    '#F0BA61',
    '#DFACD2',
    '#A9C4E5',
    '#F49C43',
    '#BA7FB8',
    '#AD7E4B'
];

export const barChartColors = [
    '#546AAA',
    '#388968',
    '#A65A88',
    '#8884d8',
    '#F0BA61',
    '#DFACD2',
    '#A9C4E5',
    '#F49C43',
    '#BA7FB8',
    '#AD7E4B',
    '#A1A3A6'
];

export const pieChartColors = [
    '#A9C4E5',
    '#546AAA',
    '#388968',
    '#A65A88',
    '#8884d8',
    '#F0BA61',
    '#DFACD2',
    '#F49C43',
    '#BA7FB8',
    '#AD7E4B'
];
