import { theme } from '../../../theme';
import { variant as buttonVariants } from './buttonVariants';

// Style Parameters to be applied when we hover over a button
export type HoverStyleReturnType = {
    backgroundColor: string;
    color: string;
    border: string;
    borderColor: string;
    boxShadow: string;
};

export type ActiveStyleReturnType = {
    backgroundColor: string;
    color: string;
    border: string;
    borderColor: string;
    boxShadow: string;
};

// Size of button
export type size = 'sm' | 'lg';

// Style Parameters to be applied on button reuses the return types of hover
export interface StyleReturnType extends HoverStyleReturnType {
    minHeight: string;
    height: number;
    minWidth: string;
    opacity: number;
    pointerEvents: string;
}

export interface StyleReturnType extends ActiveStyleReturnType {
    height: number;
    minHeight: string;
    minWidth: string;
    opacity: number;
    pointerEvents: string;
}

export const defaultStyle = {
    backgroundColor: theme.primary.THEME,
    color: theme.standard.WHITE,
    height: 44,
    minHeight: '38px',
    minWidth: '90px',
    opacity: 1,
    pointerEvents: 'all',
    border: '1px solid',
    borderColor: theme.primary.THEME,
    boxShadow: ' 0 1px 3px rgba(0, 0, 0, 0.08)'
};

// This returns general style for button as a dictionary
export const getStyle = (
    variant: buttonVariants,
    size: size | undefined,
    disabled: boolean | undefined
): StyleReturnType => {
    const additionalStyle = { ...defaultStyle };

    if (disabled === true) {
        additionalStyle.opacity = 0.5;
        additionalStyle.pointerEvents = 'none';
        additionalStyle.backgroundColor = theme.primary.THEME;
        additionalStyle.color = '#CCCCCC';
    }

    if (size === 'lg') {
        additionalStyle.height = 40;
    }

    if (size === 'sm') {
        additionalStyle.height = 32;
    }

    const style: { [variant: string]: StyleReturnType } = {
        primary: {
            ...additionalStyle,
            backgroundColor: theme.primary.THEME
        },
        secondary: {
            ...additionalStyle,
            backgroundColor: theme.standard.WHITE,
            color: theme.primary.THEME
        },
        fileupload: {
            ...additionalStyle,
            backgroundColor: theme.primary.THEME,
            color: theme.standard.WHITE,
            minWidth: '80px'
        },
        transparentPrimary: {
            ...additionalStyle,
            color: theme.primary.THEME,
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none'
        },
        toaster: {
            ...additionalStyle,
            backgroundColor: theme.primary.SECONDARY_THEME,
            color: theme.standard.WHITE,
            borderColor: theme.standard.WHITE,
            minWidth: '50px',
            minHeight: '30px'
        },
        danger: {
            ...additionalStyle,
            backgroundColor: theme.standard.WHITE,
            borderColor: theme.danger.DARK_DANGER,
            color: theme.danger.DARK_DANGER,
            minWidth: '80px'
        },
        dangerPrimary: {
            ...additionalStyle,
            backgroundColor: theme.danger.DARK_DANGER,
            borderColor: theme.standard.WHITE,
            color: theme.standard.WHITE,
            minWidth: '80px'
        },
        muted: {
            ...additionalStyle,
            backgroundColor: theme.standard.LIGHT_BLACK,
            borderColor: theme.standard.LIGHT_BLACK,
            color: theme.standard.DARK_BLACK
        }
    };

    return style[variant];
};

// This returns hover style for button as a dictionary
export const getHoverStyle = (variant: buttonVariants): HoverStyleReturnType => {
    const additionalStyle = {
        border: '1px solid',
        borderColor: theme.primary.THEME,
        boxShadow: ' 0 4px 4px rgba(0, 0, 0, 0.09)'
    };
    const style: { [variant: string]: HoverStyleReturnType } = {
        primary: {
            ...additionalStyle,
            backgroundColor: theme.info.DARK_INFO,
            color: theme.standard.WHITE,
            border: 'none'
        },
        secondary: {
            ...additionalStyle,
            backgroundColor: theme.standard.WHITE,
            color: theme.info.DARK_INFO,
            borderColor: theme.info.DARK_INFO
        },
        fileupload: {
            ...additionalStyle,
            backgroundColor: theme.primary.THEME,
            color: theme.standard.WHITE,
            border: 'none'
        },
        toaster: {
            ...additionalStyle,
            backgroundColor: theme.primary.SECONDARY_THEME,
            color: theme.standard.WHITE,
            borderColor: theme.standard.WHITE
        },
        danger: {
            ...additionalStyle,
            backgroundColor: theme.standard.WHITE,
            color: theme.danger.DARK_DANGER,
            borderColor: theme.danger.DARK_DANGER
        },
        transparentPrimary: {
            ...additionalStyle,
            backgroundColor: 'transparent',
            color: theme.primary.THEME,
            border: 'none'
        }
    };

    return style[variant];
};

// This returns active/focus style for button as a dictionary
export const getActiveStyle = (variant: buttonVariants): ActiveStyleReturnType => {
    const additionalStyle = {
        border: '1px solid',
        borderColor: theme.primary.THEME,
        boxShadow: ' 0 4px 4px rgba(0, 0, 0, 0.09)'
    };
    const style: { [variant: string]: ActiveStyleReturnType } = {
        primary: {
            ...additionalStyle,
            backgroundColor: theme.info.LIGHT_INFO,
            color: theme.standard.WHITE,
            border: 'none'
        },
        secondary: {
            ...additionalStyle,

            backgroundColor: theme.standard.WHITE,
            color: theme.info.LIGHT_INFO,
            borderColor: theme.info.LIGHT_INFO
        },
        fileupload: {
            ...additionalStyle,

            backgroundColor: theme.primary.THEME,
            color: theme.standard.WHITE,
            border: 'none'
        },
        toaster: {
            ...additionalStyle,

            backgroundColor: theme.primary.SECONDARY_THEME,
            color: theme.standard.WHITE,
            borderColor: theme.standard.WHITE
        },
        danger: {
            ...additionalStyle,

            backgroundColor: theme.standard.WHITE,
            color: theme.danger.DARK_DANGER,
            borderColor: theme.danger.DARK_DANGER
        },
        transparentPrimary: {
            ...additionalStyle,

            backgroundColor: 'transparent',
            color: theme.primary.THEME,
            border: 'none',
            boxShadow: 'none'
        }
    };

    return style[variant];
};
