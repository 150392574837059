export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET = 'RESET';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const GET_MULTITENANT_CONFIG = 'GET_MULTITENANT_CONFIG';
export const GET_MULTITENANT_CONFIG_RECEIVED = 'GET_MULTITENANT_CONFIG_RECEIVED';

export interface LoginInterFace {
    email: string;
    password: string;
}

export type loginParams = LoginInterFace;

export type forgotPasswordParams = {
    user_email: string;
};

export type resetParams = {
    password: string;
    confirm_password: string;
};

export type loginJson = {
    access: string;
    refresh: string;
};

export interface loginAction {
    type: typeof LOGIN;
    params: loginParams;
    history: any;
}

export interface loginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    data: loginJson;
}
export interface loginFailureAction {
    type: typeof LOGIN_FAILURE;
    data: loginJson;
}

export interface logoutAction {
    type: typeof LOGOUT;
}

export interface logoutSuccessAction {
    type: typeof LOGOUT_SUCCESS;
}
export interface logoutFailureAction {
    type: typeof LOGOUT_FAILURE;
}

export interface forgotPasswordAction {
    type: typeof FORGOT_PASSWORD;
    params: forgotPasswordParams;
}

export interface forgotPasswordSuccessAction {
    type: typeof FORGOT_PASSWORD_SUCCESS;
}

export interface forgotPasswordFailureAction {
    type: typeof FORGOT_PASSWORD_FAILURE;
}
export interface resetAction {
    type: typeof RESET;
    params: resetParams;
}
export interface resetSuccessAction {
    type: typeof RESET_SUCCESS;
}

export interface updatePasswordAction {
    type: typeof UPDATE_PASSWORD;
    params: any;
}

export interface getMultiTenantConfigAction {
    type: typeof GET_MULTITENANT_CONFIG;
}

export interface getMultiTenantConfigSuccessAction {
    type: typeof GET_MULTITENANT_CONFIG_RECEIVED;
    payload: any;
}

export type LoginActionTypes =
    | loginAction
    | loginSuccessAction
    | logoutAction
    | logoutSuccessAction
    | logoutFailureAction
    | loginFailureAction
    | forgotPasswordAction
    | forgotPasswordSuccessAction
    | forgotPasswordFailureAction
    | resetAction
    | resetSuccessAction
    | updatePasswordAction
    | getMultiTenantConfigAction
    | getMultiTenantConfigSuccessAction;

export interface LoginState {
    isLoggedIn: boolean;
    loginData: loginJson | null;
    isLoggedOut: boolean;
    isForgotPasswordRequestSent: boolean;
    isPasswordReset: boolean;
    multiTenantData: any;
}
