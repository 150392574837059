import {
    GET_INVOICE_DETAILS,
    GET_INVOICE_DETAILS_RECEIVED,
    InvoiceActionTypes,
    InvoiceState
} from '../actions/Invoice/types';

const initialState: InvoiceState = {
    invoiceDetailsData: null,
    isFetchingInvoiceDetails: false
};

export const Invoice = (state = initialState, action: InvoiceActionTypes): InvoiceState => {
    switch (action.type) {
        case GET_INVOICE_DETAILS: {
            return {
                ...state,
                isFetchingInvoiceDetails: true
            };
        }
        case GET_INVOICE_DETAILS_RECEIVED: {
            return {
                ...state,
                invoiceDetailsData: action.payload,
                isFetchingInvoiceDetails: false
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default Invoice;
