import {
    certificationActionTypes,
    GET_CERTIFICATION_TYPES_LIST,
    GET_CERTIFICATION_TYPES_LIST_SUCCESS,
    CREATE_CERTIFICATION_TYPE,
    CREATE_CERTIFICATION_TYPE_SUCCESS,
    CREATE_CERTIFICATION_LEVEL_DATA,
    CREATE_CERTIFICATION_LEVEL_DATA_SUCCESS,
    GET_CERTIFICATION_LEVEL_LIST,
    GET_CERTIFICATION_LEVEL_LIST_SUCCESS,
    GET_CERTIFICATION_LEVEL_OPTIONS,
    GET_CERTIFICATION_LEVEL_OPTIONS_SUCCESS,
    EDIT_CERTIFICATION_LEVEL_DATA,
    EDIT_CERTIFICATION_LEVEL_DATA_SUCCESS,
    GET_CERTIFICATION_BUILDINGS_LIST,
    GET_CERTIFICATION_BUILDINGS_LIST_SUCCESS
} from './types';

export const createCertificationType = (params: any): certificationActionTypes => ({
    type: CREATE_CERTIFICATION_TYPE,
    params
});

export const createCertificationTypeSuccess = (): certificationActionTypes => ({
    type: CREATE_CERTIFICATION_TYPE_SUCCESS
});

export const getCertificationTypesListData = (
    params: any,
    shouldAppend = false
): certificationActionTypes => ({
    type: GET_CERTIFICATION_TYPES_LIST,
    params,
    shouldAppend
});

export const getCertificationTypesListDataSuccess = (
    payload: any,
    shouldAppend?: any
): certificationActionTypes => ({
    type: GET_CERTIFICATION_TYPES_LIST_SUCCESS,
    payload,
    shouldAppend
});

export const createCertificationLevelData = (
    params: any,
    id?: number
): certificationActionTypes => ({
    type: CREATE_CERTIFICATION_LEVEL_DATA,
    params,
    id
});

export const createCertificationLevelDataSuccess = (): certificationActionTypes => ({
    type: CREATE_CERTIFICATION_LEVEL_DATA_SUCCESS
});

export const editCertificationLevelData = (
    params: any,
    certification_id: number,
    id?: number
): certificationActionTypes => ({
    type: EDIT_CERTIFICATION_LEVEL_DATA,
    params,
    certification_id,
    id
});

export const editCertificationLevelDataSuccess = (): certificationActionTypes => ({
    type: EDIT_CERTIFICATION_LEVEL_DATA_SUCCESS
});

export const getCertificationLevelListData = (
    params: any,
    certification_id?: number
): certificationActionTypes => ({
    type: GET_CERTIFICATION_LEVEL_LIST,
    params,
    certification_id
});

export const getCertificationLevelListDataSuccess = (payload: any): certificationActionTypes => ({
    type: GET_CERTIFICATION_LEVEL_LIST_SUCCESS,
    payload
});

export const getCertificationLevelOptionsData = (): certificationActionTypes => ({
    type: GET_CERTIFICATION_LEVEL_OPTIONS
});

export const getCertificationLevelOptionsDataSuccess = (
    payload: any
): certificationActionTypes => ({
    type: GET_CERTIFICATION_LEVEL_OPTIONS_SUCCESS,
    payload
});

export const getCertificationBuildingsList = (
    name: string,
    params: any,
    shouldAppend = false
): certificationActionTypes => ({
    type: GET_CERTIFICATION_BUILDINGS_LIST,
    name,
    params,
    shouldAppend
});

export const getCertificationBuildingsListSuccess = (
    payload: any,
    shouldAppend: any
): certificationActionTypes => ({
    type: GET_CERTIFICATION_BUILDINGS_LIST_SUCCESS,
    payload,
    shouldAppend
});
