import { put, takeLatest } from 'redux-saga/effects';
import { getApiData, patchApiData, postApiData } from '../../CommonUtilities';
import { api_prefix } from '../../config';
import {
    createCategorySuccess,
    createSubCategorySuccess,
    createTopicSuccess,
    editCategorySuccess,
    editSubCategorySuccess,
    editTopicSuccess,
    getCategoryListData,
    getCategoryListDataSuccess,
    getSubCategoryListData,
    getSubCategoryListDataSuccess,
    getTopicListData,
    getTopicListDataSuccess,
    setIsEdit
} from '../actions';
import {
    categoryActionTypes,
    CREATE_CATEGORY,
    CREATE_SUB_CATEGORY,
    CREATE_TOPIC,
    editCategoryAction,
    editSubCategoryAction,
    editTopicAction,
    EDIT_CATEGORY,
    EDIT_SUB_CATEGORY,
    EDIT_TOPIC,
    getCategoryListAction,
    getSubCategoryListAction,
    getTopicListAction,
    GET_CATEGORY_LIST,
    GET_SUB_CATEGORY_LIST,
    GET_TOPIC_LIST
} from '../actions/Category/types';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* getCategoryListDataSaga(action: getCategoryListAction): any {
    const json = yield getApiData(`${api_prefix}/category/`, action);
    if (json && json.results) {
        yield put(getCategoryListDataSuccess(json, action.appendData));
    }
}

export function* getCategoryDataSaga() {
    yield takeLatest(GET_CATEGORY_LIST, getCategoryListDataSaga);
}

function* createCategory(action: categoryActionTypes): any {
    const json = yield postApiData(
        `${api_prefix}/category/`,
        action,
        i18n.t(constantStrings.focusAreaCreated),
        true
    );
    let params: any = {
        ordering: 'id',
        limit: 'all'
    };
    if (json) {
        yield put(createCategorySuccess());
        yield put(getCategoryListData(params));
    }
}

export function* createCategorySaga() {
    yield takeLatest(CREATE_CATEGORY, createCategory);
}

function* editCategory(action: editCategoryAction): any {
    const json = yield patchApiData(
        `${api_prefix}/category/${action.id}/`,
        action,
        i18n.t(constantStrings.focusAreaEdited),
        true
    );

    let params: any = {
        ordering: 'id',
        limit: 'all'
    };

    if (!json.data) {
        yield put(editCategorySuccess());
        yield put(setIsEdit(false));
        yield put(getCategoryListData(params));
    }
}

export function* editCategorySaga() {
    yield takeLatest(EDIT_CATEGORY, editCategory);
}

function* getSubCategoryListDataSaga(action: getSubCategoryListAction): any {
    const json = yield getApiData(`${api_prefix}/sub-category/`, action);
    if (json && json.results) {
        yield put(getSubCategoryListDataSuccess(json, action.appendData));
    }
}

export function* getSubCategoryDataSaga() {
    yield takeLatest(GET_SUB_CATEGORY_LIST, getSubCategoryListDataSaga);
}

function* createSubCategory(action: categoryActionTypes): any {
    const json = yield postApiData(
        `${api_prefix}/sub-category/`,
        action,
        i18n.t(constantStrings.topicCreated),
        true
    );
    let params: any = {
        ordering: 'id',
        limit: 'all'
    };

    if (json) {
        yield put(createSubCategorySuccess());
        yield put(getSubCategoryListData(params));
    }
}

export function* createSubCategorySaga() {
    yield takeLatest(CREATE_SUB_CATEGORY, createSubCategory);
}

function* editSubCategory(action: editSubCategoryAction): any {
    const json = yield patchApiData(
        `${api_prefix}/sub-category/${action.id}/`,
        action,
        i18n.t(constantStrings.topicEdited),
        true
    );
    let params: any = {
        ordering: 'id',
        limit: 'all'
    };

    if (!json.data) {
        yield put(editSubCategorySuccess());
        yield put(setIsEdit(false));
        yield put(getSubCategoryListData(params));
    }
}

export function* editSubCategorySaga() {
    yield takeLatest(EDIT_SUB_CATEGORY, editSubCategory);
}

function* getTopicListDataSaga(action: getTopicListAction): any {
    const json = yield getApiData(`${api_prefix}/topic/`, action);
    if (json && json.results) {
        yield put(getTopicListDataSuccess(json, action.appendData));
    }
}

export function* getTopicDataSaga() {
    yield takeLatest(GET_TOPIC_LIST, getTopicListDataSaga);
}

function* createTopic(action: categoryActionTypes): any {
    const json = yield postApiData(
        `${api_prefix}/topic/`,
        action,
        i18n.t(constantStrings.keyWordCreated),
        true
    );
    let params: any = {
        ordering: 'id',
        limit: 'all'
    };

    if (json) {
        yield put(createTopicSuccess());
        yield put(getTopicListData(params));
    }
}

export function* createTopicSaga() {
    yield takeLatest(CREATE_TOPIC, createTopic);
}

function* editTopic(action: editTopicAction): any {
    const json = yield patchApiData(
        `${api_prefix}/topic/${action.id}/`,
        action,
        i18n.t(constantStrings.keyWordEdited),
        true
    );
    let params: any = {
        ordering: 'id',
        limit: 'all'
    };

    if (!json.data) {
        yield put(editTopicSuccess());
        yield put(setIsEdit(false));
        yield put(getTopicListData(params));
    }
}

export function* editTopicSaga() {
    yield takeLatest(EDIT_TOPIC, editTopic);
}
