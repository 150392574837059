import styled from 'styled-components';

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: default;
    margin: 0;

    .containerClassName {
        display: flex;
        list-style-type: none;
        align-items: center;
    }

    .activeClassName {
        background-color: ${(props: any) => props.theme.primary.THEME};
    }

    .activeLinkClassName {
        color: white;
    }

    .indexClassName {
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 4px;
        margin: 4px;
        box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.25);
    }

    .pageLinkClassName {
        text-decoration: none;
    }

    li.previous a,
    li.next a,
    li.break a {
        margin: 4px;
        text-decoration: none;
    }
`;
