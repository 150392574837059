export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const GET_QUESTIONNAIRE_RECEIVED = 'GET_QUESTIONNAIRE_RECEIVED';
export const GET_COMBINATIONS = 'GET_COMBINATIONS';
export const GET_COMBINATIONS_RECEIVED = 'GET_COMBINATIONS_RECEIVED';
export const GET_ANSWER_QUESTIONNAIRE = 'GET_ANSWER_QUESTIONNAIRE';
export const GET_ANSWER_QUESTIONNAIRE_RECEIVED = 'GET_ANSWER_QUESTIONNAIRE_RECEIVED';
export const GET_QUESTIONS_OPTIONS = 'GET_QUESTIONS_OPTIONS';
export const GET_QUESTIONS_OPTIONS_SUCCESS = 'GET_QUESTIONS_OPTIONS_SUCCESS';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const GET_SEQUENCE_ID = 'GET_SEQUENCE_ID';
export const GET_SEQUENCE_ID_RECEIVED = 'GET_SEQUENCE_ID_RECEIVED';
export const GET_ANSWERS = 'GET_ANSWERS';
export const GET_ANSWERS_RECEIVED = 'GET_ANSWERS_RECEIVED';
export const SET_QUESTIONNAIRE_TYPE = 'SET_QUESTIONNAIRE_TYPE';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const PUBLISH_QUESTIONNAIRE = 'PUBLISH_QUESTIONNAIRE';
export const PUBLISH_QUESTIONNAIRE_SUCCESS = 'PUBLISH_QUESTIONNAIRE_SUCCESS';
export const DELETE_COMBINATION = 'DELETE_COMBINATION';
export const DELETE_COMBINATION_SUCCESS = 'DELETE_COMBINATION_SUCCESS';
export const GET_QUESTION_VALIDATIONS = 'GET_QUESTION_VALIDATIONS';
export const GET_QUESTION_VALIDATIONS_SUCCESS = 'GET_QUESTION_VALIDATIONS_SUCCESS';
export const CREATE_DRAFT = 'CREATE_DRAFT';
export const CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS';
export const MOVE_TO_ARCHIVE = 'MOVE_TO_ARCHIVE';
export const MOVE_TO_ARCHIVE_SUCCESS = 'MOVE_TO_ARCHIVE_SUCCESS';
export const SUBMIT_FOR_VERIFICATION = 'SUBMIT_FOR_VERIFICATION';
export const SUBMIT_FOR_VERIFICATION_SUCCESS = 'SUBMIT_FOR_VERIFICATION_SUCCESS';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const ADD_ATTACHMENT_SUCCESS = 'ADD_ATTACHMENT_SUCCESS';

export interface getQuestionnaireAction {
    type: typeof GET_QUESTIONNAIRE;
    params: any;
    status: string;
    appendData?: boolean;
}

export interface getQuestionnaireSuccessAction {
    type: typeof GET_QUESTIONNAIRE_RECEIVED;
    payload: any;
    appendData?: boolean;
}

export interface getCombinationsAction {
    type: typeof GET_COMBINATIONS;
    params: any;
    id: number;
}

export interface getCombinationsSuccessAction {
    type: typeof GET_COMBINATIONS_RECEIVED;
    payload: any;
}

export interface getQuestionsOptionsAction {
    type: typeof GET_QUESTIONS_OPTIONS;
}

export interface getQuestionsOptionsSuccessAction {
    type: typeof GET_QUESTIONS_OPTIONS_SUCCESS;
    payload: any;
}

export interface createQuestionAction {
    type: typeof CREATE_QUESTION;
    id: number;
    questionnaireId: number;
    params: any;
}

export interface createQuestionSuccessAction {
    type: typeof CREATE_QUESTION_SUCCESS;
}

export interface getSequenceIdAction {
    type: typeof GET_SEQUENCE_ID;
    id: number;
}

export interface getSequenceIdSuccessAction {
    type: typeof GET_SEQUENCE_ID_RECEIVED;
    payload: any;
}

export interface getAnswerQuestionnaireAction {
    type: typeof GET_ANSWER_QUESTIONNAIRE;
    id: number;
}
export interface getAnswerQuestionnaireSuccessAction {
    type: typeof GET_ANSWER_QUESTIONNAIRE_RECEIVED;
    payload: any;
}

export interface getAnswersAction {
    type: typeof GET_ANSWERS;
    id: number;
}
export interface getAnswersSuccessAction {
    type: typeof GET_ANSWERS_RECEIVED;
    payload: any;
}
export interface setQuestionnaireTypeAction {
    type: typeof SET_QUESTIONNAIRE_TYPE;
    data: any;
}

export interface updateQuestionAction {
    type: typeof UPDATE_QUESTION;
    id: number;
    questionnaireId: number;
    params: any;
}

export interface updateQuestionSuccessAction {
    type: typeof UPDATE_QUESTION_SUCCESS;
}

export interface deleteQuestionAction {
    type: typeof DELETE_QUESTION;
    id: number;
    questionnaireId: number;
    payload: any;
}

export interface deleteQuestionSuccessAction {
    type: typeof DELETE_QUESTION_SUCCESS;
}

export interface deleteCombinationAction {
    type: typeof DELETE_COMBINATION;
    id: number;
    questionnaireId: number;
    name: string;
    payload: any;
}

export interface deleteCombinationSuccessAction {
    type: typeof DELETE_COMBINATION_SUCCESS;
}

export interface publishQuestionnaireAction {
    type: typeof PUBLISH_QUESTIONNAIRE;
    params: any;
    history: any;
    status: string;
    limit: any;
}

export interface publishQuestionnaireSuccessAction {
    type: typeof PUBLISH_QUESTIONNAIRE_SUCCESS;
}

export interface getQuestionValidationsAction {
    type: typeof GET_QUESTION_VALIDATIONS;
    params: any;
}

export interface getQuestionValidationsSuccessAction {
    type: typeof GET_QUESTION_VALIDATIONS_SUCCESS;
    payload: any;
}

export interface submitForVerificationAction {
    type: typeof SUBMIT_FOR_VERIFICATION;
    params: any;
    id: number;
    history: any;
}

export interface submitForVerificationSuccessAction {
    type: typeof SUBMIT_FOR_VERIFICATION_SUCCESS;
}
export interface createDraftAction {
    type: typeof CREATE_DRAFT;
    params: any;
    status: string;
    limit: any;
}

export interface createDraftSuccessAction {
    type: typeof CREATE_DRAFT_SUCCESS;
}

export interface moveToArchiveAction {
    type: typeof MOVE_TO_ARCHIVE;
    params: any;
}

export interface moveToArchiveSuccessAction {
    type: typeof MOVE_TO_ARCHIVE_SUCCESS;
}

export interface addAttachmentAction {
    type: typeof ADD_ATTACHMENT;
    params: any;
}

export interface addAttachmentSuccessAction {
    type: typeof ADD_ATTACHMENT_SUCCESS;
    payload: any;
}

export type questionnaireActionTypes =
    | getQuestionnaireAction
    | getQuestionnaireSuccessAction
    | getCombinationsAction
    | getCombinationsSuccessAction
    | getAnswerQuestionnaireAction
    | getAnswerQuestionnaireSuccessAction
    | getQuestionsOptionsAction
    | getQuestionsOptionsSuccessAction
    | createQuestionAction
    | createQuestionSuccessAction
    | getSequenceIdAction
    | getSequenceIdSuccessAction
    | getAnswersAction
    | getAnswersSuccessAction
    | setQuestionnaireTypeAction
    | updateQuestionAction
    | updateQuestionSuccessAction
    | publishQuestionnaireAction
    | publishQuestionnaireSuccessAction
    | deleteQuestionAction
    | deleteQuestionSuccessAction
    | deleteCombinationAction
    | deleteCombinationSuccessAction
    | getQuestionValidationsAction
    | getQuestionValidationsSuccessAction
    | createDraftAction
    | createDraftSuccessAction
    | moveToArchiveAction
    | moveToArchiveSuccessAction
    | submitForVerificationAction
    | submitForVerificationSuccessAction
    | addAttachmentAction
    | addAttachmentSuccessAction;

export interface QuestionnaireState {
    isQuestionnaireDataFetched: boolean;
    questionnaireData: any;
    combinationsData: any;
    answerQuestionnaireData: any;
    isFetchingAnswerQuestionnaire: boolean;
    questionsOptionsData: any;
    sequenceIds: any;
    answersData: any;
    isAnswersDataFetched: boolean;
    answerDetails: any;
    updateAnswerDetails: any;
    questionnaireType: any;
    questionValidationsData: any;
    isCombinationsFetched: boolean;
    attachmentData: any;
}
