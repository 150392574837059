import React, { FunctionComponent, useEffect, useState } from 'react';
import IBILoader from '../../UIComponents/IBILoader';
import { getImage } from '../../../CommonUtilities';
import { ProfileIconLoader } from '../../styles';
import { baseHTTPURL } from '../../../config';
interface GenericImageTypes {
    path: string;
    style?: any;
    className?: string;
    alt?: string | null;
    cacheIdentifier?: string;
    isShortLoader?: boolean;
}

interface cachedImagesType {
    [key: string]: string;
}
const cachedImages: cachedImagesType = {};

const GenericImage: FunctionComponent<GenericImageTypes> = ({
    alt,
    path,
    style,
    isShortLoader,
    className,
    cacheIdentifier
}: GenericImageTypes) => {
    const [image, setImage] = useState<string>(''),
        [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        function storeAndUpdateImage(imageBlobURL: string) {
            if (cacheIdentifier) cachedImages[cacheIdentifier] = imageBlobURL;
            setImage(imageBlobURL);
        }

        if (path && path.startsWith('data:')) {
            setImage(path);
            return;
        }

        if (cacheIdentifier && cachedImages[cacheIdentifier]) {
            setImage(cachedImages[cacheIdentifier]);
        } else {
            getImage(baseHTTPURL + path, storeAndUpdateImage, setShowLoader);
        }
    }, [path, cacheIdentifier]);

    if (showLoader) {
        if (isShortLoader) {
            return <ProfileIconLoader className='fas fa-spinner fa-spin fa-lg' />;
        }
        return (
            <div className={`${className} p-2`}>
                <IBILoader width={'100%'} height={'100%'} />
            </div>
        );
    }
    return <img className={className} src={image} style={style} alt={alt || ''} />;
};

export default GenericImage;
