import { checkUserAction } from './../actions/CreateNewUser/types';
import { all, put, takeLatest } from 'redux-saga/effects';
import { createNewUserAction, CREATE_NEW_USER, CHECK_USER } from '../actions/CreateNewUser/types';
import {
    checkUserFailure,
    checkUserSuccess,
    createNewUserFailure,
    createNewUserSuccess,
    getUserData,
    userExist,
    userExistReset
} from '../actions';
import { api_prefix } from '../../config';
import i18n from 'i18next';
import { getApiData, postApiData } from '../../CommonUtilities';
import { constantStrings } from '../../constantStrings';

function* createNewUserSaga(action: createNewUserAction): any {
    const json = yield postApiData(
        `${api_prefix}/users/create-user/`,
        action,
        i18n.t(constantStrings.userCreated),
        true
    );
    let params: any = {
        ordering: '-id',
        limit: action.limit,
        offset: 0
    };
    if (json && json.email) {
        yield put(createNewUserSuccess());
        action.history && action.history.push('/Users');
        if (action.limit) {
            yield put(
                getUserData(params, undefined, undefined, [
                    'Property User',
                    'Property Read Only User'
                ])
            );
        }
        yield put(checkUserFailure());
        yield put(userExistReset());
    } else {
        yield put(createNewUserFailure());
    }
}

export function* newUserSaga() {
    yield all([takeLatest(CREATE_NEW_USER, createNewUserSaga)]);
}

function* checkUserSaga(action: checkUserAction): any {
    const json = yield getApiData(
        `${api_prefix}/users/user_exists/?user_email=${action.payload.email}`,
        action
    );
    if (json && !json.status) {
        yield put(checkUserSuccess());
    }
    if (json && json.status) {
        yield put(userExist());
    }
}

export function* checkSaga() {
    yield all([takeLatest(CHECK_USER, checkUserSaga)]);
}
