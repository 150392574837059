import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { setMapBoxObject } from '../../../Store/actions';
import { mapBoxToken, mapBoxInitialValues } from '../../../constants';

const MapBoxMap = (props: any) => {
    const { buildingLat, buildingLng, draggable } = props;
    const lat = buildingLat || mapBoxInitialValues.lat;
    const lng = buildingLng || mapBoxInitialValues.lng;

    const dispatch = useDispatch();

    mapboxgl.accessToken = mapBoxToken;

    useEffect(() => {
        const baseMap = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: mapBoxInitialValues.defaultZoom
        });
        baseMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        const marker = new mapboxgl.Marker({
            draggable: draggable ?? true
        })
            .setLngLat({ lng, lat })
            .addTo(baseMap);
        dispatch(setMapBoxObject(baseMap, marker));
    }, [dispatch, lat, lng, draggable]);

    return <div id='map' className='w-100' style={{ height: '200px' }} />;
};

export default MapBoxMap;
