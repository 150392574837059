export const REGISTER_COMPANY = 'REGISTER_COMPANY';
export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_FAILURE = 'REGISTER_COMPANY_FAILURE';
export const COMPANY_DATA = 'COMPANY_DATA';
export const COMPANY_DATA_RECEIVED = 'COMPANY_DATA_RECEIVED';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export type registerCompanyJson = {
    companyName: string;
    companyType: string;
};

export interface registerCompanyAction {
    type: typeof REGISTER_COMPANY;
    params: registerCompanyJson;
}

export interface registerCompanySuccessAction {
    type: typeof REGISTER_COMPANY_SUCCESS;
}

export interface registerCompanyFailureAction {
    type: typeof REGISTER_COMPANY_FAILURE;
}

export interface companyNamesParams {
    params: {};
}

export interface userSignUpParams {
    params: {};
}

export interface companyNamesJson {
    results: [];
}

export interface companyNamesAction {
    type: typeof COMPANY_DATA;
    params: companyNamesParams;
}

export interface companyNamesSuccessAction {
    type: typeof COMPANY_DATA_RECEIVED;
    data: any;
}

export interface userSignUpAction {
    type: typeof SIGN_UP;
    params: userSignUpParams;
}

export interface userSignUpSuccessAction {
    type: typeof SIGN_UP_SUCCESS;
}

export interface userSignUpFailureAction {
    type: typeof SIGN_UP_FAILURE;
}

export type CompanyActionTypes =
    | registerCompanyAction
    | registerCompanySuccessAction
    | companyNamesAction
    | companyNamesSuccessAction
    | userSignUpAction
    | userSignUpSuccessAction
    | registerCompanyFailureAction
    | userSignUpFailureAction;

export interface CompanyState {
    isCompanyRegistered: boolean;
    isUserSignedUp: boolean;
    companyNamesData: companyNamesJson | null;
}
