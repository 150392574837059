import {
    CREATE_DIRECTORIES,
    CREATE_FILES,
    DELETE_DIRECTORIES,
    DELETE_FILES,
    DocumentActionTypes,
    EDIT_DIRECTORIES,
    EDIT_FILES,
    GET_DIRECTORIES,
    GET_DIRECTORIES_SUCCESS,
    GET_FILES,
    GET_FILES_SUCCESS,
    EDIT_DIRECTORIES_SUCCESS,
    DELETE_DIRECTORIES_SUCCESS,
    CREATE_DIRECTORIES_SUCCESS,
    CREATE_FILES_SUCCESS,
    EDIT_FILES_SUCCESS,
    DELETE_FILES_SUCCESS
} from './types';

export const getDirectories = (url: string, params: any): DocumentActionTypes => ({
    type: GET_DIRECTORIES,
    url,
    params
});

export const directoriesRecieved = (data: any): DocumentActionTypes => ({
    type: GET_DIRECTORIES_SUCCESS,
    data
});

export const createDirectories = (url: string, params: any): DocumentActionTypes => ({
    type: CREATE_DIRECTORIES,
    url,
    params
});

export const editDirectories = (
    url: string,
    id: number,
    params: any,
    isSubDir = false
): DocumentActionTypes => ({
    type: EDIT_DIRECTORIES,
    url,
    id,
    params,
    isSubDir
});

export const deleteDirectories = (
    url: string,
    directoryId: number,
    isSubDir = false
): DocumentActionTypes => ({
    type: DELETE_DIRECTORIES,
    url,
    directoryId,
    isSubDir
});

export const getFiles = (url: string, params: any): DocumentActionTypes => ({
    type: GET_FILES,
    url,
    params
});

export const filesRecieved = (data: any): DocumentActionTypes => ({
    type: GET_FILES_SUCCESS,
    data
});

export const createFiles = (
    url: string,
    params: any,
    onUploadProgress: any,
    onUploadSuccess: any
): DocumentActionTypes => ({
    type: CREATE_FILES,
    url,
    params,
    onUploadProgress,
    onUploadSuccess
});

export const editFiles = (url: string, id: number, params: any): DocumentActionTypes => ({
    type: EDIT_FILES,
    url,
    id,
    params
});

export const deleteFiles = (url: string, id: number): DocumentActionTypes => ({
    type: DELETE_FILES,
    url,
    id
});

export const editDirectoriesSuccess = () => ({
    type: EDIT_DIRECTORIES_SUCCESS
});

export const deleteDirectoriesSuccess = () => ({
    type: DELETE_DIRECTORIES_SUCCESS
});

export const createDirectoriesSuccess = () => ({
    type: CREATE_DIRECTORIES_SUCCESS
});

export const createFilesSuccess = () => ({
    type: CREATE_FILES_SUCCESS
});

export const editFilesSuccess = () => ({
    type: EDIT_FILES_SUCCESS
});

export const deleteFilesSuccess = () => ({
    type: DELETE_FILES_SUCCESS
});
