import { put, takeLatest } from 'redux-saga/effects';

import { USER_PROFILE_UPDATE, userProfileUpdateAction } from '../actions/UserProfileUpdate/types';

import {
    getCurrentUserData,
    getUpdatedUserProfileDataFailed,
    getUpdatedUserProfileDataReceived,
    getUserData,
    setIsEdit
} from '../actions';

import { api_prefix } from '../../config';
import { putApiData } from '../../CommonUtilities';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* getUpdatedUserProfileData(action: userProfileUpdateAction): any {
    const json = yield putApiData(
        `${api_prefix}/users/${action.id}/update-user/`,
        action,
        action.successMessage || i18n.t(constantStrings.userProfileUpdated),
        true
    );
    const params: any = {
        ordering: '-id'
    };
    if (json.id) {
        yield put(getUpdatedUserProfileDataReceived());
        yield put(getUserData(params, false, action.id));
        action.history && action.history.push('/Users');
        yield put(getCurrentUserData({}));
        yield put(setIsEdit(false));
    } else {
        yield put(getUpdatedUserProfileDataFailed());
    }
}

export function* userProfileUpdateSaga() {
    yield takeLatest(USER_PROFILE_UPDATE, getUpdatedUserProfileData);
}
