import React from 'react';
import { HeadingTagLineText, HeadingText } from './style';

export default function GenericHeadingText(props: any) {
    return (
        <div className={'flex-column ' + props.className}>
            <HeadingText title={props.title}>{props.title}</HeadingText>
            {props.tagLine && <HeadingTagLineText>{props.tagLine}</HeadingTagLineText>}
        </div>
    );
}

GenericHeadingText.defaultProps = {
    className: '',
    isMultipleLines: false
};
