import {
    CREATE_NEW_USER,
    CREATE_NEW_USER_SUCCESS,
    createNewUserActionTypes,
    createNewUserParams,
    CHECK_USER,
    CHECK_USER_SUCCESS,
    USER_EXIST,
    CREATE_NEW_USER_FAILURE,
    CHECK_USER_FAILURE,
    USER_EXIST_RESET
} from './types';

export const createNewUser = (
    params: createNewUserParams,
    history: any,
    limit?: any
): createNewUserActionTypes => ({
    type: CREATE_NEW_USER,
    params,
    history,
    limit
});

export const createNewUserSuccess = (): createNewUserActionTypes => ({
    type: CREATE_NEW_USER_SUCCESS
});

export const createNewUserFailure = (): createNewUserActionTypes => ({
    type: CREATE_NEW_USER_FAILURE
});

export const checkUser = (payload: any): createNewUserActionTypes => ({
    type: CHECK_USER,
    payload
});

export const checkUserSuccess = (): createNewUserActionTypes => ({
    type: CHECK_USER_SUCCESS
});

export const checkUserFailure = (): createNewUserActionTypes => ({
    type: CHECK_USER_FAILURE
});

export const userExist = (): createNewUserActionTypes => ({
    type: USER_EXIST
});

export const userExistReset = (): createNewUserActionTypes => ({
    type: USER_EXIST_RESET
});
