import { useTranslation } from 'react-i18next';
import { PopUpContainer } from './styles';
import { constantStrings } from '../../../constantStrings';

const MapPopupComponent = ({ properties }: any) => {
    const { t } = useTranslation();
    const hideProperties = ['latitude', 'longitude', 'id'];
    return (
        <PopUpContainer>
            {Object.keys(properties || {})
                .filter((key) => !hideProperties.includes(key))
                .map((property: string) => {
                    if (property !== 'certifications') {
                        return (
                            <div className={'row m-0 py-2'} key={property}>
                                <div className={'col-4'}>
                                    <b>{t(constantStrings[property])}</b>
                                </div>
                                <div className={'col-8 '}>{properties[property]}</div>
                            </div>
                        );
                    }
                    return JSON.parse(properties.certifications).map((certification: any) => (
                        <div className={'col-12 row m-0 py-2'} key={certification}>
                            {Object.keys(certification).map((key: string) => {
                                return (
                                    <>
                                        <div className={'col-4'}>
                                            <b>{t(constantStrings[key])}</b>
                                        </div>
                                        <div className={'col-8'}>{certification[key]}</div>
                                    </>
                                );
                            })}
                        </div>
                    ));
                })}
        </PopUpContainer>
    );
};

export default MapPopupComponent;
