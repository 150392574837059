import Skeleton from 'react-loading-skeleton';

const CheckboxOptionsLoader = ({ count }: { count: number }) => {
    return (
        <>
            {Array.from({ length: count }, (_, index) => index).map((_, index: number) => (
                <div className='d-flex align-items-center gap-3 w-100 ms-2 mb-2' key={index}>
                    <Skeleton className={'col-2'} width={19} height={19} />
                    <div className='flex-grow-1 me-3 '>
                        <Skeleton className='col-10' height={19} />
                    </div>
                </div>
            ))}
        </>
    );
};

export default CheckboxOptionsLoader;
