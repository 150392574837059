import Lottie from 'lottie-react';
import React from 'react';
import WithModal from '../Modal';

const style = {
    height: 250,
    width: 250
};

const LottieAnimation = ({ animationData }: any) => {
    return <Lottie {...{ animationData, style }} />;
};

export default WithModal(LottieAnimation);
