import { css } from 'styled-components';

export const size = {
    xxs: '0.625rem', //equal to 10px
    xs: '0.75rem', //equal to 12px
    sm: '0.875rem', //equal to 14px
    base: '1rem', //equal to 16px
    lg: '1.125rem', //equal to 18px
    xl: '1.125rem', //equal to 20px
    xxl: '1.375rem', // equal to 22px
    xxxl: '1.5rem', // equal to 26px
    xxxxl: '1.875rem' // equal to 30px
};

export const ratio = {
    resolution: '(-webkit-min-device-pixel-ratio: 1.2)'
};

export const fontSize_XXS = css`
    font-size: ${size.xxs};
`;

export const fontSize_XS = css`
    font-size: ${size.xs};

    @media ${ratio.resolution} {
        font-size: calc(${size.xs} / 1.2);
    }
`;

export const fontSize_SM = css`
    font-size: ${size.sm};

    @media ${ratio.resolution} {
        font-size: calc(${size.sm} / 1.2);
    }
`;

export const fontSize_BASE = css`
    font-size: ${size.base};

    @media ${ratio.resolution} {
        font-size: calc(${size.base} / 1.2);
    }
`;

export const fontSize_LG = css`
    font-size: ${size.lg};

    @media ${ratio.resolution} {
        font-size: calc(${size.lg} / 1.2);
    }
`;

export const fontSize_XL = css`
    font-size: ${size.xl};

    @media ${ratio.resolution} {
        font-size: calc(${size.xl} / 1.2);
    }
`;

export const fontSize_XXL = css`
    font-size: ${size.xxl};

    @media ${ratio.resolution} {
        font-size: calc(${size.xxl} / 1.2);
    }
`;

export const fontSize_XXXL = css`
    font-size: ${size.xxxl};

    @media ${ratio.resolution} {
        font-size: calc(${size.xxxl} / 1.2);
    }
`;

export const fontSize_XXXXL = css`
    font-size: ${size.xxxxl};

    @media ${ratio.resolution} {
        font-size: calc(${size.xxxxl} / 1.2);
    }
`;
