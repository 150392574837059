import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FetchUserActionTypes,
    viewUserParams,
    viewUserJson
} from './types';

export const getUserData = (
    params: viewUserParams,
    shouldAppend?: boolean,
    id?: number,
    group?: Array<any>
): FetchUserActionTypes => ({
    type: FETCH_USER,
    params,
    id,
    shouldAppend,
    group
});

export const getUserDataReceived = (
    data: viewUserJson,
    shouldAppend?: boolean
): FetchUserActionTypes => ({
    type: FETCH_USER_SUCCESS,
    data,
    shouldAppend
});
