import { put, takeLatest } from 'redux-saga/effects';
import { getApiData, optionsApiData, postApiData, putApiData } from '../../CommonUtilities';
import { api_prefix } from '../../config';
import {
    addCommentsSuccess,
    assignVerifierSuccess,
    createVerificationQuestionSuccess,
    editAnswersSuccess,
    editVerificationQuestionSuccess,
    getAnswers,
    getPropertyUserReceived,
    getStartVerificationsSuccess,
    getVerificationOptionsSuccess,
    getVerificationsSuccess,
    getVerifiersSuccess,
    scheduleVerificationSuccess,
    setIsEdit,
    startVerificationSuccess
} from '../actions';
import {
    ADD_COMMENT,
    assignVerifierAction,
    ASSIGN_VERIFIER,
    createVerificationQuestionAction,
    CREATE_VERIFICATION_QUESTION,
    editAnswersAction,
    editVerificationQuestionAction,
    EDIT_ANSWERS,
    EDIT_VERIFICATION_QUESTION,
    getPropertyUserAction,
    getStartVerificationsAction,
    getVerificationOptionsAction,
    getVerifiersAction,
    GET_PROPERTY_USER,
    GET_START_VERIFICATIONS,
    GET_VERIFICATIONS,
    GET_VERIFICATION_OPTIONS,
    GET_VERIFIERS,
    scheduleVerificationAction,
    SCHEDULE_VERIFICATION,
    startVerificationAction,
    START_VERIFICATION,
    addCommentsAction,
    getVerificationsAction
} from '../actions/Verification/types';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* getVerifications(action: getVerificationsAction): any {
    const json = yield getApiData(`${api_prefix}/verification/`, action);
    if (json.results) {
        yield put(getVerificationsSuccess(json, action.shouldAppend));
    }
}

export function* getVerificationsSaga() {
    yield takeLatest(GET_VERIFICATIONS, getVerifications);
}

function* getVerifiers(action: getVerifiersAction): any {
    const json = yield getApiData(`${api_prefix}/buildings/${action.id}/get_verifiers/`, action);
    if (!json.data) {
        yield put(getVerifiersSuccess(json));
    }
}

export function* getVerifiersSaga() {
    yield takeLatest(GET_VERIFIERS, getVerifiers);
}

function* assignVerifier(action: assignVerifierAction): any {
    const json = yield postApiData(
        `${api_prefix}/verification/${action.id}/assign_verifier/`,
        action,
        i18n.t(constantStrings.verifierAssignedSuccess),
        true
    );
    if (json.primary_verifier) {
        yield put(assignVerifierSuccess());
        yield put(setIsEdit(false));
    }
}

export function* assignVerifierSaga() {
    yield takeLatest(ASSIGN_VERIFIER, assignVerifier);
}

function* getStartVerifications(action: getStartVerificationsAction): any {
    const verificationType = () => {
        if (action.type) return `type=${action.verificationType}`;
        return '';
    };
    const json = yield getApiData(
        `${api_prefix}/verification/get_user_verifications/?${verificationType()}`,
        action
    );
    if (json.results) {
        yield put(getStartVerificationsSuccess(json, action.appendData));
    }
}

export function* getStartVerificationsSaga() {
    yield takeLatest(GET_START_VERIFICATIONS, getStartVerifications);
}

function* startVerification(action: startVerificationAction): any {
    const json = yield postApiData(
        `${api_prefix}/verification/start_verification/`,
        action,
        '',
        false
    );
    if (!json) {
        yield put(startVerificationSuccess());
    }
}

export function* startVerificationSaga() {
    yield takeLatest(START_VERIFICATION, startVerification);
}

function* scheduleVerification(action: scheduleVerificationAction): any {
    const json = yield postApiData(
        `${api_prefix}/verification/${action.id}/schedule_verification/`,
        action,
        i18n.t(constantStrings.siteVisitScheduled),
        true
    );
    if (json) {
        localStorage.removeItem('verificationScheduleDate');
        localStorage.removeItem('verificationId');
        yield put(scheduleVerificationSuccess());
    }
}

export function* scheduleVerificationSaga() {
    yield takeLatest(SCHEDULE_VERIFICATION, scheduleVerification);
}

function* addComments(action: addCommentsAction): any {
    const json = yield postApiData(
        `${api_prefix}/verification/${action.id}/add_comments/`,
        action,
        i18n.t(constantStrings.primaryVerificationSaved),
        true
    );
    if (!json.data) {
        yield put(addCommentsSuccess());
        action.history.push('/Start');
    }
}

export function* addCommentsSaga() {
    yield takeLatest(ADD_COMMENT, addComments);
}

function* createVerificationQuestion(action: createVerificationQuestionAction): any {
    const json = yield postApiData(
        `${api_prefix}/verification-questions/`,
        action,
        i18n.t(constantStrings.verificationQuestionAdded),
        true
    );
    if (json.questionnaire_version) {
        yield put(createVerificationQuestionSuccess());
    }
}

export function* createVerificationQuestionSaga() {
    yield takeLatest(CREATE_VERIFICATION_QUESTION, createVerificationQuestion);
}

function* editVerificationQuestion(action: editVerificationQuestionAction): any {
    const json = yield putApiData(
        `${api_prefix}/verification-questions/${action.id}/`,
        action,
        i18n.t(constantStrings.verificationQuestionUpdated),
        true
    );
    if (json.questionnaire_version) {
        yield put(editVerificationQuestionSuccess());
    }
}

export function* editVerificationQuestionSaga() {
    yield takeLatest(EDIT_VERIFICATION_QUESTION, editVerificationQuestion);
}

function* getVerificationOptionsRequest(action: getVerificationOptionsAction): any {
    const json = yield optionsApiData(`${api_prefix}/verification/`, action);
    if (json?.actions?.POST) {
        yield put(getVerificationOptionsSuccess({ ...json.actions.POST }));
    }
}

export function* getVerificationOptionsSaga() {
    yield takeLatest(GET_VERIFICATION_OPTIONS, getVerificationOptionsRequest);
}

function* editAnswers(action: editAnswersAction): any {
    const json = yield postApiData(
        `${api_prefix}/verification/${action.id}/edit_answers/`,
        action,
        i18n.t(constantStrings.answersUpadted),
        true
    );
    if (!json.data) {
        yield put(editAnswersSuccess(json));
        yield put(getAnswers(action.id));
    }
}

export function* editAnswersSaga() {
    yield takeLatest(EDIT_ANSWERS, editAnswers);
}

function* getPropertyUser(action: getPropertyUserAction): any {
    const json = yield getApiData(
        `${api_prefix}/users/?groups=Property User&buildings=${action.id}`,
        action
    );
    if (json.results) {
        yield put(getPropertyUserReceived(json.results));
    }
}

export function* getPropertyUserSaga() {
    yield takeLatest(GET_PROPERTY_USER, getPropertyUser);
}
