import { Controller, useFormContext } from 'react-hook-form';
import React, { FunctionComponent, useRef } from 'react';
import { LabelHeading, ErrorText, EntryDate, SearchFilterBox, FontIconInInput } from './styles';
import { setMinutes, setHours, format } from 'date-fns';
import { FlexColumn } from '../../styles';
import DatePicker from 'react-datepicker';

interface TimeSelectorHookProps {
    displayName?: string | null;
    name: string;
    value?: string;
    className?: string;
    showTimePicker?: boolean;
    showOnlyTimePicker?: boolean;
    variant?: string;
    asterisk?: boolean;
    minTime?: any;
    maxTime?: any;
    placeHolderText?: any;
    minDate?: Date;
    maxDate?: Date;
    noMargin?: boolean;
    autoComplete?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    onChangeHandler?: any;
    defaultValue?: any;
    showYearDropdown?: boolean;
    showMonthDropdown?: boolean;
    showOnlyYear?: boolean;
    isClearable?: boolean;
}

const FormDateTimePicker: FunctionComponent<TimeSelectorHookProps> = ({
    displayName,
    name,
    minTime = null,
    maxTime = null,
    className = '',
    showTimePicker,
    showOnlyTimePicker,
    variant = '',
    asterisk = true,
    placeHolderText = '',
    minDate = null,
    maxDate = null,
    noMargin = false,
    autoComplete = false,
    autoFocus = false,
    disabled,
    onChangeHandler,
    defaultValue,
    showYearDropdown = true,
    showMonthDropdown = true,
    showOnlyYear = false,
    isClearable = false
}) => {
    const {
        formState: { errors },
        control
    } = useFormContext();
    const datePickerRef = useRef(null);
    const canFilterTime = !(minTime || maxTime);

    const dateFormat = () => {
        if (showOnlyTimePicker) return 'h:mm aa';
        if (showTimePicker) return 'yyyy/MM/dd h:mm aa';
        if (showOnlyYear) return 'yyyy';
        return 'yyyy/MM/dd';
    };
    // @ts-ignore
    return (
        <FlexColumn className={className + ' px-2 ' + (!noMargin && ' mb-3')}>
            {displayName && (
                <LabelHeading className='mb-1' htmlFor={displayName} variant={variant}>
                    {displayName}
                    {asterisk ? <span className='text-danger'> * </span> : ''}
                </LabelHeading>
            )}
            <SearchFilterBox className={'flex-column align-items-start'}>
                <EntryDate className={'w-100'} isDisabled={disabled}>
                    <Controller
                        control={control}
                        name={name}
                        defaultValue={defaultValue}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <DatePicker
                                autoComplete={autoComplete ? 'on' : 'off'}
                                ref={datePickerRef}
                                onKeyDown={(e: any) => {
                                    if (e.keyCode === 9 || e.which === 9) {
                                        //@ts-ignore
                                        datePickerRef && datePickerRef.current.setOpen(false);
                                    }
                                }}
                                id={displayName || ''}
                                onChange={(value: any) => {
                                    onChange(value);
                                    onChangeHandler && onChangeHandler(value);
                                }}
                                onBlur={onBlur}
                                selected={value}
                                showTimeSelect={showTimePicker || false}
                                showTimeSelectOnly={showOnlyTimePicker || false}
                                timeIntervals={15}
                                timeCaption='Time'
                                minTime={
                                    minTime
                                        ? setHours(setMinutes(new Date(), minTime[1]), minTime[0])
                                        : setHours(setMinutes(new Date(), 0), 0)
                                }
                                maxTime={
                                    maxTime
                                        ? setHours(setMinutes(new Date(), maxTime[1]), maxTime[0])
                                        : setHours(setMinutes(new Date(), 59), 23)
                                }
                                filterTime={
                                    canFilterTime
                                        ? (time) => {
                                              if (minDate && maxDate) {
                                                  return (
                                                      minDate.getTime() <
                                                          new Date(time).getTime() &&
                                                      new Date(time).getTime() < maxDate.getTime()
                                                  );
                                              }
                                              if (minDate) {
                                                  return (
                                                      minDate.getTime() < new Date(time).getTime()
                                                  );
                                              }
                                              if (maxDate) {
                                                  return (
                                                      new Date(time).getTime() < maxDate.getTime()
                                                  );
                                              }
                                              return true;
                                          }
                                        : undefined
                                }
                                dateFormat={dateFormat()}
                                showYearPicker={showOnlyYear}
                                placeholderText={placeHolderText}
                                minDate={minDate}
                                maxDate={maxDate}
                                isClearable={disabled ? false : isClearable}
                                disabled={disabled}
                                autoFocus={autoFocus}
                                showYearDropdown={showYearDropdown}
                                showMonthDropdown={showMonthDropdown}
                                dropdownMode='select'
                                yearItemNumber={10}
                                title={value ? format(value, dateFormat()) : placeHolderText}
                                showDisabledMonthNavigation={true}
                            />
                        )}
                    />
                </EntryDate>
                <FontIconInInput isLeftSide={false} className={'far fa-calendar-alt mr-1'} />
            </SearchFilterBox>
            <ErrorText>{errors[name]?.message}</ErrorText>
        </FlexColumn>
    );
};

export default FormDateTimePicker;
