import {
    SET_ISMODAL_OPEN,
    isModalOpenAction,
    SET_FIRSTTIME_LOGIN,
    GET_GROUPS_LIST,
    GET_GROUPS_LIST_SUCCESS,
    isFirstTimeLoginAction,
    CommonActionTypes,
    GET_REGIONS_LIST,
    GET_SUB_REGIONS_LIST,
    GET_REGIONS_LIST_SUCCESS,
    GET_SUB_REGIONS_LIST_SUCCESS,
    SET_IS_EDIT,
    GET_USER_REGIONS_AND_SUB_REGIONS_LIST,
    GET_USER_REGIONS_AND_SUB_REGIONS_LIST_SUCCESS,
    SET_SHOW_LOADER,
    SET_TABLE_ROW_LIMIT,
    CREATE_REGION,
    CREATE_REGION_SUCCESS,
    EDIT_REGION,
    EDIT_REGION_SUCCESS,
    CREATE_SUB_REGION,
    CREATE_SUB_REGION_SUCCESS,
    EDIT_SUB_REGION,
    EDIT_SUB_REGION_SUCCESS,
    GET_CHOICES,
    CHOICES_RECIEVED,
    SET_SHOW_APP_LOADER
} from './types';

export const setIsModalOpen = (isModalOpen: boolean): isModalOpenAction => ({
    type: SET_ISMODAL_OPEN,
    isModalOpen
});

export const setIsFirstTimeLogin = (isFirstTimeLogin: boolean): isFirstTimeLoginAction => ({
    type: SET_FIRSTTIME_LOGIN,
    isFirstTimeLogin
});

export const getGroupsListData = (params: any): CommonActionTypes => ({
    type: GET_GROUPS_LIST,
    params
});

export const getGroupsListDataRecieved = (payload: any): CommonActionTypes => ({
    type: GET_GROUPS_LIST_SUCCESS,
    payload
});

export const getRegionsListData = (params: any, shouldAppend?: any): CommonActionTypes => ({
    type: GET_REGIONS_LIST,
    params,
    shouldAppend
});

export const getRegionsListDataRecieved = (
    payload: any,
    shouldAppend?: any
): CommonActionTypes => ({
    type: GET_REGIONS_LIST_SUCCESS,
    payload,
    shouldAppend
});

export const getSubRegionsListData = (params: any, shouldAppend?: any): CommonActionTypes => ({
    type: GET_SUB_REGIONS_LIST,
    params,
    shouldAppend
});

export const getSubRegionsListDataRecieved = (
    payload: any,
    shouldAppend?: any
): CommonActionTypes => ({
    type: GET_SUB_REGIONS_LIST_SUCCESS,
    payload,
    shouldAppend
});

export const setIsEdit = (data: boolean): CommonActionTypes => ({
    type: SET_IS_EDIT,
    data
});

export const getUserRegionsAndSubRegionsListData = (
    params: any,
    id: number
): CommonActionTypes => ({
    type: GET_USER_REGIONS_AND_SUB_REGIONS_LIST,
    params,
    id
});

export const getUserRegionsAndSubRegionsListDataRecieved = (payload: any): CommonActionTypes => ({
    type: GET_USER_REGIONS_AND_SUB_REGIONS_LIST_SUCCESS,
    payload
});

export const setShowLoader = (data: boolean) => ({
    type: SET_SHOW_LOADER,
    data
});

export const setShowAppLoader = (data: boolean) => ({
    type: SET_SHOW_APP_LOADER,
    data
});

export const setTableRowLimit = (limit: number) => ({
    type: SET_TABLE_ROW_LIMIT,
    limit
});

export const createRegion = (params: any): CommonActionTypes => ({
    type: CREATE_REGION,
    params
});

export const createRegionSuccess = (): CommonActionTypes => ({
    type: CREATE_REGION_SUCCESS
});

export const editRegion = (id: number, params: any): CommonActionTypes => ({
    type: EDIT_REGION,
    id,
    params
});

export const editRegionSuccess = (): CommonActionTypes => ({
    type: EDIT_REGION_SUCCESS
});

export const createSubRegion = (params: any): CommonActionTypes => ({
    type: CREATE_SUB_REGION,
    params
});

export const createSubRegionSuccess = (): CommonActionTypes => ({
    type: CREATE_SUB_REGION_SUCCESS
});

export const editSubRegion = (id: number, params: any): CommonActionTypes => ({
    type: EDIT_SUB_REGION,
    id,
    params
});

export const editSubRegionSuccess = (): CommonActionTypes => ({
    type: EDIT_SUB_REGION_SUCCESS
});

export const getChoices = (): CommonActionTypes => ({
    type: GET_CHOICES
});

export const choicesReceived = (data: any): CommonActionTypes => ({
    type: CHOICES_RECIEVED,
    data
});
