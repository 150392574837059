import { api_prefix } from '../../config';
import { put, takeLatest } from 'redux-saga/effects';
import { postApiData, getApiData } from '../../CommonUtilities';
import {
    REGISTER_COMPANY,
    registerCompanyAction,
    COMPANY_DATA,
    SIGN_UP
} from '../actions/Company/types';
import { getCompanyNamesDataReceived } from '../actions';
import { registerCompanySuccess, userSignUpSuccess } from '../actions/Company/index';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* registerCompany(action: registerCompanyAction): any {
    const json = yield postApiData(
        `${api_prefix}/company/`,
        action,
        i18n.t(constantStrings.companyRegistrationSuccess),
        true
    );
    if (json && json.id) {
        yield put(registerCompanySuccess());
    }
}

export function* registerCompanySaga() {
    yield takeLatest(REGISTER_COMPANY, registerCompany);
}

function* getCompanyNamesData(action: registerCompanyAction): any {
    const json = yield getApiData(`${api_prefix}/company/`, action);
    yield put(getCompanyNamesDataReceived(json.results ? json : []));
}

export function* companyNamesDataSaga() {
    yield takeLatest(COMPANY_DATA, getCompanyNamesData);
}

function* userSignUp(action: registerCompanyAction): any {
    const json = yield postApiData(
        `${api_prefix}/signup/`,
        action,
        i18n.t(constantStrings.userSignUp),
        false
    );
    if (json && json.email) {
        yield put(userSignUpSuccess());
    }
}

export function* userSignUpSaga() {
    yield takeLatest(SIGN_UP, userSignUp);
}
