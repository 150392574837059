import i18n from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { constantStrings } from '../../../constantStrings';
import TableContainer from '../../DesignSystem/GenericTable';
import PaginationComponent from '../../DesignSystem/Pagination';
import SearchFilter from '../../DesignSystem/SearchFilter/SearchFilter';
import { FlexCenter } from '../../styles';
import GenericHeadingText from '../../UIComponents/GenericHeading';
import { BuildingCardContainer } from '../styles';
import { buildingDataTypes, buildingTableDataTypes } from '../types';

const limit = 10;
const dateNow = new Date();

const columns = [
    {
        Header: i18n.t(constantStrings.buildingName),
        accessor: 'buildingName'
    },
    {
        Header: i18n.t(constantStrings.buildingAddress),
        accessor: 'address'
    },
    {
        Header: i18n.t(constantStrings.managementCompany),
        accessor: 'managementCompany'
    },
    {
        Header: i18n.t(constantStrings.certificationName),
        accessor: 'certificationName'
    },
    {
        Header: i18n.t(constantStrings.certificationLevel),
        accessor: 'certificationLevel'
    },
    {
        Header: i18n.t(constantStrings.certificationDate),
        accessor: 'certificationDate'
    },
    {
        Header: i18n.t(constantStrings.expiryDate),
        accessor: 'expiryDate'
    }
];

const BuildingCardComponent = ({ filteredBuildingData }: any) => {
    const { t } = useTranslation();
    const [searchFilterValue, setSearchFilterValue] = useState('');
    const [buildingsData, setBuildingsData] = useState<buildingTableDataTypes[]>([]);
    const [totalBuildingCount, setTotalBuildingCount] = useState(0);

    const fetchBuildingData = useCallback(
        (index: number) => {
            const filteredBuildingsData = filteredBuildingData.buildingData
                .filter(
                    (building: buildingDataTypes) =>
                        building.certificationExpiryDate &&
                        new Date(building.certificationExpiryDate) > dateNow &&
                        building.privacyStatus === 'Non-Confidential'
                )
                .filter((obj: buildingDataTypes) =>
                    obj.buildingName
                        ?.toString()
                        .toLowerCase()
                        .includes(searchFilterValue.toLowerCase())
                )
                .sort(
                    (current: buildingDataTypes, prev: buildingDataTypes) =>
                        new Date(prev.certificationIssueDate).getTime() -
                        new Date(current.certificationIssueDate).getTime()
                );

            const buildingsTableData = [...filteredBuildingsData]
                .splice(limit * index, limit)
                .map((buildingData: any, index: any) => ({
                    buildingName: buildingData.buildingName,
                    address: `${buildingData.addressLane1}${
                        buildingData.addressLane2 ? `, ${buildingData.addressLane2}` : ''
                    }`,
                    managementCompany: buildingData.managementCompanyName,
                    certificationName: buildingData.certificationName,
                    certificationLevel: buildingData.certificationLevel,
                    certificationDate: buildingData.certificationIssueDate,
                    expiryDate: buildingData.certificationExpiryDate
                }));
            setTotalBuildingCount(filteredBuildingsData.length);
            setBuildingsData(buildingsTableData);
        },
        //eslint-disable-next-line
        [limit, searchFilterValue, filteredBuildingData]
    );

    useEffect(() => {
        fetchBuildingData(0);
    }, [fetchBuildingData]);

    const handlePageChange = ({ selected }: { selected: number }) => {
        fetchBuildingData(selected);
    };

    const getBuildingsCard = () => {
        if (!buildingsData.length)
            return (
                <BuildingCardContainer>
                    <GenericHeadingText
                        title={t(constantStrings.noInformationAvailable)}
                        className='col-3 mb-1'
                    />
                </BuildingCardContainer>
            );
        return (
            <>
                <TableContainer columns={columns} data={buildingsData} />
                <FlexCenter className='pt-4'>
                    <PaginationComponent
                        pageCount={Math.ceil(totalBuildingCount / limit)}
                        handlePageChange={handlePageChange}
                    />
                </FlexCenter>
            </>
        );
    };
    return (
        <BuildingCardContainer className='ms-2 mt-4'>
            <FlexCenter className='justify-content-between mb-3 pe-3'>
                <SearchFilter
                    placeholder={t(constantStrings.searchBuildingName)}
                    searchFilterValue={searchFilterValue}
                    setSearchFilterValue={setSearchFilterValue}
                    isFindBuildingsSearchBox={false}
                />
            </FlexCenter>
            {getBuildingsCard()}
        </BuildingCardContainer>
    );
};

export default BuildingCardComponent;
