import { FlexRow } from '../../../../styles';
import CertificationAwardedComponent from '../CertificationAwardedComponent';
import CertificationLevelComponent from './CertificationLevelComponent';
import PropertiesPertypeComponent from './PropertiesPertypeComponent';

const BBCertifiedBuildingsComponent = ({ filteredBuildingData }: any) => {
    return (
        <FlexRow className='m-1 flex-column flex-lg-row'>
            <div className='col-12 col-lg-4'>
                <CertificationAwardedComponent {...{ filteredBuildingData }} />
            </div>
            <div className='col-12 col-lg-4 px-lg-2 py-2 py-lg-0'>
                <CertificationLevelComponent {...{ filteredBuildingData }} />
            </div>
            <div className='col-12 col-lg-4'>
                <PropertiesPertypeComponent {...{ filteredBuildingData }} />
            </div>
        </FlexRow>
    );
};

export default BBCertifiedBuildingsComponent;
