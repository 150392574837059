import {
    FETCH_COMPANY,
    FETCH_COMPANY_SUCCESS,
    FetchCompanyActionTypes,
    viewCompanyParams,
    viewCompanyJson
} from './types';

export const getCompanyData = (
    params: viewCompanyParams,
    shouldAppend = false
): FetchCompanyActionTypes => ({
    type: FETCH_COMPANY,
    params,
    shouldAppend
});

export const getCompanyDataReceived = (
    data: viewCompanyJson,
    shouldAppend?: boolean
): FetchCompanyActionTypes => ({
    type: FETCH_COMPANY_SUCCESS,
    data,
    shouldAppend
});
