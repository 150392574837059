import { GET_INVOICE_DETAILS, GET_INVOICE_DETAILS_RECEIVED, InvoiceActionTypes } from './types';

export const getInvoiceDetails = (id: number): InvoiceActionTypes => ({
    type: GET_INVOICE_DETAILS,
    id
});

export const getInvoiceDetailsReceived = (payload: any): InvoiceActionTypes => ({
    type: GET_INVOICE_DETAILS_RECEIVED,
    payload
});
