export const notificationTypes = {
    changeUserGroupRequest: 'Permission to Change User Group',
    changeUserSubRegionRequest: 'Permission to change user’s sub-region',
    changeUserRegionRequest: 'Permission to change user’s region',
    buildingAccessRequest: 'Building Access Request',
    newUserAdded: 'New User Added',
    newBuildingRegistration: 'New Building Registered',
    buildingManagementCompanyChanged: 'Building Management Company Changed',
    buildingOwnerCompanyChanged: 'Building Owner Company Changed',
    verificationRequest: 'Verification Request',
    preSiteVerificationCompleted: 'Pre-site Verification Completed',
    certificationExpiryApproaching: 'Certification Expiry Approaching',
    questionnaireVersionExpire: 'New Version of the Questionnaire Coming Soon',
    newQuestionnaireVersionPublished: 'New Questionnaire Version Published',
    buildingRequestUpdate: 'Building Request Update',
    buildingCertificationCompleted: 'Building Certification Completed',
    invoicePayment: 'Invoice Payment',
    verificationAssigned: 'Verification Assigned',
    secondaryVerification: 'Secondary Verification',
    verificationScheduleCreated: 'Verification Schedule Created',
    invoiceReminder: 'Invoice Reminder',
    bomaBestCertificationAwarded: 'BOMA BEST Certification Awarded',
    newVersionQuestionnaireAvailable: 'New Version of the Questionnaire Available',
    questionnaireResponseReverted: 'Questionnaire Response Reverted',
    revertedQuestionnaireResponsePending: 'Reverted Questionnaire Response Pending',
    promoCodeAdded: 'Promo Code Added',
    promoCodeEdited: 'Promo Code Edited'
};
