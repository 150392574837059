import { all, fork } from 'redux-saga/effects';
import * as UserProfileUpdate from './UserProfileUpdate';
import * as CurrentUser from './CurrentUser';
import * as AddClientCompany from './AddClientCompany';
import * as ViewCompany from './ViewCompany';
import * as Login from './Login';
import * as Company from './Company';
import * as CreateNewUser from './CreateNewUser';
import * as Common from './Common';
import * as ViewUser from './ViewUser';
import * as Buildings from './Buildings';
import * as Certification from './Certification';
import * as Category from './Category';
import * as Finances from './Finances';
import * as Questionnaire from './Questionnaire';
import * as Notifications from './Notifications';
import * as Benchmarking from './Benchmarking';
import * as Documents from './Documents';
import * as Invoice from './Invoice';
import * as Verification from './Verification';

function* rootSaga() {
    yield all(
        [
            ...Object.values(Login),
            ...Object.values(Company),
            ...Object.values(AddClientCompany),
            ...Object.values(ViewCompany),
            ...Object.values(CreateNewUser),
            ...Object.values(Common),
            ...Object.values(CurrentUser),
            ...Object.values(UserProfileUpdate),
            ...Object.values(ViewUser),
            ...Object.values(Buildings),
            ...Object.values(Certification),
            ...Object.values(Category),
            ...Object.values(Questionnaire),
            ...Object.values(Finances),
            ...Object.values(Notifications),
            ...Object.values(Benchmarking),
            ...Object.values(Documents),
            ...Object.values(Invoice),
            ...Object.values(Verification)
        ].map(fork)
    );
}

export default rootSaga;
