export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';

export interface fetchCurrentUserAction {
    type: typeof FETCH_CURRENT_USER;
    params: any;
}

export interface fetchCurrentUserSuccessAction {
    type: typeof FETCH_CURRENT_USER_SUCCESS;
    data: any;
}

export type FetchCurrentUserActionTypes = fetchCurrentUserAction | fetchCurrentUserSuccessAction;

export interface FetchCurrentUserState {
    isFetching: boolean;
    currentUserData: any;
}
