/* This component is to render pieChart it expects the data in the format:
const pieChartData = [
  { name: 'Jan 1', electricMeterValue: 400 },
  { name: 'Feb 1', electricMeterValue: 300 },
];
It will show the piechart only when the data is passed in the above way */

import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { pieChartColors } from './chartHelpers';

const getCell = (pieChartData: any, pieColors: any) => {
    return pieChartData?.map((entry: any, index: any) => (
        <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
    ));
};

const PieCharts = (props: any) => {
    const { containerHeight, chartTitle, pieChartData, pieChartColors, innerRadius, outerRadius } =
        props;

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent
    }: any) => {
        const RADIAN = Math.PI / 180;
        const radius = 40 + innerRadius + (outerRadius - innerRadius);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        if (percent === 0) return null;
        return (
            <>
                <text x={x} y={y} fill='black' textAnchor={'middle'} dominantBaseline='central'>
                    {`${Math.round(percent * 100) + '%'}`}
                </text>
            </>
        );
    };
    const renderCustomizedLabelLine = (props: any) => {
        return props.value !== 0 ? (
            <path
                stroke={props.stroke}
                d={`M${props.points[0].x},${props.points[0].y}L${props.points[1].x},${props.points[1].y}`}
            />
        ) : (
            <polyline stroke={props.stroke} fill='none' />
        );
    };

    return (
        <ResponsiveContainer width='100%' height={containerHeight}>
            <PieChart
                margin={{
                    top: 15,
                    right: 10,
                    left: 10,
                    bottom: 0
                }}
            >
                <text x={'50%'} y={10} fill='black' textAnchor='middle' dominantBaseline='central'>
                    <tspan fontSize='20'>{chartTitle}</tspan>
                </text>
                <Pie
                    data={pieChartData}
                    dataKey={'value'}
                    label={renderCustomizedLabel}
                    labelLine={renderCustomizedLabelLine}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    minAngle={3}
                    paddingAngle={3}
                    stroke='none'
                    fill={'transparent'}
                >
                    {getCell(pieChartData, pieChartColors)}
                </Pie>
                <Legend />
                <Tooltip isAnimationActive={false} />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieCharts;

PieCharts.defaultProps = {
    pieChartColors: pieChartColors,
    containerHeight: 400,
    chartTitle: '',
    labelLine: true
};
