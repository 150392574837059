import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useTranslation } from 'react-i18next';
import { mapBoxToken } from '../../../constants';
import MapPopupComponent from './MapPopupComponent';
import { MapContainer } from './styles';

const MapBoxComponent = ({ mapBoxInitialValues, markersData, mapID }: any) => {
    const { t } = useTranslation();
    mapboxgl.accessToken = mapBoxToken;

    useEffect(() => {
        const baseMap = new mapboxgl.Map({
            container: mapID,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: mapBoxInitialValues.center,
            zoom: mapBoxInitialValues.defaultZoom,
            minZoom: mapBoxInitialValues.minZoom,
            maxBounds: [
                [-180, -85.051129],
                [180, 85.051129]
            ]
        });
        baseMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        baseMap.addControl(new mapboxgl.FullscreenControl());

        // Convert markersData to GeoJSON format
        const features = markersData?.map((marker: any) => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [marker.longitude, marker.latitude]
            },
            properties: {
                ...marker
            }
        }));

        if (!!features?.length)
            baseMap.on('load', () => {
                baseMap.addSource('markers', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: features
                    },
                    cluster: true,
                    clusterMaxZoom: 14
                });

                // Add layer for clustered points
                baseMap.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'markers',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': '#4B74BE',
                        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
                    }
                });

                // Add layer for cluster count labels
                baseMap.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'markers',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12
                    },
                    paint: { 'text-color': '#fff' }
                });

                // Add layer for individual unclustered points
                baseMap.addLayer({
                    id: 'unclustered-point',
                    type: 'circle',
                    source: 'markers',
                    filter: ['!', ['has', 'point_count']],
                    layout: {},
                    paint: {
                        'circle-radius': 8,
                        'circle-color': [
                            'match',
                            ['get', 'assetTypeName'],
                            'Healthcare',
                            '#A9C4E5',
                            'Multi Unit Residential Buildings',
                            '#546AAA',
                            'Universal',
                            '#388968',
                            'Light Industrial',
                            '#A65A88',
                            'Enclosed Shopping Center',
                            '#8884d8',
                            'Office',
                            '#F0BA61',
                            'Open Air Retail',
                            '#DFACD2',
                            '#880808'
                        ]
                    }
                });

                baseMap.on('mouseenter', 'markers', () => {
                    baseMap.getCanvas().style.cursor = 'pointer';
                });

                baseMap.on('mouseleave', 'markers', () => {
                    baseMap.getCanvas().style.cursor = '';
                });

                baseMap.on('click', 'unclustered-point', (e: any) => {
                    const { properties } = e.features[0];

                    new mapboxgl.Popup({ className: 'custom-popup' })
                        .setLngLat(e.features[0].geometry.coordinates)
                        .setHTML(renderToString(<MapPopupComponent {...{ properties }} />))
                        .addTo(baseMap);
                });
            });

        return () => {
            baseMap.remove();
        };
    }, [mapBoxInitialValues, markersData, t, mapID]);

    return <MapContainer id={mapID} className='w-100 h-100' />;
};

export default MapBoxComponent;
