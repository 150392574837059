import styled from 'styled-components';
import { customCheckBoxDimension } from '../../variables';
import { theme } from '../../theme';
import { device } from '../responsiveBreakpoints';
import { fontSize_SM } from '../customFonts';

interface CheckboxProps {
    isRowselect?: boolean;
    isDisabled?: boolean;
}

export const filterScrollableHeight = '280px';
export const filterScrollableHeightlaptopL = '100px';

export const CustomCheckBox = styled.label<CheckboxProps>`
    display: flex;
    position: relative;
    cursor: ${(props: CheckboxProps): string => (props.isDisabled ? 'not-allowed' : 'pointer')};
    align-items: center;
    height: ${customCheckBoxDimension};
    // width: ${customCheckBoxDimension};
    margin: 15px 2px;

    b {
        margin-left: 15px;
        color: #3f4650;
        ${fontSize_SM};
        font-weight: 500;
    }

    input[type='checkbox'] {
        visibility: hidden;
    }

    .disclaimer-checkbox {
        visibility: visible !important;
    }

    .duplicate-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        height: ${customCheckBoxDimension};
        width: ${customCheckBoxDimension};
        border: 2px solid ${(props) => props.theme.light_primary.LIGHT_OCTONARY};
        background-color: ${(props: CheckboxProps): string =>
            props.isDisabled ? theme.primary.THEME : theme.standard.WHITE};
        border-radius: 4px;
        opacity: ${(props: CheckboxProps): string => (props.isDisabled ? '0.5' : '1')};
    }

    input:checked ~ .duplicate-checkbox {
        background-color: ${(props: any) => props.theme.primary.THEME};
        border: 1px solid ${(props) => props.theme.primary.THEME};
        opacity: 1;
    }

    .duplicate-checkbox:after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked ~ .duplicate-checkbox:after {
        display: block;
    }

    .duplicate-checkbox:after {
        left: 5px;
        bottom: 4px;
        width: 5px;
        height: 10px;
        border: solid ${(props: CheckboxProps) => theme.standard.WHITE};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
`;

export const Scrollable = styled.div`
    overflow: auto;
    max-height: ${filterScrollableHeight};

    @media ${device.laptopL} {
        max-height: ${filterScrollableHeightlaptopL};
    }
`;
