import { put, takeLatest } from 'redux-saga/effects';

import { fetchUserAction, FETCH_USER } from '../actions/ViewUsers/types';

import { getUserDataReceived } from '../actions/ViewUsers';

import { api_prefix } from '../../config';
import { getApiData } from '../../CommonUtilities';

function* fetchUser(action: fetchUserAction): any {
    const json = yield getApiData(
        `${api_prefix}/users/${action.group ? '?groups=' + action.group : ''}${
            action.id ? action.id + '/' : ''
        }`,
        action
    );

    if (json && (json.results || json.id)) {
        yield put(getUserDataReceived(json, action.shouldAppend));
    }
}

export function* fetchUserSaga() {
    yield takeLatest(FETCH_USER, fetchUser);
}
