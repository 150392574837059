import React from 'react';

import ReactPaginate from 'react-paginate';
import { PaginationContainer } from './styles';
import { NextArrow, PrevArrow } from '../../../assets';

const PaginationComponent = ({
    pageCount,
    handlePageChange
}: {
    pageCount: number;
    handlePageChange: ({ selected }: { selected: number }) => void;
}) => {
    return (
        <PaginationContainer>
            <ReactPaginate
                breakLabel='...'
                nextLabel={<img src={NextArrow} alt='next' />}
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel={<img src={PrevArrow} alt='previous' />}
                renderOnZeroPageCount={null}
                pageClassName={'indexClassName'}
                containerClassName={'containerClassName'}
                pageLinkClassName={'pageLinkClassName'}
                activeClassName={'activeClassName'}
                activeLinkClassName={'activeLinkClassName'}
            />
        </PaginationContainer>
    );
};

export default PaginationComponent;
