import { put, takeLatest } from 'redux-saga/effects';

import {
    ADD_CLIENT_COMPANY,
    addClientCompanyAction,
    GET_CLIENT_COMPANY
} from '../actions/AddClientCompany/types';

import {
    clientCompanyAddFailed,
    clientCompanyAdded,
    getClientCompanyDataRecieved,
    getClientCompanyData
} from '../actions';

import { api_prefix } from '../../config';
import { getApiData, postApiData } from '../../CommonUtilities';
import i18n from 'i18next';
import { constantStrings } from '../../constantStrings';

function* addClientCompany(action: addClientCompanyAction): any {
    const json = yield postApiData(
        `${api_prefix}/companies/`,
        action,
        i18n.t(constantStrings.companyAdded),
        true
    );
    if (json && json.id) {
        let params: any = {};
        yield put(clientCompanyAdded());
        yield put(getClientCompanyData(params));
        if (action.history) {
            action.history.push('/companyList');
        }
    } else {
        yield put(clientCompanyAddFailed());
    }
}

export function* addClientCompanySaga() {
    yield takeLatest(ADD_CLIENT_COMPANY, addClientCompany);
}

function* getClientCompany(action: addClientCompanyAction): any {
    const json = yield getApiData(`${api_prefix}/companies/`, action);
    if (json && json.results) {
        yield put(getClientCompanyDataRecieved(json));
    }
}

export function* getClientCompanySaga() {
    yield takeLatest(GET_CLIENT_COMPANY, getClientCompany);
}
