import {
    CompanyActionTypes,
    CompanyState,
    COMPANY_DATA,
    COMPANY_DATA_RECEIVED,
    REGISTER_COMPANY,
    REGISTER_COMPANY_FAILURE,
    REGISTER_COMPANY_SUCCESS,
    SIGN_UP,
    SIGN_UP_FAILURE,
    SIGN_UP_SUCCESS
} from '../actions/Company/types';

const initialState: CompanyState = {
    isCompanyRegistered: false,
    isUserSignedUp: false,
    companyNamesData: null
};

const Company = (state = initialState, action: CompanyActionTypes): CompanyState => {
    switch (action.type) {
        case REGISTER_COMPANY: {
            return {
                ...state
            };
        }

        case REGISTER_COMPANY_SUCCESS: {
            return {
                ...state,
                isCompanyRegistered: true
            };
        }

        case REGISTER_COMPANY_FAILURE: {
            return {
                ...state,
                isCompanyRegistered: false
            };
        }

        case COMPANY_DATA: {
            return {
                ...state
            };
        }
        case COMPANY_DATA_RECEIVED: {
            return {
                ...state,
                companyNamesData: action.data
            };
        }
        case SIGN_UP: {
            return {
                ...state
            };
        }

        case SIGN_UP_SUCCESS: {
            return {
                ...state,
                isUserSignedUp: true
            };
        }

        case SIGN_UP_FAILURE: {
            return {
                ...state,
                isUserSignedUp: false
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default Company;
