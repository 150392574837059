import React, { FunctionComponent } from 'react';
import { FontIconInInput, SearchFilterBox } from '../FormElements/styles';
import { SearchInput } from './styles';
import { useTranslation } from 'react-i18next';
import { constantStrings } from '../../../constantStrings';

interface SearchInterface {
    className?: string;
    searchFilterValue: string;
    setSearchFilterValue: Function;
    placeholder?: string | null;
    isFindBuildingsSearchBox?: boolean;
    isSearchboxSecondaryBorderRadius?: boolean;
}

const SearchFilter: FunctionComponent<SearchInterface> = (props: SearchInterface) => {
    const { t } = useTranslation();
    const {
        searchFilterValue,
        setSearchFilterValue,
        placeholder,
        className,
        isFindBuildingsSearchBox,
        isSearchboxSecondaryBorderRadius
    } = props;
    return (
        <SearchFilterBox
            className={'col-12 col-sm-8 col-md-6 col-lg-4 col-xl-3 ml-auto px-0 my-1 ' + className}
        >
            <SearchInput
                isFindBuildingsSearchBox={isFindBuildingsSearchBox}
                isSearchboxSecondaryBorderRadius={isSearchboxSecondaryBorderRadius}
                value={searchFilterValue || ''}
                onChange={(e: any) => {
                    setSearchFilterValue(e.target.value);
                }}
                placeholder={placeholder || `${t(constantStrings.search)}...`}
            />
            {!searchFilterValue ? (
                <FontIconInInput
                    isThemeColor={isFindBuildingsSearchBox}
                    className='fa fa-search'
                    isLeftSide={true}
                ></FontIconInInput>
            ) : (
                <FontIconInInput
                    role='button'
                    tabIndex={0}
                    aria-label='clear text'
                    onClick={() => setSearchFilterValue('')}
                    onKeyPress={(e) => e.key === 'Enter' && setSearchFilterValue('')}
                    className={isFindBuildingsSearchBox ? ' ' : 'fa fa-times'}
                    isLeftSide={true}
                ></FontIconInInput>
            )}
        </SearchFilterBox>
    );
};
export default SearchFilter;
