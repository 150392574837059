import {
    categoryActionTypes,
    CategoryState,
    CREATE_CATEGORY,
    CREATE_CATEGORY_SUCCESS,
    CREATE_SUB_CATEGORY,
    CREATE_SUB_CATEGORY_SUCCESS,
    CREATE_TOPIC,
    CREATE_TOPIC_SUCCESS,
    EDIT_CATEGORY,
    EDIT_CATEGORY_SUCCESS,
    EDIT_SUB_CATEGORY,
    EDIT_SUB_CATEGORY_SUCCESS,
    EDIT_TOPIC,
    EDIT_TOPIC_SUCCESS,
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_SUB_CATEGORY_LIST,
    GET_SUB_CATEGORY_LIST_SUCCESS,
    GET_TOPIC_LIST,
    GET_TOPIC_LIST_SUCCESS
} from '../actions/Category/types';

const initialState: CategoryState = {
    categoryListData: null,
    subCategoryListData: null,
    topicListData: null
};

export const Category = (state = initialState, action: categoryActionTypes): CategoryState => {
    switch (action.type) {
        case GET_CATEGORY_LIST: {
            return {
                ...state
            };
        }
        case GET_CATEGORY_LIST_SUCCESS: {
            if (action.appendData && state?.categoryListData?.results?.length) {
                action.payload.results = [
                    ...state.categoryListData?.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                categoryListData: action.payload
            };
        }
        case CREATE_CATEGORY: {
            return {
                ...state
            };
        }
        case CREATE_CATEGORY_SUCCESS: {
            return {
                ...state
            };
        }

        case EDIT_CATEGORY: {
            return {
                ...state
            };
        }
        case EDIT_CATEGORY_SUCCESS: {
            return {
                ...state
            };
        }

        case EDIT_SUB_CATEGORY: {
            return {
                ...state
            };
        }
        case EDIT_SUB_CATEGORY_SUCCESS: {
            return {
                ...state
            };
        }

        case EDIT_TOPIC: {
            return {
                ...state
            };
        }
        case EDIT_TOPIC_SUCCESS: {
            return {
                ...state
            };
        }
        case GET_SUB_CATEGORY_LIST: {
            return {
                ...state
            };
        }
        case GET_SUB_CATEGORY_LIST_SUCCESS: {
            if (action.appendData && state?.subCategoryListData?.results?.length) {
                action.payload.results = [
                    ...state.subCategoryListData?.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                subCategoryListData: action.payload
            };
        }
        case CREATE_SUB_CATEGORY: {
            return {
                ...state
            };
        }
        case CREATE_SUB_CATEGORY_SUCCESS: {
            return {
                ...state
            };
        }
        case GET_TOPIC_LIST: {
            return {
                ...state
            };
        }
        case GET_TOPIC_LIST_SUCCESS: {
            if (action.appendData && state?.topicListData?.results?.length) {
                action.payload.results = [
                    ...state.topicListData?.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                topicListData: action.payload
            };
        }
        case CREATE_TOPIC: {
            return {
                ...state
            };
        }
        case CREATE_TOPIC_SUCCESS: {
            return {
                ...state
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default Category;
