import {
    GET_NOTIFICATIONS_MESSAGE,
    GET_PENDING_REQUEST_ACCESS_DATA,
    GET_PENDING_REQUEST_ACCESS_DATA_SUCCESS,
    GET_PREVIOUS_NOTIFICATIONS_MESSAGE,
    GET_PREVIOUS_NOTIFICATIONS_MESSAGE_SUCCESS,
    MARK_ALL_AS_READ_NOTIFICATION,
    MARK_ALL_AS_READ_NOTIFICATION_SUCCESS,
    MARK_AS_READ_NOTIFICATION,
    MARK_AS_READ_NOTIFICATION_SUCCESS,
    notificationsActionTypes,
    NotificationsState,
    SET_PERMISSION_TAB
} from '../actions/Notifications/types';

const initialState: NotificationsState = {
    notificationMessage: null,
    previousNotificationsMessageData: null,
    isFetchingPreviousMessageData: false,
    isFetchingPendingRequestAccessData: false,
    pendingRequestAccessData: null,
    selectedPermissionTab: 'Group'
};

const Notifications = (
    state = initialState,
    action: notificationsActionTypes
): NotificationsState => {
    switch (action.type) {
        case GET_NOTIFICATIONS_MESSAGE: {
            return {
                ...state,
                notificationMessage: action.message
            };
        }

        case GET_PREVIOUS_NOTIFICATIONS_MESSAGE: {
            return {
                ...state,
                isFetchingPreviousMessageData: true
            };
        }

        case GET_PREVIOUS_NOTIFICATIONS_MESSAGE_SUCCESS: {
            if (action.appendData && state?.previousNotificationsMessageData?.results?.length) {
                action.previousMessage.results = [
                    ...state.previousNotificationsMessageData?.results,
                    ...action.previousMessage.results
                ];
            }
            return {
                ...state,
                isFetchingPreviousMessageData: false,
                previousNotificationsMessageData: action.previousMessage
            };
        }

        case MARK_AS_READ_NOTIFICATION: {
            return {
                ...state
            };
        }

        case MARK_AS_READ_NOTIFICATION_SUCCESS: {
            return {
                ...state
            };
        }

        case MARK_ALL_AS_READ_NOTIFICATION: {
            return {
                ...state
            };
        }

        case MARK_ALL_AS_READ_NOTIFICATION_SUCCESS: {
            return {
                ...state
            };
        }

        case GET_PENDING_REQUEST_ACCESS_DATA: {
            return {
                ...state,
                isFetchingPendingRequestAccessData: true
            };
        }

        case GET_PENDING_REQUEST_ACCESS_DATA_SUCCESS: {
            if (action.appendData && state?.pendingRequestAccessData?.results?.length) {
                action.payload.results = [
                    ...state.pendingRequestAccessData?.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                isFetchingPendingRequestAccessData: false,
                pendingRequestAccessData: action.payload
            };
        }

        case SET_PERMISSION_TAB: {
            return {
                ...state,
                selectedPermissionTab: action.data
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default Notifications;
