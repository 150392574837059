import {
    GET_QUESTIONNAIRE,
    GET_QUESTIONNAIRE_RECEIVED,
    questionnaireActionTypes,
    QuestionnaireState,
    GET_COMBINATIONS,
    GET_COMBINATIONS_RECEIVED,
    GET_ANSWER_QUESTIONNAIRE,
    GET_ANSWER_QUESTIONNAIRE_RECEIVED,
    GET_QUESTIONS_OPTIONS,
    GET_QUESTIONS_OPTIONS_SUCCESS,
    CREATE_QUESTION,
    CREATE_QUESTION_SUCCESS,
    GET_SEQUENCE_ID,
    GET_SEQUENCE_ID_RECEIVED,
    GET_ANSWERS,
    GET_ANSWERS_RECEIVED,
    SET_QUESTIONNAIRE_TYPE,
    UPDATE_QUESTION,
    UPDATE_QUESTION_SUCCESS,
    PUBLISH_QUESTIONNAIRE,
    PUBLISH_QUESTIONNAIRE_SUCCESS,
    DELETE_QUESTION,
    DELETE_QUESTION_SUCCESS,
    DELETE_COMBINATION,
    DELETE_COMBINATION_SUCCESS,
    GET_QUESTION_VALIDATIONS,
    GET_QUESTION_VALIDATIONS_SUCCESS,
    CREATE_DRAFT,
    CREATE_DRAFT_SUCCESS,
    MOVE_TO_ARCHIVE,
    MOVE_TO_ARCHIVE_SUCCESS,
    SUBMIT_FOR_VERIFICATION,
    SUBMIT_FOR_VERIFICATION_SUCCESS,
    ADD_ATTACHMENT,
    ADD_ATTACHMENT_SUCCESS
} from '../actions/Questionnaire/types';

const initialState: QuestionnaireState = {
    isQuestionnaireDataFetched: false,
    questionnaireData: null,
    combinationsData: null,
    answerQuestionnaireData: null,
    isFetchingAnswerQuestionnaire: false,
    questionsOptionsData: null,
    sequenceIds: null,
    answersData: null,
    isAnswersDataFetched: false,
    answerDetails: null,
    updateAnswerDetails: null,
    questionnaireType: 'active',
    questionValidationsData: null,
    isCombinationsFetched: false,
    attachmentData: null
};

export const Questionnaire = (
    state = initialState,
    action: questionnaireActionTypes
): QuestionnaireState => {
    switch (action.type) {
        case GET_QUESTIONNAIRE: {
            return {
                ...state,
                isQuestionnaireDataFetched: true
            };
        }
        case GET_QUESTIONNAIRE_RECEIVED: {
            if (action.appendData && state?.questionnaireData?.results?.length) {
                action.payload.results = [
                    ...state.questionnaireData?.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                questionnaireData: action.payload,
                isQuestionnaireDataFetched: false
            };
        }
        case GET_COMBINATIONS: {
            return {
                ...state,
                isCombinationsFetched: true
            };
        }
        case GET_COMBINATIONS_RECEIVED: {
            return {
                ...state,
                combinationsData: action.payload,
                isCombinationsFetched: false
            };
        }
        case GET_QUESTIONS_OPTIONS: {
            return {
                ...state
            };
        }
        case GET_QUESTIONS_OPTIONS_SUCCESS: {
            return {
                ...state,
                questionsOptionsData: action.payload
            };
        }
        case CREATE_QUESTION: {
            return {
                ...state
            };
        }
        case CREATE_QUESTION_SUCCESS: {
            return {
                ...state
            };
        }
        case GET_SEQUENCE_ID: {
            return {
                ...state
            };
        }
        case GET_SEQUENCE_ID_RECEIVED: {
            return {
                ...state,
                sequenceIds: action.payload
            };
        }
        case GET_ANSWER_QUESTIONNAIRE: {
            return {
                ...state,
                isFetchingAnswerQuestionnaire: true
            };
        }
        case GET_ANSWER_QUESTIONNAIRE_RECEIVED: {
            return {
                ...state,
                answerQuestionnaireData: action.payload,
                isFetchingAnswerQuestionnaire: false
            };
        }
        case GET_ANSWERS: {
            return {
                ...state,
                isAnswersDataFetched: true
            };
        }
        case GET_ANSWERS_RECEIVED: {
            return {
                ...state,
                answersData: action.payload,
                isAnswersDataFetched: false
            };
        }

        case SET_QUESTIONNAIRE_TYPE: {
            return {
                ...state,
                questionnaireType: action.data
            };
        }

        case UPDATE_QUESTION: {
            return {
                ...state
            };
        }

        case UPDATE_QUESTION_SUCCESS: {
            return {
                ...state
            };
        }

        case DELETE_QUESTION: {
            return {
                ...state
            };
        }

        case DELETE_QUESTION_SUCCESS: {
            return {
                ...state
            };
        }

        case DELETE_COMBINATION: {
            return {
                ...state
            };
        }

        case DELETE_COMBINATION_SUCCESS: {
            return {
                ...state
            };
        }

        case PUBLISH_QUESTIONNAIRE: {
            return {
                ...state
            };
        }
        case PUBLISH_QUESTIONNAIRE_SUCCESS: {
            return {
                ...state
            };
        }

        case GET_QUESTION_VALIDATIONS: {
            return {
                ...state
            };
        }

        case CREATE_DRAFT: {
            return {
                ...state
            };
        }

        case GET_QUESTION_VALIDATIONS_SUCCESS: {
            return {
                ...state,
                questionValidationsData: action.payload
            };
        }

        case CREATE_DRAFT_SUCCESS: {
            return {
                ...state
            };
        }

        case SUBMIT_FOR_VERIFICATION: {
            return {
                ...state
            };
        }

        case SUBMIT_FOR_VERIFICATION_SUCCESS: {
            return {
                ...state
            };
        }

        case MOVE_TO_ARCHIVE: {
            return {
                ...state
            };
        }
        case MOVE_TO_ARCHIVE_SUCCESS: {
            return {
                ...state
            };
        }

        case ADD_ATTACHMENT: {
            return {
                ...state
            };
        }
        case ADD_ATTACHMENT_SUCCESS: {
            return {
                ...state,
                attachmentData: action.payload
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default Questionnaire;
