import Administration from './Administration.svg';
import BomaLogo from './images/BOMA-Canada-Logo-white.svg';
import AddFileFolder from './add-file-folder.svg';
import AdministrationActive from './AdministrationActive.svg';
import AddFolder from './add-folder.svg';
import AscendingImg from './ascending.svg';
import Benchmarking from './Benchmarking.svg';
import BenchmarkingActive from './BenchmarkingActive.svg';
import Dashboard from './Dashboard.svg';
import DashboardActive from './DashboardActive.svg';
import Properties from './Properties.svg';
import PropertiesActive from './PropertiesActive.svg';
import Verification from './Verification.svg';
import VerificationActive from './VerificationActive.svg';
import Questionnaire from './Questionnaire.svg';
import QuestionnaireActive from './QuestionnaireActive.svg';
import UserManagement from './UserManagement.svg';
import UserManagementActive from './UserManagementActive.svg';
import Finances from './Finances.svg';
import FinancesActive from './FinancesActive.svg';
import Documents from './Documents.svg';
import DocumentsActive from './DocumentsActive.svg';
import BinGray from './bin-gray.svg';
import BinRed from './bin-red.svg';
import boma_heading_logo from './boma-best-heading.svg';
import CircleChevronUp from './circle-chevron-up.svg';
import CircleChevronDown from './circle-chevron-down.svg';
import CancelIcon from './close-icon-red.svg';
import close_icon from './close-icon.svg';
import CloudComputingImg from './cloud-computing.svg';
import DescendingImg from './descending.svg';
import DocumentImg from './document.svg';
import DownloadIcon from './download.svg';
import EditIcon2x from './edit-icon-2x.svg';
import EditIconGreen from './edit-icon-green.svg';
import EditIconWhite from './edit-icon-white.svg';
import editIcon from './edit-icon.svg';
import Export from './Export.svg';
import eyeIconCrossed from './eye-crossed.svg';
import eyeIcon from './eye-icon.svg';
import FileIcon from './file.svg';
import FlagImage from './flag.svg';
import FlagActiveImage from './flag-active.svg';
import IconMaterialClose from './icon-material-close.svg';
import InfoIcon from './info-4.svg';
import MessageNotedImage from './noted-message.svg';
import OpenNewTabIcon from './open-new-tab.svg';
import plusCircleIconImage from './plus-circle.svg';
import plusCircleIconWhite from './plus-icon-white.svg';
import SkeletonUserImage from './skeleton-user.svg';
import SubFolderIcon from './sub-folder.svg';
import FilterImg from './table-filter-icon.svg';
import UploadIcon from './upload.svg';
import Preview from './View.svg';
import boma_login_bg from './images/boma-best-bg.png';
import ProfileImg from './images/profileImg.png';
import BomaCertifiedImg from './boma-certified.png';
import BuildingIcon from './building-icon.svg';
import Successcheck from './successcheck.svg';
import Failurecheck from './failurecheck.svg';
import DisabledSuccessCheck from './disabled-success-check.svg';
import DisabledFailureCheck from './disabled-failure-check.svg';
import Funnel from './funnel.svg';
import GeneratorIcon from './Generator-icon.svg';
import PlusIconGreyImage from './plus-icon-grey.svg';
import FlagDisabledImage from './flag-disabled.svg';
import BlankIcon from './Blank-icon.svg';
import CompanyIcon from './company.svg';
import SaveIcon from './save-icon.svg';
import BuildingRegisteredImage from './dashboard/building-registered.svg';
import BuildingVerificationImage from './dashboard/building-verification.svg';
import BuildingVerifiedImage from './dashboard/building-verified.svg';
import CertifiedImage from './dashboard/certified.svg';
import ExpiredImage from './dashboard/expired.svg';
import PaymentDueImage from './dashboard/paymentdue.svg';
import LoginLogoImage from './images/login-logo-tagline.svg';
import LoginLogoFrenchImage from './images/LoginLogoFrench.png';
import NextArrow from './nextArrow.svg';
import PrevArrow from './prevArrow.svg';
import CommentsImage from './comment-icon.svg';
import EmptyCommentsImage from './empty-comments-icon.svg';
import PlusIconWhiteImage from './white-plus-icon.svg';
import BonusQuestionIcon from './images/bonusquestionsicon.svg';
import checkCircleIcon from './check-circle.svg';
import menuIcon from './images/menu-icon.svg';

const images = {
    Administration: Administration,
    BomaLogo: BomaLogo,
    AddFileFolder: AddFileFolder,
    AdministrationActive: AdministrationActive,
    AddFolder: AddFolder,
    AscendingImg: AscendingImg,
    Benchmarking: Benchmarking,
    BenchmarkingActive: BenchmarkingActive,
    Dashboard: Dashboard,
    DashboardActive: DashboardActive,
    Properties: Properties,
    PropertiesActive: PropertiesActive,
    Verification: Verification,
    VerificationActive: VerificationActive,
    Questionnaire: Questionnaire,
    QuestionnaireActive: QuestionnaireActive,
    UserManagement: UserManagement,
    UserManagementActive: UserManagementActive,
    Finances: Finances,
    FinancesActive: FinancesActive,
    Documents: Documents,
    DocumentsActive: DocumentsActive,
    BinGray: BinGray,
    BinRed: BinRed,
    boma_heading_logo: boma_heading_logo,
    CircleChevronUp: CircleChevronUp,
    CircleChevronDown: CircleChevronDown,
    CancelIcon: CancelIcon,
    close_icon: close_icon,
    CloudComputingImg: CloudComputingImg,
    DescendingImg: DescendingImg,
    DocumentImg: DocumentImg,
    DownloadIcon: DownloadIcon,
    EditIcon2x: EditIcon2x,
    EditIconGreen: EditIconGreen,
    EditIconWhite: EditIconWhite,
    editIcon: editIcon,
    Export: Export,
    eyeIconCrossed: eyeIconCrossed,
    eyeIcon: eyeIcon,
    FileIcon: FileIcon,
    IconMaterialClose: IconMaterialClose,
    InfoIcon: InfoIcon,
    OpenNewTabIcon: OpenNewTabIcon,
    plusCircleIconWhite: plusCircleIconWhite,
    SubFolderIcon: SubFolderIcon,
    FilterImg: FilterImg,
    UploadIcon: UploadIcon,
    Preview: Preview,
    boma_login_bg: boma_login_bg,
    ProfileImg: ProfileImg,
    BomaCertifiedImg: BomaCertifiedImg,
    BuildingIcon: BuildingIcon,
    Successcheck: Successcheck,
    Failurecheck: Failurecheck,
    DisabledSuccessCheck: DisabledSuccessCheck,
    DisabledFailureCheck: DisabledFailureCheck,
    Funnel: Funnel,
    GeneratorIcon: GeneratorIcon
};
export {
    PlusIconGreyImage,
    FlagDisabledImage,
    MessageNotedImage,
    FlagImage,
    FlagActiveImage,
    SkeletonUserImage,
    plusCircleIconImage,
    BlankIcon,
    CompanyIcon,
    FilterImg,
    Funnel,
    SaveIcon,
    AscendingImg,
    DescendingImg,
    BuildingRegisteredImage,
    BuildingVerificationImage,
    BuildingVerifiedImage,
    CertifiedImage,
    ExpiredImage,
    PaymentDueImage,
    LoginLogoImage,
    LoginLogoFrenchImage,
    BonusQuestionIcon,
    InfoIcon,
    NextArrow,
    PrevArrow,
    Preview,
    DownloadIcon,
    CommentsImage,
    EmptyCommentsImage,
    PlusIconWhiteImage,
    BinRed,
    checkCircleIcon,
    menuIcon
};

export default images;
