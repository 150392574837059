import { put, takeLatest } from 'redux-saga/effects';

import { fetchCompanyAction, FETCH_COMPANY } from '../actions/ViewCompany/types';

import { getCompanyDataReceived } from '../actions/ViewCompany';

import { api_prefix } from '../../config';
import { getApiData } from '../../CommonUtilities';

function* fetchCompany(action: fetchCompanyAction): any {
    const json = yield getApiData(`${api_prefix}/companies/`, action);
    if (json && json.results) {
        yield put(getCompanyDataReceived(json, action.shouldAppend));
    }
}

export function* fetchCompanySaga() {
    yield takeLatest(FETCH_COMPANY, fetchCompany);
}
