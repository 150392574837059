import styled from 'styled-components';
import { fontSize_LG, fontSize_XXXXL, fontSize_XXL } from '../customFonts';
import { device } from '../responsiveBreakpoints';
import { theme } from '../../theme';

interface CustomProps {
    isSecondaryCard?: boolean;
}

export const Card = styled.div<CustomProps>`
    display: flex;
    padding: 18px;
    border-radius: 12px;
    height: 160px;
    background-color: ${(props: any) =>
        props.isSecondaryCard ? theme.light_secondary.BRIGHT_DARK : 'white'};
    flex-direction: column;
    justify-content: space-between;
    box-shadow: ${(props: any) => (props.isSecondaryCard ? 'none' : '0px 8px 16px #00000029')};

    .card-body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .card-text {
            ${fontSize_XXXXL};
            font-weight: 900;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media ${device.laptop} {
                ${fontSize_XXL};
            }
        }

        .card-title {
            ${fontSize_LG};
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-word;
        }
    }
`;

export const RCSContainer = styled.div`
    .slick-slide {
        padding: 12px;
        padding-bottom: 18px;
    }

    .slick-prev:before,
    .slick-next:before {
        color: ${(props: any) => props.theme.primary.THEME};
    }
`;
