import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { onErrorCallback, setUserLanguage } from '../../CommonUtilities';
import { constantStrings } from '../../constantStrings';
import { setShowAppLoader } from '../../Store/actions';
import GenericHeadingText from '../UIComponents/GenericHeading';
import BuildingCardComponent from './Components/BuildingCardComponent';
import DashBoardsAndFPComponent from './Components/DashBoardAndFeaturedProject';
import FiltersComponent from './Components/FiltersComponent';
import HeaderComponent from './Components/HeaderComponent';
import { DashBoardContainer, MainContainer } from './styles';
import { allBuildingsDataType } from './types';

const formatAllBuildingsData = (allData: any) => {
    const certificationPortfolioBuildings =
        allData.certification_portfolio_buildings.map((buildings: any) => ({
            subRegionName: buildings.sub_region__region__name,
            assetTypeName: buildings.asset__name,
            managementCompanyName: buildings.management_company__name,
            grossFloorArea:
                buildings.gross_floor_area_unit !== 'Sq.ft'
                    ? 10.76 * Number(buildings.gross_floor_area)
                    : buildings.gross_floor_area,
            grossFloorAreaUnit: constantStrings.squareFeet,
            privacyStatus: buildings.privacy_status,
            isFeatureBuilding: buildings.feature_building,
            buildingName: buildings.name,
            latitude: buildings.building_latitude,
            longitude: buildings.building_longitude,
            addressLane1: buildings.address_lane1,
            addressLane2: buildings.address_lane2,
            city: buildings.city,
            province: buildings.province,
            postalCode: buildings.postal_code,
            country: buildings.country,
            certificationLevel: buildings.portfolio_certification__certified__level,
            certificationExpiryDate: buildings.portfolio_certification__expiry_date,
            certificationIssueDate: buildings.portfolio_certification__issue_date,
            certificationName: buildings.portfolio_certification__certification__name,
            certificationStreamName: buildings.portfolio_certification__stream
        })) || [];

    const versionThreeBuildings =
        allData.version_three_buildings.map((buildings: any) => ({
            subRegionName: buildings.sub_region__region__name,
            assetTypeName: buildings.asset__name,
            managementCompanyName: buildings.management_company__name,
            grossFloorArea:
                buildings.gross_floor_area_unit !== 'Sq.ft'
                    ? 10.76 * Number(buildings.gross_floor_area)
                    : buildings.gross_floor_area,
            grossFloorAreaUnit: constantStrings.squareFeet,
            privacyStatus: buildings.privacy_status,
            isFeatureBuilding: buildings.feature_building,
            buildingName: buildings.name,
            latitude: buildings.building_latitude,
            longitude: buildings.building_longitude,
            addressLane1: buildings.address_lane1,
            addressLane2: buildings.address_lane2,
            city: buildings.city,
            province: buildings.province,
            postalCode: buildings.postal_code,
            country: buildings.country,
            certificationLevel: buildings.bomathreecertifications__level,
            certificationExpiryDate: buildings.bomathreecertifications__expiry_date,
            certificationIssueDate: buildings.bomathreecertifications__issue_date,
            certificationName: buildings.bomathreecertifications__certification_name,
            certificationStreamName: buildings.bomathreecertifications__stream
        })) || [];

    const buildingCertificationBuildings =
        allData.building_certification_buildings.map((buildings: any) => ({
            subRegionName: buildings.sub_region__region__name,
            assetTypeName: buildings.asset__name,
            managementCompanyName: buildings.management_company__name,
            grossFloorArea:
                buildings.gross_floor_area_unit !== 'Sq.ft'
                    ? 10.76 * Number(buildings.gross_floor_area)
                    : buildings.gross_floor_area,
            grossFloorAreaUnit: constantStrings.squareFeet,
            privacyStatus: buildings.privacy_status,
            isFeatureBuilding: buildings.feature_building,
            buildingName: buildings.name,
            latitude: buildings.building_latitude,
            longitude: buildings.building_longitude,
            addressLane1: buildings.address_lane1,
            addressLane2: buildings.address_lane2,
            city: buildings.city,
            province: buildings.province,
            postalCode: buildings.postal_code,
            country: buildings.country,
            certificationLevel:
                buildings.answered_questionnaires__certification__certificate_level__level,
            certificationExpiryDate: buildings.answered_questionnaires__certification__expiry_date,
            certificationIssueDate: buildings.answered_questionnaires__certification__issue_date,
            certificationName:
                buildings.answered_questionnaires__certification__certificate_level__certification__name,
            certificationStreamName: buildings.answered_questionnaires__certification__stream
        })) || [];

    const formattedData = {
        regions: allData.regions || [],
        certifications: allData.certifications || [],
        certificationLevels: allData.certification_level || [],
        assets: allData.asset || [],
        propertiesManagementCompanies: allData.property_management_companies || [],
        buildingData: [
            ...certificationPortfolioBuildings,
            ...versionThreeBuildings,
            ...buildingCertificationBuildings
        ]
    };
    return formattedData;
};

const PublicDashboardComponent = () => {
    const [filteredBuildingData, setFilteredBuildingData] = useState<allBuildingsDataType | {}>({});
    const [allBuildingsData, setAllBuildingsData] = useState<any>([]);
    const { t } = useTranslation();
    const userInfo = useSelector((state: any) => state.CurrentUser.currentUserData);

    const dispatch = useDispatch();

    useEffect(() => {
        userInfo && setUserLanguage(userInfo.language);
    }, [userInfo]);

    useEffect(() => {
        const fetchFile = async () => {
            try {
                dispatch(setShowAppLoader(true));
                const response = await axios.get(
                    `/static_backend/public_dashboard/dashboard.json`,
                    {
                        headers: { 'Cache-Control': 'no-cache' }
                    }
                );
                const newFileContent = response.data;
                setFilteredBuildingData(formatAllBuildingsData(newFileContent));
                setAllBuildingsData(formatAllBuildingsData(newFileContent));
            } catch (error) {
                onErrorCallback(error);
            } finally {
                dispatch(setShowAppLoader(false));
            }
        };

        const fetchInterval = 24 * 60 * 60 * 1000;

        const intervalId = setInterval(fetchFile, fetchInterval);
        fetchFile();

        return () => clearInterval(intervalId);
    }, [dispatch]);

    if (!Object.keys(filteredBuildingData).length) return <></>;
    return (
        <DashBoardContainer>
            <HeaderComponent />
            <FiltersComponent {...{ allBuildingsData, setFilteredBuildingData }} />
            <MainContainer className='p-3 pt-2'>
                {/* <FormPrimaryContainer className='ms-2 overflow-visible' border={true}> */}
                <GenericHeadingText
                    title={t(constantStrings.mapsAndGraphs)}
                    className='col-sm-3 text-nowrap ms-2'
                />
                {/* </FormPrimaryContainer> */}
                <DashBoardsAndFPComponent {...{ allBuildingsData, filteredBuildingData }} />
                <BuildingCardComponent {...{ filteredBuildingData }} />
            </MainContainer>
        </DashBoardContainer>
    );
};

export default PublicDashboardComponent;
