import {
    FETCH_CURRENT_USER,
    FETCH_CURRENT_USER_SUCCESS,
    FetchCurrentUserActionTypes,
    FetchCurrentUserState
} from '../actions/CurrentUser/types';

const initialState: FetchCurrentUserState = {
    isFetching: false,
    currentUserData: null
};

const CurrentUser = (
    state = initialState,
    action: FetchCurrentUserActionTypes
): FetchCurrentUserState => {
    switch (action.type) {
        case FETCH_CURRENT_USER: {
            return {
                ...state,
                isFetching: true
            };
        }
        case FETCH_CURRENT_USER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                currentUserData: action.data
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default CurrentUser;
