import { defaultGetResponse } from '../../interfaces/interfaces';
import {
    DocumentActionTypes,
    DocumentState,
    GET_DIRECTORIES,
    GET_DIRECTORIES_SUCCESS,
    GET_FILES,
    GET_FILES_SUCCESS,
    EDIT_DIRECTORIES_SUCCESS,
    DELETE_DIRECTORIES_SUCCESS,
    CREATE_DIRECTORIES_SUCCESS,
    CREATE_FILES_SUCCESS,
    EDIT_FILES_SUCCESS,
    DELETE_FILES_SUCCESS,
    CREATE_DIRECTORIES,
    EDIT_DIRECTORIES,
    DELETE_DIRECTORIES,
    CREATE_FILES,
    EDIT_FILES,
    DELETE_FILES
} from '../actions/Documents/types';

const initialState: DocumentState = {
    directoriesData: defaultGetResponse,
    subDirectoriesData: defaultGetResponse,
    isFetchingDirectories: false,
    filesData: defaultGetResponse,
    isFetchingFiles: false,
    isCreatingDirectories: false,
    isEditingDirectories: false,
    isDeletingDirectories: false,
    isCreatingFiles: false,
    isEditingFiles: false,
    isDeletingFiles: false
};

export const Documents = (state = initialState, action: DocumentActionTypes): DocumentState => {
    switch (action.type) {
        case GET_DIRECTORIES:
            return {
                ...state,
                isFetchingDirectories: true
            };
        case GET_DIRECTORIES_SUCCESS:
            return {
                ...state,
                directoriesData: {
                    ...action.data,
                    results: action.data.results.filter((dir: any) => !dir.parent)
                },
                subDirectoriesData: {
                    ...action.data,
                    results: action.data.results.filter((dir: any) => dir.parent)
                },
                isFetchingDirectories: false
            };
        case GET_FILES: {
            return {
                ...state,
                isFetchingFiles: true
            };
        }
        case GET_FILES_SUCCESS: {
            return {
                ...state,
                filesData: action.data,
                isFetchingFiles: false
            };
        }
        case CREATE_DIRECTORIES: {
            return {
                ...state,
                isCreatingDirectories: true
            };
        }
        case EDIT_DIRECTORIES: {
            return {
                ...state,
                isEditingDirectories: true
            };
        }
        case DELETE_DIRECTORIES: {
            return {
                ...state,
                isDeletingDirectories: true
            };
        }
        case CREATE_FILES: {
            return {
                ...state,
                isCreatingFiles: true
            };
        }
        case EDIT_FILES: {
            return {
                ...state,
                isEditingFiles: true
            };
        }
        case DELETE_FILES: {
            return {
                ...state,
                isDeletingFiles: true
            };
        }
        case CREATE_DIRECTORIES_SUCCESS: {
            return {
                ...state,
                isCreatingDirectories: false
            };
        }
        case EDIT_DIRECTORIES_SUCCESS: {
            return {
                ...state,
                isEditingDirectories: false
            };
        }
        case DELETE_DIRECTORIES_SUCCESS: {
            return {
                ...state,
                isDeletingDirectories: false
            };
        }
        case CREATE_FILES_SUCCESS: {
            return {
                ...state,
                isCreatingFiles: false
            };
        }
        case EDIT_FILES_SUCCESS: {
            return {
                ...state,
                isEditingFiles: false
            };
        }
        case DELETE_FILES_SUCCESS: {
            return {
                ...state,
                isDeletingFiles: false
            };
        }
        default:
            return {
                ...state
            };
    }
};

export default Documents;
