import i18n from 'i18next';
import { constantStrings } from '../../../constantStrings';

export const buildingPropertiesTabs: { [key: string]: string } = {
    documents: 'Documents',
    questionnaire: 'Questionnaire',
    certification: 'Certification',
    invoice: 'Invoice'
};
export const questionnaireTabs = {
    current: i18n.t(constantStrings.current),
    historical: i18n.t(constantStrings.historical)
};

export const amountColPerms = [
    i18n.t(constantStrings.companySuperAdmin),
    i18n.t(constantStrings.propertyAdmin),
    i18n.t(constantStrings.propertyUser),
    i18n.t(constantStrings.propertyReadOnlyUser)
];

export const canUpdateBuildings = [
    i18n.t(constantStrings.bbSuperAdmin),
    i18n.t(constantStrings.bbAdmin),
    i18n.t(constantStrings.companySuperAdmin),
    i18n.t(constantStrings.propertyAdmin),
    i18n.t(constantStrings.propertyUser)
];

export const canAccessQuestionnareTab = [
    i18n.t(constantStrings.bbSuperAdmin),
    i18n.t(constantStrings.bbAdmin),
    i18n.t(constantStrings.companySuperAdmin),
    i18n.t(constantStrings.propertyAdmin),
    i18n.t(constantStrings.propertyUser)
];
