import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import reducer from './reducers';
import saga from './sagas';
import { showReduxLogger } from '../config';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
    collapsed: true,
    duration: true,
    predicate: () => showReduxLogger
});
// Mount it on the Store
export const index = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
);

// Run the saga
sagaMiddleware.run(saga);

export default index;
