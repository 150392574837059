import {
    FETCH_CURRENT_USER,
    FETCH_CURRENT_USER_SUCCESS,
    FetchCurrentUserActionTypes
} from './types';

export const getCurrentUserData = (params: any): FetchCurrentUserActionTypes => ({
    type: FETCH_CURRENT_USER,
    params
});

export const getCurrentUserDataReceived = (data: any): FetchCurrentUserActionTypes => ({
    type: FETCH_CURRENT_USER_SUCCESS,
    data
});
