import { Controller, useFormContext } from 'react-hook-form';
import React, { FunctionComponent, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { LabelHeading, EntryInput, ErrorText, EyeFontIcon, SearchFilterBox } from './styles';
import { FlexColumn } from '../../styles';
import { isKeyEnteredAllowedForInputTypeNumber } from '../../../CommonUtilities';
import images from '../../../assets';

const Tooltip = loadable(() => import('../Tooltip/index'));

interface InputHookProps {
    name: string;
    type: string;
    placeholder?: string | null;
    displayName?: string | null;
    className?: string;
    id?: string;
    variant?: string;
    asterisk?: boolean;
    min?: string;
    isDisabled?: boolean;
    title?: string;
    autoFocus?: boolean;
    autoComplete?: string;
    noMargin?: boolean;
    infoIcon?: boolean;
    infoText?: string | null;
    maxLength?: number;
    onClick?: any;
    value?: any;
    onChange?: any;
    defaultValue?: any;
    childElement?: any;
}

const FormInput: FunctionComponent<InputHookProps> = ({
    name,
    type,
    placeholder,
    displayName,
    className = '',
    id = '',
    variant = '',
    asterisk = true,
    min,
    isDisabled = false,
    title,
    autoFocus,
    autoComplete = 'off',
    noMargin = false,
    infoIcon,
    infoText,
    maxLength,
    value,
    onChange,
    defaultValue,
    childElement,
    ...props
}) => {
    const [passwordShown, setPasswordShown] = useState(false);
    const {
        register,
        unregister,
        formState: { errors },
        control
    }: any = useFormContext();
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    useEffect(() => {
        return () => {
            unregister(name);
        };
        //eslint-disable-next-line
    }, []);
    return (
        <FlexColumn className={className + ' px-2 ' + (noMargin ? ' ' : ' mb-3 ')}>
            <SearchFilterBox
                className={' ' + (variant === 'loginpage' ? ' order-0 ' : ' order-1 ')}
            >
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange: controllerOnChange } }) => (
                        <EntryInput
                            aria-label={displayName}
                            variant={variant ? variant : isDisabled ? 'disabled' : 'primary'}
                            className={
                                (type === 'password' && variant === 'loginpage' && ' pe-5 ') +
                                (errors[name] && ' border-danger ')
                            }
                            title={title}
                            name={name}
                            type={
                                type === 'password' ? (passwordShown ? 'text' : 'password') : type
                            }
                            placeholder={placeholder}
                            {...register(name)}
                            id={id}
                            min={min}
                            disabled={isDisabled}
                            autoComplete={autoComplete}
                            onKeyDown={(e) =>
                                type === 'number' && isKeyEnteredAllowedForInputTypeNumber(e)
                            }
                            autoFocus={autoFocus}
                            maxLength={maxLength}
                            value={value}
                            onChange={(e: any) => {
                                controllerOnChange(e);
                                if (onChange) {
                                    onChange(e);
                                }
                            }}
                            defaultValue={defaultValue}
                        />
                    )}
                />
                {childElement}

                {type === 'password' && variant === 'loginpage' && (
                    <EyeFontIcon
                        isLoginPage={variant === 'loginpage'}
                        onClick={togglePasswordVisiblity}
                    >
                        {passwordShown ? (
                            <img src={images['eyeIcon']} alt='' />
                        ) : (
                            <img src={images['eyeIconCrossed']} alt='' />
                        )}
                    </EyeFontIcon>
                )}
            </SearchFilterBox>
            {(displayName || infoIcon) && (
                <LabelHeading
                    variant={variant ? variant : 'primary'}
                    className={'mb-1 ' + (variant === 'loginpage' ? 'd-none' : 'order-0')}
                >
                    {displayName}
                    {asterisk ? <span className='text-danger'> * </span> : ''}
                    {infoIcon ? (
                        <>
                            <i
                                className='fas fa-info-circle ms-1'
                                data-tip
                                data-for={name}
                                data-event='click'
                                aria-hidden='true'
                            ></i>
                            <Tooltip place={'top'} id={name}>
                                <div dangerouslySetInnerHTML={{ __html: infoText ?? '' }} />
                            </Tooltip>
                        </>
                    ) : (
                        ''
                    )}
                </LabelHeading>
            )}
            {errors[name] && (
                <ErrorText className={variant ? 'error-text order-2 mt-2' : 'order-2 mt-2'}>
                    {errors[name] && errors[name].message}
                </ErrorText>
            )}
        </FlexColumn>
    );
};
export default FormInput;
