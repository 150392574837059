import Slider from 'react-slick';

const RCSlider = (props: any) => {
    const { handlePrevClick, handleNextClick } = props;
    const PrevArrow = (props: any) => {
        const { className, style, onClick, handlePrevClick } = props;

        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: 'block',
                    background: '#DDDDDD',
                    borderRadius: '100%'
                }}
                onClick={() => {
                    onClick && onClick();
                    handlePrevClick && handlePrevClick();
                }}
            />
        );
    };

    const NextArrow = (props: any) => {
        const { className, style, onClick, handleNextClick } = props;

        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: 'block',
                    background: '#DDDDDD',
                    borderRadius: '100%'
                }}
                onClick={() => {
                    onClick && onClick();
                    handleNextClick && handleNextClick();
                }}
            />
        );
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        responsive: props.responsiveSettings || [
            {
                settings: {
                    slidesToShow: 1
                }
            }
        ],
        slidesToScroll: 1,
        prevArrow: <PrevArrow {...{ handlePrevClick }} />,
        nextArrow: <NextArrow {...{ handleNextClick }} />
    };

    return (
        <Slider
            {...settings}
            className={`${props.className} ${props.noBottomPadding ? '' : 'pb-3'}`}
        >
            {props.children}
        </Slider>
    );
};

export default RCSlider;
