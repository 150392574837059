import {
    createNewInvoiceFeeActionTypes,
    createNewInvoiceFeeState,
    CREATE_NEW_INVOICE_FEE,
    CREATE_NEW_INVOICE_FEE_SUCCESS,
    EDIT_INVOICE_DATA,
    EDIT_INVOICE_DATA_SUCCESS,
    GET_INVOICE_DATA,
    GET_INVOICE_DATA_SUCCESS,
    GET_INVOICE_LIST,
    GET_INVOICE_LIST_SUCCESS,
    GET_INVOICE_OPTIONS,
    GET_INVOICE_OPTIONS_SUCCESS,
    SET_INVOICE_TYPE
} from '../actions/Finances/types';

const initialState: createNewInvoiceFeeState = {
    isNewInvoiceFeeCreated: false,
    isFetchingInvoiceDataList: false,
    invoiceDataList: null,
    invoiceOptionsData: null,
    invoiceData: null,
    isFetchingInvoiceData: false,
    isInvoiceDataEdited: false,
    invoiceType: 'Single Stream'
};

export const CreateNewInvoiceFee = (
    state = initialState,
    action: createNewInvoiceFeeActionTypes
): createNewInvoiceFeeState => {
    switch (action.type) {
        case CREATE_NEW_INVOICE_FEE: {
            return {
                ...state,
                isNewInvoiceFeeCreated: true
            };
        }
        case CREATE_NEW_INVOICE_FEE_SUCCESS: {
            return {
                ...state,
                isNewInvoiceFeeCreated: false
            };
        }

        case GET_INVOICE_LIST: {
            return {
                ...state,
                isFetchingInvoiceDataList: true
            };
        }

        case GET_INVOICE_LIST_SUCCESS: {
            if (action.appendData && state?.invoiceDataList?.results?.length) {
                action.payload.results = [
                    ...state.invoiceDataList?.results,
                    ...action.payload.results
                ];
            }
            return {
                ...state,
                invoiceDataList: action.payload,
                isFetchingInvoiceDataList: false
            };
        }

        case GET_INVOICE_DATA: {
            return {
                ...state,
                isFetchingInvoiceData: true
            };
        }

        case GET_INVOICE_DATA_SUCCESS: {
            return {
                ...state,
                invoiceData: action.payload,
                isFetchingInvoiceData: false
            };
        }

        case EDIT_INVOICE_DATA: {
            return {
                ...state,
                isInvoiceDataEdited: true
            };
        }

        case EDIT_INVOICE_DATA_SUCCESS: {
            return {
                ...state,
                isInvoiceDataEdited: false
            };
        }

        case GET_INVOICE_OPTIONS: {
            return {
                ...state
            };
        }

        case GET_INVOICE_OPTIONS_SUCCESS: {
            return {
                ...state,
                invoiceOptionsData: action.payload
            };
        }

        case SET_INVOICE_TYPE: {
            return {
                ...state,
                invoiceType: action.data
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default CreateNewInvoiceFee;
