import { GetRequestInterface } from '../../../interfaces/interfaces';

export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';

export interface UserProfileInterFace {}

export interface userProfileParams extends GetRequestInterface {
    params: Array<UserProfileInterFace>;
}
export interface userProfileUpdateAction {
    type: typeof USER_PROFILE_UPDATE;
    params: userProfileParams;
    id: number;
    successMessage?: string;
    history?: any;
}

export interface userProfileUpdateSuccessAction {
    type: typeof USER_PROFILE_UPDATE_SUCCESS;
}

export interface userProfileUpdateFailureAction {
    type: typeof USER_PROFILE_UPDATE_FAILURE;
}

export type UserProfileUpdateActionTypes =
    | userProfileUpdateAction
    | userProfileUpdateSuccessAction
    | userProfileUpdateFailureAction;

export interface UserProfileUpdateState {
    isLoading: boolean;
}
