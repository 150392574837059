import {
    ADD_CLIENT_COMPANY,
    ADD_CLIENT_COMPANY_SUCCESS,
    AddClientCompanyActionTypes,
    clientCompanyParams,
    ADD_CLIENT_COMPANY_FAILURE,
    GET_CLIENT_COMPANY,
    GET_CLIENT_COMPANY_SUCCESS,
    clientCompanyNamesJson,
    clientCompanyNamesParams
} from './types';

export const addClientCompany = (
    params: clientCompanyParams,
    history?: any
): AddClientCompanyActionTypes => ({
    type: ADD_CLIENT_COMPANY,
    params,
    history
});

export const clientCompanyAdded = (): AddClientCompanyActionTypes => ({
    type: ADD_CLIENT_COMPANY_SUCCESS
});

export const clientCompanyAddFailed = (): AddClientCompanyActionTypes => ({
    type: ADD_CLIENT_COMPANY_FAILURE
});

export const getClientCompanyData = (
    params: clientCompanyNamesParams
): AddClientCompanyActionTypes => ({
    type: GET_CLIENT_COMPANY,
    params
});

export const getClientCompanyDataRecieved = (
    data: clientCompanyNamesJson
): AddClientCompanyActionTypes => ({
    type: GET_CLIENT_COMPANY_SUCCESS,
    data
});
