import React, { FunctionComponent, ReactNode } from 'react';
import { Button as IBIButton } from './styles';
import { getActiveStyle, getHoverStyle, getStyle, size as sizeType, defaultStyle } from './helpers';
import { variant as variantType } from './buttonVariants';
import _ from 'lodash';
import { VHFlexCenterClassnames } from '../../commonClassNames';
import IBILoader from '../../UIComponents/IBILoader';

export interface ButtonProps {
    children: ReactNode;
    loading?: boolean;
    size?: sizeType;
    variant?: variantType;
    disabled?: boolean;
    onClick?: (...args: unknown[]) => unknown;
    title?: string | null;
    className?: string;
    onKeyPress?: any;
    id?: string;
    type?: any;
    value?: any;
    style?: any;
    tooltip?: any;
    tabIndex?: number;
}

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    /*
     *  We need this as typescript currently doesn't support default props so we need to check
     *  if variant,size are being passed or else typescript marks them undefined
     *  even if default props are passed
     */
    const defaultHoverStyle = {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        border: 'red'
    };

    const defaulActiveStyle = {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        border: 'red'
    };

    // This is the default style when button is rendered on dom
    const style = props.variant
        ? _.merge({}, getStyle(props.variant, props.size, props.disabled), props.style)
        : _.merge({}, defaultStyle, props.style);

    // Hover Style of button
    const hoverStyle = props.variant
        ? _.merge({}, getHoverStyle(props.variant), props.style)
        : _.merge({}, defaultHoverStyle, props.style);

    const activeStyle = props.variant
        ? _.merge({}, getActiveStyle(props.variant), props.style)
        : _.merge({}, defaulActiveStyle, props.style);
    return (
        <IBIButton
            as='button'
            className={props.className}
            tabIndex={props.tabIndex}
            disabled={props.disabled || false}
            generalStyle={style}
            hoverStyle={hoverStyle}
            activeStyle={activeStyle}
            title={props.title || ''}
            type={props.type}
            onClick={props.onClick}
        >
            <b className={`${VHFlexCenterClassnames} text-truncate`}>
                {props.loading ? <IBILoader width={20} height={20} /> : props.children}
            </b>
        </IBIButton>
    );
};

Button.defaultProps = {
    disabled: false,
    loading: false,
    size: 'lg',
    title: '',
    variant: 'primary',
    className: '',
    type: 'button'
};

export default Button;
