import { useCallback, useEffect, useRef, useState } from 'react';

export function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 400);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}

export function usePrevious(value: any) {
    const currentRef = useRef(value);
    const previousRef = useRef();

    if (currentRef.current !== value) {
        previousRef.current = currentRef.current;
        currentRef.current = value;
    }

    return previousRef.current;
}

export function useAsync(asyncFunction: any, immediate = true) {
    const [responseData, updateResponseData] = useState(null);
    const [isLoading, udpateIsLoading] = useState(true);
    const [error, udpateError] = useState(null);

    const execute = useCallback(() => {
        udpateIsLoading(true);
        updateResponseData(null);
        udpateError(null);
        return asyncFunction()
            .then((response: any) => {
                updateResponseData(response.data);
            })
            .catch((error: any) => {
                udpateError(error);
            })
            .finally(() => udpateIsLoading(false));
    }, [asyncFunction]);

    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    return { execute, isLoading, responseData, error };
}
