import Cookies from 'js-cookie';
import { clearLocalStorageAfterLogout } from '../../CommonUtilities';
import {
    LOGIN_SUCCESS,
    LoginActionTypes,
    LoginState,
    LOGOUT,
    LOGIN_FAILURE,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    RESET,
    RESET_SUCCESS,
    UPDATE_PASSWORD,
    GET_MULTITENANT_CONFIG,
    GET_MULTITENANT_CONFIG_RECEIVED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE
} from '../actions/Login/types';

const initialState: LoginState = {
    isLoggedIn: false,
    loginData: null,
    isLoggedOut: false,
    isForgotPasswordRequestSent: false,
    isPasswordReset: false,
    multiTenantData: null
};

const Login = (state = initialState, action: LoginActionTypes): LoginState => {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            localStorage.setItem('accessToken', 'loggedIn');

            Cookies.set('name', 'value');
            return {
                ...state,
                isLoggedIn: true,
                loginData: action.data,
                isLoggedOut: false
            };
        }

        case LOGIN_FAILURE: {
            return {
                ...state,
                loginData: action.data
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedOut: false
            };
        }
        case LOGOUT_SUCCESS: {
            clearLocalStorageAfterLogout();
            return {
                ...state,
                isLoggedOut: true
            };
        }
        case LOGOUT_FAILURE: {
            return {
                ...state,
                isLoggedOut: false
            };
        }
        case FORGOT_PASSWORD: {
            return {
                ...state
            };
        }
        case FORGOT_PASSWORD_SUCCESS: {
            return {
                ...state,
                isForgotPasswordRequestSent: true
            };
        }
        case FORGOT_PASSWORD_FAILURE: {
            return {
                ...state,
                isForgotPasswordRequestSent: false
            };
        }
        case RESET: {
            return {
                ...state
            };
        }
        case RESET_SUCCESS: {
            return {
                ...state,
                isPasswordReset: true
            };
        }
        case UPDATE_PASSWORD: {
            return {
                ...state
            };
        }
        case GET_MULTITENANT_CONFIG: {
            return {
                ...state
            };
        }
        case GET_MULTITENANT_CONFIG_RECEIVED: {
            return {
                ...state,
                multiTenantData: action.payload
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default Login;
